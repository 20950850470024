"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Eurojackpot = void 0;
const api_js_1 = require("@/api.js");
const state = {
    items: [],
    draw: {}
};
const mutations = {
    list(state, items) {
        state.items = items;
    },
    drawing(state, draw) {
        state.draw = draw;
    }
};
const getters = {
    items: (state) => state.items,
    draw: state => state.draw
};
const actions = {
    async list({ commit }) {
        const res = await api_js_1.default.eurojackpot.list();
        commit('list', res.data);
    },
    async drawing({ commit }, date) {
        console.log('DRAWING', date);
        const resp = await api_js_1.default.eurojackpot.drawing(date);
        commit('drawing', resp.data);
    }
};
exports.Eurojackpot = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
