<template lang="pug">
.home
  skills
</template>

<script>
// @ is an alias to /src
import Skills from '@/components/Skills.vue'
export default {
  name: 'Home',
  components: { Skills }
}
</script>

<style lang="less" scoped>
.home {
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
  overflow: auto;
  padding: 0;
  text-align: left;
  align-items:  stretch;
}
</style>
