<template lang="pug">
    div
        x150
</template>

<script>
import X150 from '@/components/X150.vue';
export default {
    components: { X150 }
}
</script>