<template lang="pug">
    .panel 
        div(style="flex:0 0 auto;font-size:1.8rem;align-self:left;") {{name}} 
        .value {{value}} {{unit}}
</template>

<script>
export default {
    props: ['name', 'value', 'unit']
}
</script>

<style lang="less" scoped>
.panel {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: black;
    // width: 12rem;
    font-size: 2.8rem;
    border: 1px solid gray;
    border-radius: 0.75rem;
    background-color: rgb(196, 251, 240);
    margin: 0.25rem 0.2rem;
    padding: 0rem 0.75rem;
    display: flex;
    flex-flow:column;
    align-items: flex-start;
    box-shadow: inset 1px 1px 3px #000;
}
.value {
    flex:0 0 auto;
    align-self:flex-end;
}
</style>