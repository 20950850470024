<template lang="pug">
div(style="padding: 1rem; display: flex; flex-flow: column; color: black;position:relative;")
  div(
    style="align-items: center; margin: 0.5rem; border: 1px solid gray; border-radius: 0.5rem; padding: 0.25rem; display: flex; flex-flow: row nowrap",
    :style="{ color: !lower || lower.error ? 'red' : 'black' }"
  )
    h1(style="width: 8rem") Lower
    icon(
      name="trigger",
      :style="{ color: lower.trigger ? 'green' : 'silver' }"
    )
    h1(style="width: 8rem; text-align: right") {{ lower && lower.dist && lower.dist.toFixed(2) }}
    h1(style="width: 18rem") {{ lower && timeLower }}
    h3(style="width: 6rem") Err {{ lower && lower.error }}
  div(
    style="align-items: center; margin: 0.5rem; border: 1px solid gray; border-radius: 0.5rem; padding: 0.25rem; display: flex; flex-flow: row nowrap",
    :style="{ color: !upper || upper.error ? 'red' : 'black' }"
  )
    h1(style="width: 8rem") Upper
    icon(
      name="trigger",
      :style="{ color: upper.trigger ? 'green' : 'silver' }"
    )
    h1(style="width: 8rem; text-align: right") {{ upper && upper.dist && upper.dist.toFixed(2) }}
    h1(style="width: 18rem") {{ upper && timeUpper }}
    h3(style="width: 6rem") Err {{ upper && upper.error }}
  div(
    style="flex: 0 0 auto; display: flex; flex-flow: row wrap; margin-top: 4rem; align-items: flex-start; align-content: flex-start"
  )
    button3d(
      v-for="c in colors",
      style="margin: 0rem 1rem; padding: 0.1rem",
      @click="setColor(c)",
      :class="{ selected: c == color }"
    )
      div(
        style="width: 4rem; height: 4rem; border-radius: 0.5rem",
        :style="{ backgroundColor: c }"
      )
  div(style="display: flex; margin-top: 2rem")
    div(
      style="width: 1%; height: 3rem; margin: 0",
      v-for="c in rbw",
      :style="{ backgroundColor: c }",
      @click="setColor(c)"
    )
  div(
    style="flex: 0 0 auto; display: flex; flex-flow: row wrap; margin-top: 3rem; align-items: flex-start; align-content: flex-start"
  )
    button3d(
      v-for="e in effects",
      @click="setEffect(e)",
      style="margin: 1rem; font-size: 3rem; padding: 0.5rem 1rem"
    )
      div(:style="{ color: e == effect ? '#00cc00' : 'black' }") {{ e }}
  div(style="display:flex; flex-flow:row;justify-content:space-between;")

    div(style="display: flex; flex-flow:column; margin-top: 4rem;align-items:flex-start;")
      div(style="display:flex;")
        button3d(style="margin: 0 1rem", @click="dir = 1")
          div(
            style="margin: 0.5rem; width: 3rem; height: 3rem",
            :style="{ backgroundColor: color }"
          )
        button3d(
          style="margin: 0 1rem",
          @click="dir = 1",
          :class="{ selected: dir == 1 }"
        )
          icon.big(name="chevronUp", style="margin: 0.5rem")
        button3d(
          style="margin: 0 1rem",
          @click="dir = 0",
          :class="{ selected: dir == 0 }"
        )
          icon.big(name="chevronDown", style="margin: 0.5rem")
     
        button3d(
          style="margin: 0.5rem 1rem",
          @click="clear()",
          :class="{ selected: dir == 1 }"
        )
          div(style="font-size: 3rem; margin: 0 2rem; color: black") Clear
        button3d(
          style="margin: 0.5rem 1rem; color: black",
          @click="play()",
          :class="{ selected: dir == 1 }"
        )
          icon.big(name="play", style="margin: 0.25rem 2rem")
        button3d(
          style="margin: 0.5rem 1rem; color: black",
          @click="editPattern=true",
          :class="{ selected: dir == 1 }"
        )
          div(style="font-size: 3rem; margin: 0 2rem; color: black") Pattern
      div(style="display: flex; flex-flow: column; font-size: 2rem; margin: 0 1rem;margin-top:2rem;")
        div Delay {{ delay }}
        div
          input.slider(
            style="width: 32rem",
            type="range",
            min="0",
            max="60",
            step="1",
            list="markers",
            v-model="delay"
          )
          datalist#markers
            option(value="0", label="0")
            option(value="10", label="10")
            option(value="20", label="20")
            option(value="30", label="30")
            option(value="40", label="40")
            option(value="50", label="50")
            option(value="60", label="60")
      //- button3d(style="margin: 0 0.5rem",@click="play()")
        icon(name="play")
    
  .blocker(v-if="editPattern")
    ledPattern(@close="editPattern=false;")
</template>

<script>
import api from "@/api";
import * as moment from "moment";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import LedPattern from "@/components/Pattern.vue";

export default {
  components: { Icon, Button3d, LedPattern },
  data() {
    return {
      lower: {},
      upper: {},
      color: "#808080",
      colors: [
        "#ffffff",
        "#ff0000",
        "#ffff00",
        "#00ff00",
        "#00ffff",
        "#0000ff",
        "#ff00ff",
        "#000000",
        "#800000",
        "#ff8000",
        "#808080",
        "#2060a0",
      ],
      effects: [],
      effect: "simple",
      dir: 0,
      date: null,
      delay: 0,
      rbw: [...Array(100)],
      a: [...Array(14 * 18)],
      step: 0,
      editPattern: false
      // patterns: []
    };
  },
  async mounted() {
    moment.locale("de-DE");
    this.effects = (await api.stairs.effects()).data;
    this.timer = setInterval(() => {
      this.measure(1);
      this.date = Date.now() / 1000;
    }, 5000);
    this.rbw.forEach((t, ix) => {
      const c = this.HSL2RGB((360 * ix) / (this.rbw.length - 1), 80, 50);
      this.rbw[ix] = this.rgbToHex(c);
    });
    this.a = this.a.map((x) => "#000000");
    // this.patterns = JSON.parse(localStorage.getItem('patterns') || '[]');
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  computed: {
    timeLower() {
      if (!this.lower) return "";
      return moment(this.lower.time).format("HH:mm:ss.SSS");
    },
    timeUpper() {
      if (!this.upper) return "";
      return moment(this.upper.time).format("HH:mm:ss.SSS");
    },
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    // save() {
    //   this.patterns.push(this.a.map(x => x));
    //   localStorage.setItem('patterns', JSON.stringify(this.patterns));
    // },
    // load(p) {
    //     this.a = p.map(x => x);
    // },
    // black() {
    //   this.a = this.a.map(x => '#000000');
    // },
    // setPixel(x,y) {
    //   this.$set(this.a, (y-1)*18 + x - 1, this.color);
    // },
    // async copy () {
    //   api.stairs.copy(this.a);
    // },

    clear() {
      api.stairs.clear();
    },

    HSL2RGB(h, s, l) {
      s /= 100;
      l /= 100;
      const k = (n) => (n + h / 30) % 12;
      const a = s * Math.min(l, 1 - l);
      const f = (n) =>
        l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
      return { r: 255 * f(0), g: 255 * f(8), b: 255 * f(4) };
    },
    rgbToHex(c) {
      const R = Math.trunc(c.r);
      const G = Math.trunc(c.g);
      const B = Math.trunc(c.b);
      const color =
        "#" + ((1 << 24) | (R << 16) | (G << 8) | B).toString(16).slice(1);
      return color;
    },
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
    setColor(hex) {
      console.log(hex);
      this.color = hex;
      api.stairs.color(hex);
    },
    async setEffect(effect) {
      await api.stairs.effect(effect);
      this.effect = effect;
    },
    async play() {
      setTimeout(() => {
        api.stairs.play(this.dir);
      }, this.delay * 1000);
    },
    measure(id) {
      api.stairs.lower().then((res) => (this.lower = res.data));
      api.stairs.upper().then((res) => (this.upper = res.data));
    },
  },
};
</script>

<style lang="less" scoped>
datalist {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  writing-mode: vertical-lr;
  width: 100%;
}
input[type="range"] {
  width: 25rem;
  margin: 0;
}
.blocker {
  position:absolute;
  left:0.1rem;
  top:0.1rem;
  right:0.1rem;
  bottom:0.1rem;
  // border:1px solid gray;
  // border-radius:0.5rem;
  // box-shadow:0.25rem 0.25rem 0.25rem #777;
  background-color:#fffffff0;
  // opacity:0.9;
  display: flex;
  // align-items: center;
  justify-content: center;
}
</style>