<template lang="pug">
div(style="display:flex;flex-flow:column;align-items:center;")
    div(style="display: flex;")
        input(
        type="range",
        orient="vertical",
        v-model="saturation",
        min="0",
        max="100",
        list="markers1",
        @change="updateColor()"
        )
        input(
        type="range",
        orient="vertical",
        v-model="lightness",
        min="0",
        max="100",
        list="markers1",
        @change="updateColor()"
        )
        datalist#markers1
            option 0
            option 10
            option 20
            option 30
            option 40
            option 50
            option 60
            option 70
            option 80
            option 90
            option 100
        div(style="display: flex; flex-flow: column; margin: 0 1rem")
            div(
            style="height: 0.2rem; width: 2rem; margin: 0",
            v-for="c in rbw",
            :style="{ backgroundColor: (c && c.color) || '#ff00ff' }",
            @click="setColor(c.hue)"
            )
    div(style="width:6rem;height:2rem;",:style="{ backgroundColor: currentColor }") 
    div {{currentColor}}
</template>

<script>
export default {
  props: ["currentColor"],
  data() {
    return {
      rbw: [],
      hue: 0,
      saturation: 80,
      lightness: 50,
    };
  },
  mounted() {
    const h = [...Array(100)];
    h.forEach((t, ix) => {
      const c = this.HSL2RGB((360 * ix) / 99, 80, 50);
      h[ix] = { color: this.rgbToHex(c), hue: ix };
    });
    this.$set(this, "rbw", h);
  },
  methods: {
    updateColor() {
      const c = this.HSL2RGB((360 * this.hue) / (this.rbw.length - 1),
        Math.trunc(this.saturation),
        Math.trunc(this.lightness)
      );
      const h = this.rgbToHex(c);
      this.$emit("color", h);
    },
    setColor(hue) {
        this.hue = hue;
        this.updateColor();
    },
    HSL2RGB(h, s, l) {
      s /= 100;
      l /= 100;
      const k = (n) => (n + h / 30) % 12;
      const a = s * Math.min(l, 1 - l);
      const f = (n) =>
        l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
      return { r: 255 * f(0), g: 255 * f(8), b: 255 * f(4) };
    },
    rgbToHex(c) {
      const R = Math.trunc(c.r);
      const G = Math.trunc(c.g);
      const B = Math.trunc(c.b);
      const color =
        "#" + ((1 << 24) | (R << 16) | (G << 8) | B).toString(16).slice(1);
      return color;
    },
  },
};
</script>

<style lang="less" scoped>
input[type="range"][orient="vertical"] {
  flex:0 0 auto;
  appearance: slider-vertical;
  width: 1rem;
  height: 20rem;
  padding: 0 1rem;
}
.sliderLabel {
    color:black;
    font-size:0.5rem;
}
</style>

