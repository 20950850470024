<template lang="pug">
.sheet
  .header
    div(style="flex: 1 0")
    icon.small.framed(
      name="close",
      @click="$emit('close')",
      style="color: red"
    )
  .content
    div(
      style="flex: 0 0 auto; overflow: auto; width: 20rem; display: flex; flex-flow: column"
    )
      div(
        v-for="(g, key) in groups",
        style="color: black; border: 1px solid silver; border-radius: 0.25rem; margin: 0.1rem"
      )
        div(
          style="display: flex; background-color: #c0c8cf; padding: 0.25rem; align-items: center"
        )
          div(style="flex: 1 0") {{ g }}
          icon.tiny.rounded(
            v-if="drop != key",
            @click="dropdown(key)"
            name="chevronDown",
            style="background-color: white"
          )
          icon.tiny.rounded(
            v-if="drop == key",
            @click="dropdown(key)"
            name="chevronUp",
            style="background-color: white"
          )
        div(v-if="drop==key",style="display:flex;flex-flow:column;padding:0.5rem;")
          div(
            style="cursor: pointer; color: black; border: 1px solid silver; border-radius: 0.25rem; padding: 0.5rem; margin: 0.2rem; display: flex; flex-flow: column; align-items: flex-start",
            v-for="g in games",
            v-if="g.group == key"
            @click="showGame(g)",
            :class="{ selected: g == game }"
          )
            div {{ g.title }}
            div(style="flex:0 0 auto;align-self:stretch;display:flex;justify-content:space-between;")
              div {{ g.place }}
              div {{ g.date }}
    div(
      style="color: black; flex: 0 0 auto; width: 20rem; border-left: 1px solid silver; display: flex; flex-flow: column; align-items: flex-start; overflow: auto; padding: 1rem"
    )
      div(v-if="game", style="margin: 0.5rem 0; padding: 0.5rem")
        button3d(v-if="game", @click="$emit('selected', game)")
          icon.small(name="play")
      div(
        v-if="game",
        style="display: flex; flex-flow: column; align-items: flex-start; font-size: 1.4rem"
      )
        .gamerow(v-for="(r, ix) in parsed", style="display: flex") 
          |
          div(style="width: 2rem; text-align: right; margin-right: 2rem") {{ r[0] }}
          div(style="width: 6rem; text-align: left") {{ r[1] }}
          div(style="width: 6rem; text-align: left") {{ r[2] }}
</template>

<script>
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
export default {
  components: { Icon, Button3d },
  data() {
    return {
      initial: {
        a1: "T",
        b1: "S",
        c1: "L",
        d1: "D",
        e1: "K",
        f1: "L",
        g1: "S",
        h1: "T",
        a2: "P",
        b2: "P",
        c2: "P",
        d2: "P",
        e2: "P",
        f2: "P",
        g2: "P",
        h2: "P",
        a7: "p",
        b7: "p",
        c7: "p",
        d7: "p",
        e7: "p",
        f7: "p",
        g7: "p",
        h7: "p",
        a8: "t",
        b8: "s",
        c8: "l",
        d8: "d",
        e8: "k",
        f8: "l",
        g8: "s",
        h8: "t",
      },
      current: null,
      parsed: null,
      game: null,
      groups: null,
      games: null,
      drop: null
    };
  },
  methods: {
    dropdown(key) {
      if (this.drop == key)
        this.drop = null;
      else
        this.drop = key;
    },
    showGame(g) {
      this.game = g;
      this.parseGame(g.moves);
    },
    position(p) {
      const x = "abcdefgh".indexOf(p[0]);
      const y = "12345678".indexOf(p[1]);
      //   console.log('POS:', p, p[0],p[1], x, y)
      return { x, y };
    },
    field(x, y) {
      const r = "12345678"[y];
      const c = "abcdefgh"[x];
      return c + r;
    },
    initGame() {
      //   this.current = this.initial.map((r) => [...r]);
      this.current = { ...this.initial };
    },
    search(dx, dy, f, to) {
      const { x, y } = this.position(f);
      const { x: x1, y: y1 } = this.position(to);
      let cx = x1;
      let cy = y1;
      let t = "";
      do {
        cx += dx;
        cy += dy;
        t = this.field(cx, cy);
      } while (
        cx >= 0 &&
        cx <= 7 &&
        cy >= 0 &&
        cy <= 7 &&
        !this.current[t] &&
        (cx != x || cy != y)
      );
      return cx == x && cy == y;
    },
    moves() {
      return {
        P: (p, f, to) => {
          const { x, y } = this.position(f);
          const { x: x1, y: y1 } = this.position(to);
          //   console.log("Pawn from ", f, x, y, to, x1, y1);
          if (p == "P") return x == x1 && (y == y1 - 1 || (y1 == 3 && y == 1));
          return x == x1 && (y == y1 + 1 || (y1 == 4 && y == 6));
        },
        S: (p, f, to) => {
          const { x, y } = this.position(f);
          const { x: x1, y: y1 } = this.position(to);
          const t = [
            [2, 1],
            [2, -1],
            [1, 2],
            [-1, 2],
            [-2, 1],
            [-2, -1],
            [1, -2],
            [-1, -2],
          ];
          const v = t.filter((v) => {
            return x + v[0] == x1 && y + v[1] == y1;
          });
          return v.length > 0;
        },
        T: (p, f, to) => {
          let found = this.search(1, 0, f, to);
          if (!found) found = this.search(-1, 0, f, to);
          if (!found) found = this.search(0, 1, f, to);
          if (!found) found = this.search(0, -1, f, to);
          return found;
        },
        L: (p, f, to) => {
          let found = this.search(1, 1, f, to);
          if (!found) found = this.search(1, -1, f, to);
          if (!found) found = this.search(-1, 1, f, to);
          if (!found) found = this.search(-1, -1, f, to);
          return found;
        },
        D: (p, f, to) => {
          let found = this.search(1, 1, f, to);
          if (!found) found = this.search(1, -1, f, to);
          if (!found) found = this.search(-1, 1, f, to);
          if (!found) found = this.search(-1, -1, f, to);
          if (!found) found = this.search(1, 0, f, to);
          if (!found) found = this.search(-1, 0, f, to);
          if (!found) found = this.search(0, 1, f, to);
          if (!found) found = this.search(0, -1, f, to);
          return found;
        },
        K: (p, f, to) => {
          const { x, y } = this.position(f);
          const { x: x1, y: y1 } = this.position(to);
          const t = [
            [1, 1],
            [1, -1],
            [-1, 1],
            [-1, -1],
            [1, 0],
            [-1, 0],
            [0, 1],
            [0, -1],
          ];
          const v = t.filter((v) => {
            return x + v[0] == x1 && y + v[1] == y1;
          });
          return v.length > 0;
        },
      };
    },
    findFrom(piece, to) {
      //   console.log("find", piece, to);
      const list = Object.keys(this.current).filter(
        (x) => this.current[x] == piece
      );
      //   console.log("LIST:", to, list);
      const found = list.filter((x) => {
        return this.moves()[piece.toUpperCase()](piece, x, to);
      });
      //   console.log("FOUND: ", found);
      return found.length && found[0];
    },
    parseLine(line) {
      // 1. d2-d4 d7-d5
      console.log("Line", line);
      const r1 = /^\d+\.\s*/;
      const r2 =
        /((([tslkd])?((([a-h][1-8])\s*(:|-))?\s*([a-h][1-8])D?)|(0-0-0|0-0))\+?)/;
      const r3 =
        /(\s+((([tslkd])?(([a-h][1-8])\s*(:|-))?\s*([a-h][1-8])D?)|(0-0-0|0-0))\+?)?/;
      const r = new RegExp(r1.source + r2.source + r3.source, "gi");
      const m = r.exec(line);
      console.log(m);

      const result = [];
      const wpiece = (m[3] || "P").toUpperCase();
      let wfrom = m[6];
      let wto = m[8] || m[9];
      const bpiece = (m[13] || "p").toLowerCase();
      let bfrom = m[15];
      let bto = m[17] || m[18];

      if (wto == "0-0") {
        result.push(["e1", "g1"]);
        wfrom = "h1";
        wto = "f1";
      }
      if (wto == "0-0-0") {
        result.push(["e1", "c1"]);
        wfrom = "a1";
        wto = "d1";
      }

      console.log('---',m);
      const wpro = m[1].replace(/^.+([TSLD])\+?$/,'$1');
      const bpro = (m[10]||'').replace(/^.+([TSLD])\+?$/,'$1');
        
      console.log(line, wpiece, wfrom, wto, wpro, bpiece, bfrom, bto, bpro, m);

      if (!wfrom) wfrom = this.findFrom(wpiece, wto);
      const info = [line.replace(/^(\d+\.).*/, "$1"), m[1], m[10], line];
      result.push([wfrom, wto, wpro, info]);
      this.parsed.push(info);

      //   console.log('MOVED WHITE: ',line,wpiece,wfrom,wto,this.current[wfrom],this.current[wto],this.logb());
      this.current[wto] = this.current[wfrom];
      this.current[wfrom] = null;

      if (bto) {
        if (bto == "0-0") {
          result.push(["e8", "g8"]);
          bfrom = "h8";
          bto = "f8";
        }
        if (bto == "0-0-0") {
          result.push(["e8", "c8"]);
          bfrom = "a8";
          bto = "d8";
        }
        if (!bfrom) bfrom = this.findFrom(bpiece, bto);
        result.push([bfrom, bto, bpro]);
        //   console.log('MOVED BLACK.1 ',line,bpiece,bfrom,bto,this.current[bfrom],this.current[bto],this.logb());
        this.current[bto] = this.current[bfrom];
        this.current[bfrom] = null;
        //   console.log('MOVED BLACK.2 ',line,bpiece,bfrom,bto,this.current[bfrom],this.current[bto],this.logb());
      }
      //   console.log(line, wpiece, wfrom, wto, bpiece, bfrom, bto, result);
      return result;
    },
    logb() {
      return Object.keys(this.current)
        .filter((x) => this.current[x])
        .map((x) => x + ":" + this.current[x])
        .join(",");
    },
    parseGame(lines) {
      this.initGame();
      this.parsed = [];
      const game = [];
      lines.forEach((line) =>
        this.parseLine(line).forEach((t) => game.push(t))
      );
      return game;
      //   console.log(this.current);
    },
  },
  async mounted() {
    const response = await fetch("/data/chess/games.json");
    const { groups, games } = await response.json();
    this.groups = groups;
    this.games = games;
  },
};
</script>

<style lang="less" scoped>
.sheet {
  flex: 1 0;
  display: flex;
  flex-flow: column;
  margin: 2rem 1rem;
  background-color: #ffffff;
  border: 1px solid gray;
  border-radius: 0.25rem;
  box-shadow: 0.25rem 0.25rem 0.25rem #777;
  overflow: hidden;
}
.header {
  display: flex;
  background-color: #ccddff;
  padding: 0.25rem;
}
.content {
  flex: 1 0;
  overflow: auto;
  display: flex;
  flex-flow: row;
  margin: 2rem 1rem;
  background-color: #ffffff;
  padding: 1rem;
}
.selected {
  background-color: #cfc;
}
.gamerow {
  font-family: monospace;
}
</style>