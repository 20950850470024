<template lang="pug">
    .content
        .person(v-for="p in people")
            .title {{p.name}}
            div(style="flex:0 0 auto;padding: 0 1rem;display:flex;justify-content:space-between;align-items:center;")
                icon(name="chevronDown",v-if="details != p.name",@click="setDetails(p)")
                icon(name="chevronUp",v-if="details == p.name",@click="setDetails(null)")
                div(style="flex: 0 0 auto;display:flex;align-items:center;")
                    div(style="font-size:3rem;margin: 0 1rem;") {{items[p.name] && items[p.name].count}}
                    icon.smoke(name='smoke',@click="addOne(p.name)")
            div(v-if="items[p.name] && details == p.name",style="font-size:1.5rem;padding:0 0.5rem;flex:0 0 auto;display:flex;flex-flow:row wrap;overflow:auto;max-height:10rem;")
                div(v-for="x in items[p.name].times",style="padding:0.2rem 0.3rem;") {{time(x.stamp)}}
            //- .details(v-if="details == p.name") 
                .mark(v-for="x in 25",:style="{ left: (x-1)*100/24+'%' }")
                .xaxis
                .point(v-for="d in items[p.name].times",:style="point(d.stamp)")
            .details(v-if="details == p.name")
                .chart
                    .hour(v-for="h in 24",:style="{ left: (h-1)*100/24+'%' }") {{h-1}}
                    .mark(v-for="x in 25",:style="{ left: (x-1)*100/24+'%' }")
                    .min(v-for="y in 6",:style="{ top: (y-1)*100/6+'%' }") {{y*10-10}}
                    .xaxis(v-for="y in 7",:style="{ top: (y-1)*100/6+'%' }")
                    .point(v-for="d in items[p.name].times",:style="point(d.stamp)")
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import * as moment from 'moment';
import Icon from "@/components/Icon.vue";
export default {
    components: { Icon },
    data() {
        return {
            details: null,
            people: [
                { name:'Eva' },
                { name:'Gabriel' }
            ]
        }
    },
    computed: {
        ...mapGetters({
            items: "smoke/items",
        })
    },
    methods: {
        ...mapActions({
            list: 'smoke/list',
            add: 'smoke/add'
        }),
        async addOne(p) {
            await this.add(p);
            this.list();
        },
        time(t) {
            return moment(t).format('HH:mm:ss');
        },
        setDetails(p) {
            this.details = (p && p.name) || null;
        },
        point(t) {
            const m = moment(t)
            const h = m.hour() + m.minute()/60 + m.second()/3600;
            const x = m.hour() * 100/24;
            const y = 100 * (m.minute() + m.second()/60) / 60;
            console.log(t,h,x,y);
            return {
                // left: 100*h/24 + '%'
                left: x + '%',
                top: y + '%'
            }
        }
    },
    mounted() {
        this.list();
    }
}
</script>

<style lang="less" scoped>
    .content {
        flex: 1 0;
        display:flex;
        flex-flow: row wrap;
        align-items:flex-start;
        // overflow:auto;
        font-size:2rem;
        align-content: flex-start;
    }
    .person {
        flex: 0 0 auto;
        flex-basis: 90%;
        display:flex;
        align-self: flex-start;
        width: 90%;
        flex-flow: column nowrap;
        // overflow: auto;
        border: 1px solid silver;
        margin: 1rem 1rem;
        border-radius:0.5rem;
        box-shadow: #777 0.5rem 0.5rem 0.5rem;
        color: black;
    }
    .title {
        font-size: 3rem; 
        background-color:#eee;
    }
    .smoke {
        flex:0 0 auto;
        width:6rem;
        height:6rem;
        cursor:pointer;
        border:1px solid gray;
        border-radius: 0.5rem;
        padding:0.5rem;
        margin:1rem;
        background:  linear-gradient(180deg, #fff, #bbb);
        box-shadow: #777 0.25rem 0.25rem 0.5rem;
    }
    // .smoke:hover {
        // background: linear-gradient(0deg, #888, #ccc);;
    // }
    .smoke:active {
		box-shadow: none;
		background: linear-gradient(180deg, #ccc, #fff);
		-webkit-transform: translate(0, 2px);
		-moz-transform: translate(0, 2px);
		transform: translate(0, 2px);
		-webkit-transition: all .1s ease-in-out;
		-moz-transition: all .1s ease-in-out;
		transition: all .1s ease-in-out;
	}
    .details {
        display:flex;
        align-self: stretch;
        flex: 0 0 auto;
        position: relative;
        padding: 0;
    }
    .chart {
        margin: 2rem 1.5rem;
        position: relative;
        width: 100%;
        height: 100px;
    }
    .hour {
        position: absolute;
        font-size: 1rem;
        margin-left: 2px;
        margin-top: -1.2rem;
    }
    .min {
        position:absolute;
        margin-left: -1.3rem;
        margin-top: 2px;
        font-size: 1rem;
    }
    .xaxis {
        position: absolute;
        // flex:1 0;
        // margin: 1rem 0;
        // padding: 1 rem 0;
        height:1px;
        background-color: #ddd;
        width: 100%;
        // width: 100%;
    }
    .mark {
        position: absolute;
        top: 0px;
        bottom: 0px;
        background-color: #ddd;
        width: 1px;
    }
    .point {
        position: absolute;
        width:4%;
        height:1px;
        border-radius:3px;
        background-color: red;
    }
@media (orientation: landscape) {
    .content {
        font-size: 1rem;
    }
    .person {
        width: 36rem;
    }
    .smoke {
        width:3rem;
        height: 3rem;
    }
}
</style>