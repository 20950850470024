"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Recipes = void 0;
const state = {
    items: [
        {
            id: "e774799b-b6c9-4146-a9fe-3c42b2911131",
            name: "Lende im Speckmantel aus dem Backofen",
            ingredients: [
                { n: "Schweinelende", a: 500, u: "g" },
                { n: "Senf", a: 1, u: "TL" },
                { n: "Bacon", a: 120, u: "g" },
                { n: "Rosmarinzweige", a: 2 },
                { n: "Honig", a: 1, u: "EL" },
                { n: "Salz & Pfeffer" },
                { n: "Öl zum Braten" },
            ],
            steps: [
                {
                    h: "Schweinefilet panieren und anbraten",
                    t: "Ofen auf 180°C Umluft vorheizen. Das Schweinefilet von den Sehnen befreien, kräftig mit Salz und Pfeffer würzen und in einer Pfanne mit wenig Öl von allen Seiten anbraten.",
                },
                {
                    h: "Schweinefilet marinieren und in Speck einwickeln",
                    t: "Den Bacon leicht überlappend auf ein Brett legen. Den Rosmarin darüber zupfen, die Lende darauf legen und von allen Seiten mit Senf bestreichen. Anschließend fest mit dem Bacon umhüllen.",
                },
                {
                    h: "Lende im Speckmantel mit Honig einstreichen",
                    t: "Danach die Lende im Speckmantel auf ein Backblech setzen und alles mit Honig einstreichen.",
                },
                {
                    h: "Lende im Speckmantel im Backofen garen",
                    t: "Im Ofen für ca. 20 Minuten garen lassen. Wem der Bacon nicht knusprig genug ist, kann noch einmal für wenige Minuten auf Grill schalten.",
                },
                {
                    h: "Schweinefilet im Speckmantel kurz ruhen lassen und servieren",
                    t: "Die Lende herausnehmen und für 5 Minuten ruhen lassen, damit sich der Fleischsaft setzt und nicht so viel Flüssigkeit austritt.",
                },
            ],
            images: [
                'Lende-im-Speckmantel_4549-810x540.jpg.webp'
            ]
        },
        {
            id: "03a70de1-03e3-452a-8268-cb04e8b80f5f",
            name: "Gyrosschichtbraten",
            ingredients: [
                { a: 500, u: "g", n: "Rotkohl, fein geschnitten" },
                { a: 170, u: "g", n: "Gyrosgewürz" },
                { a: 2.5, u: "kg", n: "Schweinenacken" },
                { a: 700, u: "g", n: "Zwiebeln, geschält und fein geschnitten" },
                { a: 120, u: "ml", n: "Olivenöl" },
                { n: "wenig Salz und Pfeffer" },
            ],
            steps: [
                {
                    t: "Als erstes den Schweinehals vom Metzger der Länge nach so wie Rouladen schneiden lassen und wieder aufeinander legen lassen.",
                },
                {
                    t: "Jetzt Gyrosgewürz in einer Schale mit dem Öl vermengen, mit Salz und Pfeffer abschmecken, so dass es eine schöne flüssige Marinade ergibt. Die erste Scheibe Schweinehals hinlegen und nur die sichtbare Seite mit der Marinade bepinseln. Eine Schicht Zwiebeln draufgeben (und nach Belieben etwas Rotkohl) und so weiter schichten bis alles aufgebraucht ist.",
                },
                {
                    t: "Nun zum Binden erst am Anfang vom Braten einen Knoten, dann am Schluss und dann in der Mitte. Die ersten 3 Bindungen nicht so fest, da sonst die Füllung rausgedrückt wird. Danach wie gewohnt binden, immer mit Gefühl.",
                },
                {
                    t: "Bei 190°C Ober-/Unterhitze 90 bis 120 Minuten bis zu einer Kerntemperatur von 75 °C im vorgeheizten Offen braten. (Immer wieder mit dem Bratensaft begießen). Nicht erschrecken, der Braten geht stark ein, weil die Zwiebeln auch Wasser verlieren.Beim Servieren sich von Bindfaden zu Bindfaden vorarbeiten, das Fleisch ist gleich klein geschnitten.",
                },
            ],
            images: [
                "414552531_906756961237330_850760985496818835_n.jpg",
                "Gyrosschichtbraten-site.jpg",
            ],
        },
        {
            id: "3014cc0b-51b8-4856-8cbb-ee657bacda2c",
            name: "Hamburger Suppe",
            keys: ["Suppen"],
            ingredients: [
                { a: 2, u: "EL", n: "Olivenöl" },
                { a: 1, u: "", n: "große Zwiebel" },
                { a: 2, u: "", n: "Stangen Sellerie" },
                { a: 500, u: "g", n: "Rinderhackfleisch" },
                { n: "Salz" },
                { n: "Pfeffer" },
                { a: 1, u: "EL", n: "Paprikapulver" },
                { a: 1, u: "TL", n: "Knoblauchpulver" },
                { a: 2, u: "TL", n: "getrockneter Oregano" },
                { a: 800, u: "ml", n: "Rinder- oder Gemüsebrühe" },
                { a: 2, n: "Karotten" },
                { a: 2, n: "große Kartoffeln" }
            ],
            steps: [
                { t: "Zuerst die Zwiebel abziehen, Kartoffeln und Karotten schälen, Sellerie waschen. Alles in kleine Würfel schneiden." },
                { t: "Danach das Olivenöl bei mittlerer Hitze in einem großen Topf erwärmen." },
                { t: "Zwiebel und Sellerie dazugeben und für ein paar Minuten anbraten, bis die Zwiebel glasig ist." },
                { t: "Anschließend das Hackfleisch dazugeben und gut anbraten." },
                { t: "Mit Salz, Pfeffer, Oregano, Knoblauchpulver und Paprikapulver würzen." },
                { t: "Die Kartoffeln und Karotten dazugeben und alles gut mischen." },
                { t: "Mit der Rinder- oder Gemüsebrühe aufgießen und die Suppe zum Kochen bringen." },
                { t: "Hitze reduzieren und für ca. 15 Minuten köcheln lassen." },
                { t: "Als nachstes das TK-Gemüse in die Suppe rühren und weitere 10 bis 15 Minuten bei mittlerer Hitze köcheln, bis das Gemüse weich ist." }
            ],
            images: [
                "hamburger_suppe.jpg"
            ]
        },
        {
            id: "a6072935-a08c-4072-8068-201951fce35c",
            name: "Stroganoff",
            ingredients: [
                { a: 600, u: "g", n: "Fleisch" },
                { a: 1, u: "EL", n: "Mayonnaise" },
                { a: "½", n: "Zwiebel" },
                { a: "3", n: "Knoblauchzehen" },
                { a: 1, u: "TL", n: "Salz" },
                { a: "½", u: "TL", n: "Schwarzer Pfeffer" },
                { a: 1, u: "TL", n: "Paprika" },
                { a: "½", u: "TL", n: "Muskatnuß" },
                { a: 2, u: "EL", n: "Ketchup" },
                { a: 1, u: "EL", n: "Senf" },
                { a: 200, u: "g", n: "Sahne" },
                { a: 100, u: "g", n: "Champignons" },
            ],
            steps: [
                { i: "stroganoff_1.jpg", t: "Das Fleisch in eine Schüssel geben" },
                { i: "stroganoff_2.jpg", t: "Mayonnaise dazugeben und gut vermischen" },
                { i: "stroganoff_2a.jpg", t: "In der Pfanne etwas Öl erhitzen" },
                { i: "stroganoff_3.jpg", t: "Zwiebel gehackt in eine Pfanne geben" },
                { i: "stroganoff_4.jpg", t: "Knoblauch zerkleinert dazugeben und unter ständigem Rühren etwas anbraten" },
                { i: "stroganoff_5.jpg", t: "Fleisch dazugeben und sehr gut vermischen" },
                { i: "stroganoff_6.jpg", t: "Alles zusammen anbraten" },
                { i: "stroganoff_7.jpg", t: "Zugedeckt ein wenig kochen lassen" },
                { i: "stroganoff_8.jpg", t: "Salz, Pfeffer, Paprika und Muskatnuß dazugeben und umrühren" },
                { i: "stroganoff_9.jpg", t: "Ketchup dazugeben" },
                { i: "stroganoff_10.jpg", t: "Senf dazugeben und alles gut umrühren" },
                { i: "stroganoff_11.jpg", t: "Sahne dazugeben" },
                { i: "stroganoff_12.jpg", t: "Champignons dazugeben und wieder vermischen" },
                { i: "stroganoff_13.jpg", t: "Zugedeckt kochen lassen" },
                { i: "stroganoff_14.jpg", t: "Mit Petersilie nach Geschmack bestreuen und umrühren" },
                { i: "stroganoff_15.jpg", t: "Servieren" },
            ],
            images: [
                "stroganoff_14.jpg",
                "stroganoff_15.jpg",
            ]
        },
        {
            id: "638d498d-63b5-4299-8a8e-37e68c3691e3",
            name: "Einfacher, saftiger Zitronenkuchen vom Blech",
            time: "45 Min.",
            amount: "1 Blech",
            ingredients: [
                { a: 270, u: "g", n: "Butter weich" },
                { a: 200, u: "g", n: "Zucker" },
                { a: 6, n: "mittelgroße Eier zimmerwarm" },
                { a: 360, u: "g", n: "Weizenmehl" },
                { a: 3, u: "TL", n: "Backpulver gehäuft" },
                { a: 4, u: "EL", n: "Zitronensaft" },
                { a: 2, u: "EL", n: "abgeriebene Zitronenschale Menge nach Geschmack" },
                { n: "Zitronenguss siehe Rezept" }
            ],
            steps: [
                { t: "Ofen auf 175 Grad Ober- und Unterhitze vorheizen. Ein Backblech (max. 30*40 cm, siehe Tipps) mit Backpapier auslegen, den Rand einfetten. Die weiche Butter und den Zucker mit den Rührbesen des Handmixers oder der Küchenmaschine einige Minuten lang rühren, bis die Masse schaumig wird." },
                { t: "Die Eier nach und nach dazugeben und gut unterrühren. Dann Zitronenschale, Mehl, Backpulver und zuletzt den Zitronensaft mit in die Schüssel geben und alles möglichst kurz zu einem homogenen Teig verarbeiten. Sollte der Teig sehr fest wirken, noch 1-2 Schluck Milch unterrühren." },
                { t: "Rührteig auf dem Blech verteilen und glattstreichen. Etwa 21-24 Minuten backen, bis der Zitronenkuchen schön hellbraun ist – er kann innen ruhig noch etwas feucht sein." },
                { t: "Zum Servieren den abgekühlten Kuchen mit Puderzucker bestäuben oder Zitronenguss glasieren. Dafür 200 g Puderzucker fein sieben und mit so vielen Esslöffeln Zitronensaft (etwa 6 EL) anrühren, bis ein zähflüssiger Guss entsteht, der sich gut verteilen lässt." },
            ],
            images: [
                "Zitronenkuchen-Blech-Rezept-980x530.jpg"
            ],
            notes: [
                "Auf einem normalen großen Blech wird der Kuchen sehr flach; wer einen schön hohen Kuchen haben möchte, kann das Blech auf ca. ¾ der Größe mit doppelt gefalteter Folie oder einem Backrahmen begrenzen. Alternativ je nach gewünschter Höhe ca. ⅓ mehr von allen Zutaten verwenden. Backzeit evtl. anpassen."
            ]
        }
    ]
};
const mutations = {};
const getters = {
    items: (state) => state.items
};
const actions = {};
exports.Recipes = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
