<template lang="pug">
.content
  h2 Kontakt
  .layer
    .p Gabriel Gnatowski
    .p Hinter der Alten Kirche 19
    .p 46446 Emmerich am Rhein
    .space
    .p E-Mail: gnatowski@web.de
    .p Tel.: +49 1522 1648102
</template>

<script>
export default {
  metaInfo: {
    title: "Meine Kontaktdaten",
  },
};
</script>

<style lang="less" scoped>
.content {
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
  color: black;
  font-size: 1.8rem;
  overflow: hidden;
  perspective: 20px;
  perspective-origin: 50% 0;
}

.layer {
  animation: 0.5s left forwards;
  margin: -2rem 0;
  font-size: 0.7rem;
}

h2 {
  animation: 0.5s head forwards;
}
.space {
  height: 1rem;
}

@keyframes left {
  from {
    filter: blur(10px);
    transform: translateZ(-10px) rotateX(-10deg);
  }
  to {
    filter: blur(0px);
    transform: translateZ(14px) rotateX(0deg);
  }
}
@keyframes head {
  from {
    transform: translateZ(-10px);
  }
  to {
    transform: translateZ(12px);
  }
}

@media screen and (max-device-height: 700px) and (orientation: landscape) {
    // h2 { flex: 1 0; }
  h2 {
      margin: 0 0;
    margin-top:1.5vh;
  }
  .content {
      font-size: 3vh;
    // flex-flow: row;
    // align-items:center;
  }
  .content div {
      margin: 0 0;
  }
  .layer {
      margin: 0 0;
    font-size: 0.5rem;
  }
  .space {
      height:0.5rem;
  }
}
      @media screen and (max-device-height: 420px) and (orientation: landscape) {
            .content {
          font-size: 2.5vh;
          // flex-flow: row;
          // align-items:center;
        }
          .layer {
          margin: 0 0;
          font-size: 1.5vh;
        }
        .space {
          height:0.2rem;
        }
      }
</style>