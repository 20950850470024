<template lang="pug">
    .box(@click="update(value)")
        icon.small(name="checked",v-if="value",style="flex:0 0 auto;")
        icon.small(name="unchecked",v-if="!value",style="flex:0 0 auto;")
        div(style="flex:1 0;margin-left:0.5rem;")
            slot
</template>

<script>
import Icon from '@/components/Icon.vue'
export default {
    components: { Icon },
    props: ['value'],
    methods: {
        update(c) {
            // const t = this.checked ? 0 : 1;
            this.$emit('update', this.value);
        }
    }
}
</script>

<style lang="less" scoped>
@import (reference) '../shared.less';
.box {
    flex: 0 0 auto;
    display: flex;
    margin: 0.25 0.5rem;
    padding: 0.25rem 0.25rem;
    align-items:center;
    color:black;
    cursor:pointer;
}
</style>