<template lang="pug">
    div(style="flex: 1 0;display:flex;overflow:auto;")
        #selector(style="flex: 0 0 auto;overflow:auto;display:flex;flex-flow:column nowrap;")
            .letter(v-for="l in letters",@click="filter(l)") {{l}}
        div(style="flex: 0 0 auto;overflow:auto;display:flex;flex-flow:column nowrap;")
            .kfz(v-for="c in filtered",@click="show(c)")
                .num(:class="{ selected: selected == c.id }")
                    .d
                    .id {{c.id}}
                .info(:class="{ missed : c.missed }",v-html="infoText(c)")
        div(style="flex: 1 0;overflow:auto;display:flex;flex-flow:column;align-items:left;position:relative;")
            //- div(style="position:absolute;left:0;top:0;width:100%;height:10rem;text-align:left;color:black;")
                icon(name="chevronLeft",@click="left()")
                icon(name="chevronUp",@click="up()")
                icon(name="chevronRight",@click="right()")
                icon(name="chevronDown",@click="down()")
                div {{x.toFixed(2)}} : {{y.toFixed(2)}} 
                div {{pan.x.toFixed(2)}} : {{pan.y.toFixed(2)}}
                div {{pan.mx.toFixed(2)}} : {{pan.my.toFixed(2)}}
                div {{pan.cx.toFixed(2)}} : {{pan.cy.toFixed(2)}}
            //- div(style="height:2rem;font-size:2rem;color:#26a;") {{info}}
            .title(v-html="info")
            div(style="flex: 1 0;overflow:auto;display:flex;flex-flow:column")
                object#svg1(data="/img/de.svg" type="image/svg+xml",style="width:100%;height:100%;")
</template>

<script>
import Vue from 'vue';
import Icon from '@/components/Icon.vue';
import svgPanZoom from "svg-pan-zoom";

export default {
    components: { Icon },
    data() {
        return {
            x : 0,
            y: 0,
            letters: [],
            codes: [
                { id:"A", add:'A1', place:"Augsburg", src:"[A]ugsburg" },
                { id:"AA",  place:"Ostalbkreis" },
                { id:"AB", add:'AB1', place:"Aschaffenburg", src:"[A]schaffen[B]urg" },
                { id:"ABG", place:"Altenburger Land" },
                { id:"ABI", place:"Anhalt-Bitterfeld" },
                { id:"AC", place:"Aachen" },
                { id:"AE", alt:'V', place:"Vogtlandkreis" },
                { id:"AH", alt:'BOR', place:"Borken" },
                { id:"AIB", alt:'M', add:'RO', place:"München, Rosenheim" },
                { id:"AIC", place:"Aichach-Friedberg" },
                { id:"AK", place:"Altenkirchen (Westerwald)" },
                { id:"ALF", alt:'HI', place:"Hildesheim" },
                { id:"ALZ", alt:'AB', place:"Aschaffenburg" },
                { id:"AM", place:"Amberg, Stadt", note: "auslaufend Kreis, Abwicklung: Zulassungsbehörde Kreis Amberg-Sulzbach" },
                { id:"AN", add:'AN1', place:"Ansbach" },
                { id:"ANA", alt:'ERZ', place:"Erzgebirgskreis" },
                { id:"ANG", alt:'UM', place:"Uckermark" },
                { id:"ANK", alt:'VG', place:"Vorpommern-Greifswald" },
                { id:"AÖ", place:"Altötting" },
                { id:"AP", place:"Weimarer Land" },
                { id:"APD", alt:'AP', place:"Weimarer Land" },
                { id:"ARN", alt:'IK', place:"Ilm-Kreis" },
                { id:"ART", alt:'KYF', place:"Kyffhäuserkreis" },
                { id:"AS", place:"Amberg-Sulzbach" },
                { id:"ASL", alt:'SLK', place:"Salzlandkreis" },
                { id:"ASZ", alt:'ERZ', place:"Erzgebirgskreis" },
                { id:"AT", alt:'NB', place:"Mecklenburgische Seenplatte" },
                { id:"AU", alt:'ERZ', place:"Erzgebirgskreis" },
                { id:"AUR",  place:"Aurich" },
                { id:"AW", place:"Ahrweiler" },
                { id:"AZ", place:"Alzey-Worms" },
                { id:"AZE", alt:'ABI', place:"Anhalt-Bitterfeld" },
                { id:"B", place:"Berlin" },
                { id:"BA", add:'BA1', place:"Bamberg" },
                { id:"BAD", place:"Baden-Baden, Stadt" },
                { id:"BAR", place:"Barnim" },
                { id:'BB', place:'Böblingen' },
                { id:'BBG', alt:'SLK', place:'Salzlandkreis' },
                { id:'BC',  place:'Biberach' },
                { id:'BCH', alt:'MOS', place:'Neckar-Odenwald-Kreis' },
                { id:'BE', alt:'WAF', place:'Warendorf' },
                { id:'BED', alt:'FG', place:'Mittelsachsen' },
                { id:'BER', alt:'BAR', place:'Barnim' },
                { id:'BF', alt:'ST', place:'Steinfurt' },
                { id:'BGD', alt:'BGL', place:'Berchtesgadener Land' },
                { id:'BGL', place:'Berchtesgadener Land' },
                { id:'BH', alt:'OG', add:'RA', place:'Ortenaukreis, Raststatt' },
                { id:'BI', place:'Bielefeld, Stadt' },
                { id:'BID', alt:'MR', place:'Marburg-Biedenkopf' },
                { id:'BIN', alt:'MZ', place:'Mainz-Bingen' },
                { id:'BIR', place:'Birkenfeld' },
                { id:'BIT', place:'Eifelkreis Bitburg-Prüm' },
                { id:'BIW', alt:'BZ', place:'Bautzen' },
                { id:'BK', alt:'WN', add:'SHA', place:'Börde, Rems-Murr-Kreis, Schwäbisch Hall' },
                { id:'BKS', alt:'WIL', place:'Bernkastel-Wittlich' },
                { id:'BL', place:'Zollernalbkreis' },
                { id:'BLB', alt:'SI', place:'Siegen-Wittgenstein' },
                { id:'BLK', place:'Burgenlandkreis' },
                { id:'BM', place:'Rhein-Erft-Kreis' },
                { id:'BN', place:'Bonn, Stadt' },
                { id:'BNA', alt:'L', place:'Leipzig' },
                { id:'BO', place:'Bochum, Stadt' },
                { id:'BÖ', alt:'BK', place:'Börde' },
                { id:'BOG', alt:'SR', place:'Straubing-Bogen' },
                { id:'BOH', alt:'BOR', place:'Borken' },
                { id:'BOR', place:'Borken' },
                { id:'BOT', place:'Bottrop, Stadt' },
                { id:'BRA', place:'Wesermarsch' },
                { id:'BRB', place:'Brandenburg, Stadt', note: ' auslaufend Kreis, Abwicklung: Zulassungsbehörde Kreis Potsdam-Mittelmark' },
                { id:'BRG', alt:'JL', place:'Jerichower Land' },
                { id:'BRK', alt:'KG', place:'Bad Kissingen' },
                { id:"BRL", alt:'GS', place:"Goslar" },
                { id:"BRV", alt:'ROW', place:"Rotenburg (Wümme)" },
                { id:"BS", place:"Braunschweig, Stadt" },
                { id:"BSB", alt:'OS', place:"Osnabrück" },
                { id:"BSK", alt:'LOS', place:"Oder-Spree" },
                { id:"BT", add:'BT1', place:"Bayreuth" },
                { id:"BTF", alt:'ABI', place:"Anhalt-Bitterfeld" },
                { id:"BÜD", alt:'FB', place:"Wetteraukreis" },
                { id:"BÜR", alt:'PB', place:"Paderborn" },
                { id:"BUL", alt:'AS', add:'SAD', place:"Amberg-Sulzbach, Schwandorf" },
                { id:"BÜS", place:"Konstanz, Gemeinde Büsingen am Hochrhein" },
                { id:"BÜZ", alt:'LRO', place:"Rostock, Landkreis" },
                { id:"BZ", place:"Bautzen" },
                { id:"C", place:"Chemnitz, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Zwickau" },
                { id:"CA", alt:'OSL', place:"Oberspreewald-Lausitz" },
                { id:"CAS", alt:'RE', place:"Recklinghausen" },
                { id:"CB", place:"Cottbus, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Spree-Neiße" },
                { id:"CE", place:"Celle" },
                { id:"CHA", place:"Cham" },
                { id:"CLP", place:"Cloppenburg" },
                { id:"CLZ", alt:'GS', place:"Goslar" },
                { id:"CO", add:'CO1', place:"Zulassungsstelle Coburg, Zweckverband" },
                { id:"COC", place:"Cochem-Zell" },
                { id:"COE", place:"Coesfeld" },
                { id:"CR", alt:'SHA', place:"Schwäbisch Hall" },
                { id:"CUX", place:"Cuxhaven" },
                { id:"CW", place:"Calw" },
                { id:"D", place:"Düsseldorf, Stadt" },
                { id:"DA", add:'DA1', place:"Darmstadt" },
                { id:"DAH", place:"Dachau" },
                { id:"DAN", place:"Lüchow-Dannenberg" },
                { id:"DAU", place:"Vulkaneifel" },
                { id:"DBR", alt:'LRO', place:"Rostock, Landkreis" },
                { id:"DD", place:"Dresden, Stadt" },
                { id:"DE", place:"Dessau-Roßlau, Stadt" },
                { id:"DEG", place:"Deggendorf" },
                { id:"DEL", place:"Delmenhorst, Stadt" },
                { id:"DGF", place:"Dingolfing-Landau" },
                { id:"DH", place:"Diepholz" },
                { id:"DI", alt:'DA', place:"Darmstadt-Dieburg" },
                { id:"DIL", alt:'LDK', place:"Lahn-Dill-Kreis" },
                { id:"DIN", alt:'WES', place:"Wesel" },
                { id:"DIZ", alt:'EMS', place:"Rhein-Lahn-Kreis" },
                { id:"DKB", alt:'AN', place:"Ansbach" },
                { id:"DL", alt:'FG', place:"Mittelsachsen" },
                { id:"DLG",	 place:"Dillingen an der Donau" },
                { id:"DM", alt:'NB', place:"Mecklenburgische Seenplatte" },
                { id:"DN",	 place:"Düren" },
                { id:"DO",	 place:"Dortmund, Stadt" },
                { id:"DON",	 place:"Donau-Ries in Donauwörth" },
                { id:"DU",	 place:"Duisburg, Stadt" },
                { id:"DUD", alt:'GÖ', place:"Göttingen" },
                { id:"DÜW",	 place:"Bad Dürkheim" },
                { id:"DW", alt:'PIR', place:"Sächsische Schweiz-Osterzgebirge" },
                { id:"DZ", alt:'TDO', place:"Nordsachsen" },
                { id:"E",	 place:"Essen, Stadt" },
                { id:"EA", alt:'WAK', place:"Wartburgkreis" },
                { id:"EB", alt:'TDO', place:"Nordsachsen" },
                { id:"EBE", place:"Ebersberg" },
                { id:"EBN", alt:'HAS', place:"Haßberge" },
                { id:"EBS", alt:'KU', add:['BT','FO'], place:"Bayreuth 3), Forchheim, Kulmbach" },
                { id:"ECK", alt:'RD', place:"Rendsburg-Eckernförde" },
                { id:"ED",	 place:"Erding" },
                { id:"EE",	 place:"Elbe-Elster" },
                { id:"EF",	 place:"Erfurt, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Sömmerda" },
                { id:"EG", alt:'PAN', place:"Rottal-Inn" },
                { id:"EH", alt:'LOS', place:"Oder-Spree" },
                { id:"EI",	 place:"Eichstätt" },
                { id:"EIC",	 place:"Eichsfeld" },
                { id:"EIL", alt:'MSH', place:"Mansfeld-Südharz" },
                { id:"EIN", alt:'NOM', place:"Northeim" },
                { id:"EIS", alt:'SHK', place:"Saale-Holzland-Kreis" },
                { id:"EL",	 place:"Emsland" },
                { id:"EM",	 place:"Emmendingen" },
                { id:"EMD",	 place:"Emden, Stadt" },
                { id:"EMS",	 place:"Rhein-Lahn-Kreis" },
                { id:"EN",	 place:"Ennepe-Ruhr-Kreis" },
                { id:"ER",	 place:"Erlangen, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Erlangen-Höchstadt" },
                { id:"ERB",	 place:"Odenwaldkreis" },
                { id:"ERH",	 place:"Erlangen-Höchstadt" },
                { id:"ERK", alt:'HS', place:"Heinsberg" },
                { id:"ERZ",	 place:"Erzgebirgskreis" },
                { id:"ES",	 place:"Esslingen" },
                { id:"ESB", alt:'BT', add:['AS','NEW','LAU'], place:"Amberg-Sulzbach, Bayreuth, Neustadt a. d. Waldnaab, Nürnberger Land" },
                { id:"ESW", place:"Werra-Meißner-Kreis" },
                { id:"EU", place:"Euskirchen" },
                { id:"EW", alt:'BAR', place:"Barnim" },
                { id:"F", place:"Frankfurt/Main, Stadt" },
                { id:"FB", place:"Wetteraukreis in Friedberg Hessen" },
                { id:"FD", place:"Fulda" },
                { id:"FDB", alt:'AIC', place:"Aichach-Friedberg" },
                { id:"FDS", place:"Freudenstadt" },
                { id:"FEU", alt:'AN', place:"Ansbach" },
                { id:"FF", place:"Frankfurt (Oder), Stadt" },
                { id:"FFB", place:"Fürstenfeldbruck" },
                { id:"FG", place:"Mittelsachsen" },
                { id:"FI", alt:'EE', place:"Elbe-Elster" },
                { id:"FKB", alt:'KB', place:"Waldeck-Frankenberg" },
                { id:"FL", place:"Flensburg" },
                { id:"FLÖ", alt:'FG', place:"Mittelsachsen" },
                { id:"FN", place:"Bodenseekreis" },
                { id:"FO", place:"Forchheim" },
                { id:"FOR", alt:'SPN', place:"Spree-Neiße" },
                { id:"FR", add:'FR1', place:"Freiburg im Breisgau, Stadt, Breisgau-Hochschwarzwald" },
                { id:"FRG", place:"Freyung-Grafenau" },
                { id:"FRI", place:"Friesland" },
                { id:"FRW", alt:'MOL', place:"Märkisch-Oderland" },
                { id:"FS", place:"Freising, Moosburg" },
                { id:"FT", place:"Frankenthal (Pfalz), Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Bad Dürkheim und Rhein-Pfalz-Kreis" },
                { id:"FTL", alt:'PIR', place:"Sächsische Schweiz-Osterzgebirge" },
                { id:"FÜ", add:'FÜ1', place:"Fürth" },
                { id:"FÜS", alt:'OAL', place:"Ostallgäu" },
                { id:"FW", alt:'LOS', place:"Oder-Spree" },
                { id:"FZ", alt:'HR', place:"Schwalm-Eder-Kreis" },
                { id:"G", place:"Gera, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Greiz" },
                { id:"GA", alt:'SAW', place:"Altmarkkreis Salzwedel" },
                { id:"GAN", alt:'NOM', place:"Northeim" },
                { id:"GAP", place:"Garmisch-Partenkirchen" },
                { id:"GC", alt:'Z', place:"Zwickau" },
                { id:"GD", alt:'AA', place:"Ostalbkreis" },
                { id:"GDB", alt:'NWM', place:"Nordwestmecklenburg" },
                { id:"GE", place:"Gelsenkirchen, Stadt" },
                { id:"GEL", alt:'KLE', place:"Kleve" },
                { id:"GEO", alt:'SW', add:'HAS', place:"Haßberge, Schweinfurt" },
                { id:"GER", place:"Germersheim" },
                { id:"GF", place:"Gifhorn" },
                { id:"GG", place:"Groß-Gerau" },
                { id:"GHA", alt:'L', place:"Leipzig" },
                { id:"GHC", alt:'WB', place:"Wittenberg" },
                { id:"GI", place:"Gießen" },
                { id:"GK", alt:'HS', place:"Heinsberg" },
                { id:"GL", place:"Rheinisch-Bergischer Kreis" },
                { id:"GLA", alt:'RE', place:"Recklinghausen" },
                { id:"GM", place:"Oberbergischer Kreis" },
                { id:"GMN", alt:'HST', place:"Vorpommern-Rügen" },
                { id:"GN", alt:'HU', place:"Main-Kinzig-Kreis" },
                { id:"GNT", alt:'JL', place:"Jerichower Land" },
                { id:"GÖ", place:"Göttingen" },
                { id:"GOA", alt:'SIM', place:"Rhein-Hunsrück-Kreis" },
                { id:"GOH", alt:'EMS', place:"Rhein-Lahn-Kreis" },
                { id:"GP", place:"Göppingen" },
                { id:"GR", place:"Görlitz" },
                { id:"GRA", alt:'FRG', place:"Freyung-Grafenau" },
                { id:"GRH", alt:'MEI', place:"Meißen" },
                { id:"GRI", alt:'PAN', place:"Rottal-Inn" },
                { id:"GRM", alt:'L', place:"Leipzig" },
                { id:"GRZ", place:"Greiz" },
                { id:"GS", place:"Goslar" },
                { id:"GT", place:"Gütersloh" },
                { id:"GTH", place:"Gotha" },
                { id:"GUB", alt:'SPN', place:"Spree-Neiße" },
                { id:"GÜ", alt:'LRO', place:"Landkreis Rostock" },
                { id:"GUN", alt:'WUG', place:"Weißenburg-Gunzenhausen" },
                { id:"GV", alt:'NE', place:"Rhein-Kreis Neuss" },
                { id:"GVM", alt:'NWM', place:"Nordwestmecklenburg" },
                { id:"GW", alt:'VG', place:"Vorpommern-Greifswald" },
                { id:"GZ", place:"Günzburg" },
                { id:"H", place:"Hannover" },
                { id:"HA", place:"Hagen, Stadt" },
                { id:"HAB", alt:'KG', place:"Bad Kissingen" },
                { id:"HAL", place:"Halle, Stadt" },
                { id:"HAM", place:"Hamm, Stadt" },
                { id:"HAS", place:"Haßberge" },
                { id:"HB", add:'HB1', place:"Freie Hansestadt Bremen" },
                { id:"HBN", place:"Hildburghausen" },
                { id:"HBS", alt:'HZ', place:"Harz" },
                { id:"HC", alt:'FG', place:"Mittelsachsen" },
                { id:"HCH", alt:'FDS', add:'BL', place:"Freudenstadt, Zollernalbkreis" },
                { id:"HD", add:'HD1', place:"Heidelberg, Stadt, Rhein-Neckar-Kreis" },
                { id:"HDH", place:"Heidenheim" },
                { id:"HDL", alt:'BK', place:"Börde" },
                { id:"HE", place:"Helmstedt" },
                { id:"HEB", alt:'LAU', place:"Nürnberger Land" },
                { id:"HEF", place:"Hersfeld-Rotenburg" },
                { id:"HEI", place:"Dithmarschen" },
                { id:"HER", place:"Herne, Stadt" },
                { id:"HET", alt:'MSH', place:"Mansfeld-Südharz" },
                { id:"HF", place:"Herford" },
                { id:"HG", place:"Hochtaunuskreis" },
                { id:"HGN", alt:'LUP', place:"Ludwigslust-Parchim" },
                { id:"HGW", alt:'VG', place:"Hansestadt Greifswald" },
                { id:"HH", place:"Freie und Hansestadt Hamburg" },
                { id:"HHM", alt:'BLK', place:"Burgenlandkreis" },
                { id:"HI", place:"Hildesheim" },
                { id:"HIG", alt:'EIC', place:"Eichsfeld" },
                { id:"HIP", alt:'RH', place:"Roth" },
                { id:"HK", place:"Heidekreis" },
                { id:"HL", place:"Hansestadt Lübeck" },
                { id:"HM", place:"Hameln-Pyrmont" },
                { id:"HMÜ", alt:'GÖ', place:"Göttingen" },
                { id:"HN", add:'HN1', place:"Heilbronn" },
                { id:"HO", add:'HO1', place:"Hof" },
                { id:"HOG", alt:'KS', place:"Kassel" },
                { id:"HOH", alt:'HAS', place:"Haßberge" },
                { id:"HOL", place:"Holzminden außer Stadt St. Ingbert (IGB)" },
                { id:"HOR", alt:'FDS', place:"Freudenstadt" },
                { id:"HÖS", alt:'ERH', place:"Erlangen-Höchstadt" },
                { id:"HOT", alt:'Z', place:"Zwickau" },
                { id:"HP", place:"Bergstraße" },
                { id:"HR", place:"Schwalm-Eder-Kreis" },
                { id:"HRO", place:"Hansestadt Rostock" },
                { id:"HS", place:"Heinsberg" },
                { id:"HSK", place:"Hochsauerlandkreis" },
                { id:"HST", place:"Hansestadt Stralsund, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Vorpommern-Rügen" },
                { id:"HU", place:"Hanau, Main-Kinzig-Kreis" },
                { id:"HV", alt:'SDL', place:"Stendal" },
                { id:"HVL", place:"Havelland" },
                { id:"HWI", alt:'NWM', place:"Hansestadt Wismar" },
                { id:"HX", place:"Höxter" },
                { id:"HY", alt:'BZ', place:"Bautzen" },
                { id:"HZ", place:"Harz" },
                { id:"IGB", alt:'HOM', place:"St. Ingbert, Stadt" },
                { id:"IK", place:"Ilm-Kreis" },
                { id:"IL", alt:'IK', place:"Ilm-Kreis" },
                { id:"ILL", alt:'NU', place:"Neu-Ulm" },
                { id:"IN", place:"Ingolstadt, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Eichstätt" },
                { id:"IZ", place:"Steinburg" },
                { id:"J", place:"Jena, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Saale-Holzland-Kreis" },
                { id:"JE", alt:'WB', place:"Wittenberg" },
                { id:"JL", place:"Jerichower Land" },
                { id:"JÜL", alt:'DN', place:"Düren" },
                { id:"K", place:"Köln, Stadt" },
                { id:"KA", add:'KA1', place:"Karlsruhe" },
                { id:"KB", place:"Waldeck-Frankenberg" },
                { id:"KC", place:"Kronach" },
                { id:"KE", place:"Kempten (Allgäu), Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Oberallgäu" },
                { id:"KEH", place:"Kelheim" },
                { id:"KEL", alt:'OG', place:"Ortenaukreis" },
                { id:"KEM", alt:'TIR', add:'BT', place:"Bayreuth, Tirschenreuth" },
                { id:"KF", place:"Kaufbeuren, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Ostallgäu" },
                { id:"KG", place:"Bad Kissingen" },
                { id:"KH", place:"Bad Kreuznach" },
                { id:"KI", place:"Kiel" },
                { id:"KIB", place:"Donnersbergkreis" },
                { id:"KK", alt:'VIE', place:"Viersen" },
                { id:"KL", add:'KL1', place:"Kaiserslautern" },
                { id:"KLE", place:"Kleve" },
                { id:"KLZ", alt:'SAW', place:"Altmarkkreis Salzwedel" },
                { id:"KM", alt:'BZ', place:"Bautzen" },
                { id:"KN", place:"Konstanz" },
                { id:"KO", place:"Koblenz, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Kreis Mayen-Koblenz" },
                { id:"KÖN", alt:'NES', place:"Rhön-Grabfeld" },
                { id:"KÖT", alt:'ABI', place:"Anhalt-Bitterfeld" },
                { id:"KÖZ", alt:'CHA', place:"Cham" },
                { id:"KR", place:"Krefeld, Stadt" },
                { id:"KRU", alt:'GZ', place:"Günzburg" },
                { id:"KS", add: 'KS1', place:"Kassel" },
                { id:"KT", place:"Kitzingen" },
                { id:"KU", place:"Kulmbach" },
                { id:"KÜN", place:"Hohenlohekreis" },
                { id:"KUS", place:"Kusel" },
                { id:"KW", alt:'LDS', place:"Dahme-Spreewald" },
                { id:"KY", alt:'OPR', place:"Ostprignitz-Ruppin" },
                { id:"KYF", place:"Kyffhäuserkreis" },
                { id:"L", add:'L1', place:"Leipzig" },
                { id:"LA", add:'LA1', place:"Landshut" },
                { id:"LAN", alt:'DGF', place:"Dingolfing-Landau" },
                { id:"LAU", place:"Nürnberger Land" },
                { id:"LB", place:"Ludwigsburg" },
                { id:"LBS", alt:'SOK', place:"Saale-Orla-Kreis" },
                { id:"LBZ", alt:'LUP', place:"Ludwigslust-Parchim" },
                { id:"LC", alt:'LDS', place:"Dahme-Spreewald" },
                { id:"LD", add:'LD1', place:"Landau in der Pfalz, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Kreis Südliche Weinstraße" },
                { id:"LDK", place:"Lahn-Dill-Kreis in Wetzlar, Kreis" },
                { id:"LDS", place:"Dahme-Spreewald" },
                { id:"LEO", alt:'BB', place:"Böblingen" },
                { id:"LER", place:"Leer" },
                { id:"LEV", place:"Leverkusen, Stadt" },
                { id:"LF", alt:'BGL', add:['TS','AÖ'], place:"Altötting, Berchtesgadener Land, Traunstein" },
                { id:"LG", place:"Lüneburg" },
                { id:"LH", alt:'UN',add:'COE', place:"Coesfeld, Unna" },
                { id:"LI", place:"Lindau (Bodensee)" },
                { id:"LIB", alt:'EE', place:"Elbe-Elster" },
                { id:"LIF", place:"Lichtenfels" },
                { id:"LIP", place:"Lippe" },
                { id:"LL", place:"Landsberg am Lech" },
                { id:"LM", place:"Limburg-Weilburg" },
                { id:"LN", alt:'LDS', place:"Dahme-Spreewald" },
                { id:"LÖ", place:"Lörrach" },
                { id:"LÖB", alt:'GR', place:"Görlitz" },
                { id:"LOS", place:"Oder-Spree" },
                { id:"LP", alt:'SO', place:"Soest" },
                { id:"LR", alt:'OG', place:"Ortenaukreis" },
                { id:"LRO", place:"Rostock, Landkreis" },
                { id:"LSZ", alt:'UH', place:"Unstrut-Hainich-Kreis" },
                { id:"LU", place:"Ludwigshafen am Rhein", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Rheinland-Pfalz-Kreis" },
                { id:"LÜN", alt:"UN", place:"Unna" },
                { id:"LUP", place:"Ludwigslust-Parchim" },
                { id:"LWL", alt:'LUP', place:"Ludwigslust-Parchim" },
                { id:"M", add:'M1', place:"München" },
                { id:"MA", place:"Mannheim, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Rhein-Neckar-Kreis" },
                { id:"MAB", alt:'ERZ', place:"Erzgebirgskreis" },
                { id:"MAI", alt:'KEH', add:'LA', place:"Kelheim, Landshut" },
                { id:"MAK",alt:'WUN', place:"Wunsiedel i. Fichtelgebirge" },
                { id:"MAL", alt:'SR', add:'LA', place:"Landshut Straubing-Bogen" },
                { id:"MB", place:"Miesbach" },
                { id:"MC", alt:'NB', place:"Mecklenburgische Seenplatte" },
                { id:"MD", place:"Magdeburg, Stadt" },
                { id:"ME", place:"Mettmann" },
                { id:"MED", alt:'HEI', place:"Dithmarschen" },
                { id:"MEG", alt:'HR', place:"Schwalm-Eder-Kreis" },
                { id:"MEI", place:"Meißen" },
                { id:"MEK", alt:'ERZ', place:"Erzgebirgskreis" },
                { id:"MEL", alt:'OS', place:"Osnabrück" },
                { id:"MER", alt:'SK', place:"Saalekreis" },
                { id:"MET", alt:'NES', place:"Rhön-Grabfeld" },
                { id:"MG", place:"Mönchengladbach, Stadt" },
                { id:"MGH", alt:'TBB', place:"Main-Tauber-Kreis" },
                { id:"MGN", alt:'SM', place:"Schmalkalden-Meiningen" },
                { id:"MH", place:"Mülheim an der Ruhr, Stadt" },
                { id:"MHL", alt:'UH', place:"Unstrut-Hainich-Kreis" },
                { id:"MI", place:"Minden-Lübbecke" },
                { id:"MIL", place:"Miltenberg" },
                { id:"MK", place:"Märkischer Kreis" },
                { id:"MKK", alt:'HU', place:"Main-Kinzig-Kreis" },
                { id:"ML", alt:'MSH', place:"Mansfeld-Südharz" },
                { id:"MM", place:"Memmingen, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Unterallgäu" },
                { id:"MN", place:"Unterallgäu" },
                { id:"MO", alt:'WES', place:"Wesel" },
                { id:"MOD", alt:'OAL', place:"Ostallgäu" },
                { id:"MOL", place:"Märkisch-Oderland" },
                { id:"MON", alt:'AC', add:'DN', place:"Aachen, Düren" },
                { id:"MOS", place:"Neckar-Odenwald-Kreis" },
                { id:"MQ", alt:'SK', place:"Saalekreis" },
                { id:"MR", place:"Marburg-Biedenkopf" },
                { id:"MS", place:"Münster, Stadt" },
                { id:"MSE", alt:'NB', place:"Mecklenburgische Seenplatte" },
                { id:"MSH", place:"Mansfeld-Südharz" },
                { id:"MSP", place:"Main-Spessart" },
                { id:"MST", alt:'NB', place:"Mecklenburgische Seenplatte" },
                { id:"MTK", place:"Main-Taunus-Kreis" },
                { id:"MTL", alt:'L', place:"Leipzig" },
                { id:"MÜ", place:"Mühldorf am Inn" },
                { id:"MÜB", alt:'HO', add:'BT', place:"Bayreuth, Hof" },
                { id:"MÜL", alt:"FR", place:"Breisgau-Hochschwarzwald" },
                { id:"MÜR", alt:'NB', place:"Mecklenburgische Seenplatte" },
                { id:"MW", alt:'FG', place:"Mittelsachsen" },
                { id:"MY", alt:'MYK', place:"Mayen-Koblenz" },
                { id:"MYK", place:"Mayen-Koblenz" },
                { id:"MZ", add:'MZ1', place:"Mainz" },
                { id:"MZG", place:"Merzig-Wadern" },
                { id:"N", add:'LAU', place:"Nürnberg Stadt, Nürnberger Land" },
                { id:"NAB", alt:'AS', add:'SAD', place:"Amberg-Sulzbach, Schwandorf" },
                { id:"NAI", alt:'HO', place:"Hof" },
                { id:"NAU", alt:'HVL', place:"Havelland" },
                { id:"NB", place:"Neubrandenburg, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Mecklenburg-Strelitz" },
                { id:"ND", place:"Neuburg-Schrobenhausen" },
                { id:"NDH", place:"Nordhausen" },
                { id:"NE", place:"Rhein-Kreis Neuss" },
                { id:"NEA", place:"Neustadt an der Aisch-Bad Winsheim" },
                { id:"NEB", alt:'BLK', place:"Burgenlandkreis" },
                { id:"NEC", alt:'CO', add:'CO1', place:"Zulassungsstelle Coburg, Zweckverband" },
                { id:"NEN", alt:'SAD', place:"Schwandorf" },
                { id:"NES", place:"Rhön-Grabfeld" },
                { id:"NEU", alt:'FR', place:"Breisgau-Hochschwarzwald" },
                { id:"NEW", place:"Neustadt an der Waldnaab" },
                { id:"NF", place:"Nordfriesland" },
                { id:"NH", alt:'SON', place:"Sonneberg" },
                { id:"NI", place:"Nienburg (Weser)" },
                { id:"NK", place:"Neunkirchen Saar" },
                { id:"NM", place:"Neumarkt in der Oberpfalz" },
                { id:"NMB", alt:'BLK', place:"Burgenlandkreis" },
                { id:"NMS", place:"Neumünster" },
                { id:"NÖ", alt:'DON', place:"Donau-Ries" },
                { id:"NOH", place:"Grafschaft Bentheim" },
                { id:"NOL", alt:'GR', place:"Görlitz" },
                { id:"NOM", place:"Northeim" },
                { id:"NOR", alt:'AUR', place:"Aurich" },
                { id:"NP", alt:'OPR', place:"Ostprignitz-Ruppin" },
                { id:"NR", place:"Neuwied" },
                { id:"NT", alt:'ES', place:"Esslingen" },
                { id:"NU", place:"Neu-Ulm" },
                { id:"NVP", alt:'HST', place:"Vorpommern-Rügen" },
                { id:"NW", place:"Neustadt an der Weinstraße", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Bad Dürkheim" },
                { id:"NWM", place:"Nordwestmecklenburg" },
                { id:"NY", alt:'GR', place:"Görlitz" },
                { id:"NZ", alt:'NB', place:"Mecklenburgische Seenplatte" },
                { id:"OA", place:"Oberallgäu" },
                { id:"OAL", place:"Ostallgäu" },
                { id:"OB", place:"Oberhausen, Stadt" },
                { id:"OBB", alt:'MIL', place:"Miltenberg" },
                { id:"OBG", alt:'SDL', place:"Stendal" },
                { id:"OC", alt:'BK', place:"Börde" },
                { id:"OCH", alt:'WÜ', place:"Würzburg" },
                { id:"OD", place:"Stormarn" },
                { id:"OE", place:"Olpe" },
                { id:"OF", add:'OF1', place:"Offenbach am Main" },
                { id:"OG", place:"Ortenaukreis" },
                { id:"OH", place:"Ostholstein" },
                { id:"OHA", alt:'GÖ', place:"Göttingen" },
                { id:"ÖHR", alt:'KÜN', place:"Hohenlohekreis" },
                { id:"OHV", place:"Oberhavel" },
                { id:"OHZ", place:"Osterholz" },
                { id:"OK", alt:'BK', place:"Börde" },
                { id:"OL", add:'OL1', place:"Oldenburg (Oldenburg)" },
                { id:"OP", alt:'LEV', place:"Leverkusen, Stadt" },
                { id:"OPR", place:"Ostprignitz-Ruppin" },
                { id:"OS", add: 'OS1', place:"Osnabrück" },
                { id:"OSL", place:"Oberspreewald-Lausitz" },
                { id:"OTW", alt:'NK', place:"Neunkirchen" },
                { id:"OVI", alt:'SAD', place:"Schwandorf" },
                { id:"OVL", alt:'V', place:"Vogtlandkreis" },
                { id:"OVP", alt:'VG', place:"Vorpommern-Greifswald" },
                { id:"OZ", alt:'TDO', place:"Nordsachsen" },
                { id:"P", place:"Potsdam, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Kreis Potsdam-Mittelmark" },
                { id:"PA", add:'PA1', place:"Passau" },
                { id:"PAF", place:"Pfaffenhofen a. d. Ilm" },
                { id:"PAN", place:"Rottal-Inn" },
                { id:"PAR", alt:'NM', add:'KEH', place:"Kehlheim, Neumarkt i. d. Opf." },
                { id:"PB", place:"Paderborn" },
                { id:"PCH", alt:'LUP', place:"Ludwigslust-Parchim" },
                { id:"PE", place:"Peine" },
                { id:"PEG", alt:'BT', add:['LAU','FO'], place:"Bayreuth, Forchheim , Nürnberger Land" },
                { id:"PF", add:'PF1', place:"Pforzheim, Stadt, Enzkreis" },
                { id:"PI", place:"Pinneberg" },
                { id:"PIR", place:"Sächsische Schweiz-Osterzgebirge" },
                { id:"PL", alt:'V', place:"Vogtlandkreis" },
                { id:"PLÖ", place:"Plön" },
                { id:"PM", place:"Potsdam-Mittelmark" },
                { id:"PN", alt:'SOK', place:"Saale-Orla-Kreis" },
                { id:"PR", place:"Prignitz" },
                { id:"PRÜ", alt:'BIT', place:"Eifelkreis Bitburg-Prüm" },
                { id:"PS", add:'PS1', place:"Pirmasens, Stadt, Südwestpfalz" },
                { id:"PW", alt:'VG', place:"Vorpommern-Greifswald" },
                { id:"PZ", alt:'UM', place:"Uckermark" },
                { id:"QFT", alt:'SK', place:"Saalekreis" },
                { id:"QLB", alt:'HZ', place:"Harz" },
                { id:"R", add:'R1', place:"Regensburg" },
                { id:"RA", place:"Rastatt" },
                { id:"RC", alt:'V', place:"Vogtlandkreis" },
                { id:"RD", place:"Rendsburg-Eckernförde" },
                { id:"RDG", alt:'HST', place:"Vorpommern-Rügen" },
                { id:"RE", place:"Recklinghausen" },
                { id:"REG", place:"Regen" },
                { id:"REH", alt:'HO', add:'WUN', place:"Hof, Wunsiedel im Fichtelgebirge" },
                { id:"REI", alt:'BGL', place:"Berchtesgadener Land" },
                { id:"RG", alt:'MEI', place:"Meißen" },
                { id:"RH", place:"Roth" },
                { id:"RI", alt:'SHG', place:"Schaumburg" },
                { id:"RID", alt:'KEH', place:"Kelheim" },
                { id:"RIE", alt:'MEI', place:"Meißen" },
                { id:"RL", alt:'FG', place:"Mittelsachsen" },
                { id:"RM", alt:'NB', place:"Mecklenburgische Seenplatte" },
                { id:"RN", alt:'HVL', place:"Havelland" },
                { id:"RO", add:'RO1', place:"Rosenheim" },
                { id:"ROD", alt:'CHA', add:'SAD', place:"Cham, Schwandorf" },
                { id:"ROF", alt:'HEF', place:"Hersfeld-Rotenburg" },
                { id:"ROK", alt:'KIB', place:"Donnersbergkreis" },
                { id:"ROL", alt:'KEH', add:'LA', place:"Kelheim, Landshut" },
                { id:"ROS", alt:'LRO', place:"Rostock, Landkreis" },
                { id:"ROT", alt:'AN', place:"Ansbach" },
                { id:"ROW", place:"Rotenburg (Wümme)" },
                { id:"RP", place:"Rhein-Pfalz-Kreis" },
                { id:"RS", place:"Remscheid, Stadt" },
                { id:"RSL", alt:'DE', place:"Dessau-Roßlau, Stadt" },
                { id:"RT", place:"Reutlingen" },
                { id:"RU", alt:'SLF', place:"Saalfeld-Rudolstadt" },
                { id:"RÜD", place:"Rheingau-Taunus Kreis" },
                { id:"RÜG", alt:'HST', place:"Vorpommern-Rügen" },
                { id:"RV", place:"Ravensburg" },
                { id:"RW", place:"Rottweil" },
                { id:"RZ", place:"Herzogtum Lauenburg" },
                { id:"S", place:"Stuttgart, Stadt" },
                { id:"SAB", alt:'TR', place:"Trier-Saarburg" },
                { id:"SAD", place:"Schwandorf" },
                { id:"SÄK", alt:'WT', place:"Waldshut" },
                { id:"SAN", alt:'KC', add:'HO', place:"Hof, Kronach, Kulmbach" },
                { id:"SAW", place:"Altmarkkreis Salzwedel" },
                { id:"SB", place:"Saarbrücken, Stadt und Stadtverband außer Völklingen, Stadt (VK)" },
                { id:"SBG", alt:'VG', place:"Vorpommern-Greifswald" },
                { id:"SBK", alt:'SLK', place:"Salzlandkreis" },
                { id:"SC", place:"Schwabach, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Roth" },
                { id:"SCZ", alt:'SOK', place:"Saale-Orla-Kreis" },
                { id:"SDH", alt:'KYF', place:"Kyffhäuserkreis" },
                { id:"SDL", place:"Stendal" },
                { id:"SDT", alt:'UM', place:"Uckermark" },
                { id:"SE", place:"Segeberg" },
                { id:"SEB", alt:'PIR', place:"Sächsische Schweiz-Osterzgebirge" },
                { id:"SEE", alt:'MOL', place:"Märkisch-Oderland" },
                { id:"SEF", alt:'NEA', place:"Neustadt a. d. Aisch-Bad Windsheim" },
                { id:"SEL", alt:'WUN', place:"Wunsiedel i. Fichtelgebirge" },
                { id:"SFB", alt:'OSL', place:"Oberspreewald-Lausitz" },
                { id:"SFT", alt:'SLK', place:"Salzlandkreis" },
                { id:"SG", place:"Solingen, Stadt" },
                { id:"SGH", alt:'MSH', place:"Mansfeld-Südharz" },
                { id:"SHA", place:"Schwäbisch Hall" },
                { id:"SHG", place:"Schaumburg" },
                { id:"SHK", place:"Saale-Holzland-Kreis" },
                { id:"SHL", place:"Suhl, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Hildburghausen" },
                { id:"SI", place:"Siegen-Wittgenstein" },
                { id:"SIG", place:"Sigmaringen" },
                { id:"SIM", place:"Rhein-Hunsrück-Kreis" },
                { id:"SK", place:"Saalekreis" },
                { id:"SL", place:"Schleswig-Flensburg" },
                { id:"SLE", alt:'DN', add:'EU', place:"Düren, Euskirchen" },
                { id:"SLF", place:"Saalfeld-Rudolstadt" },
                { id:"SLG", alt:'RV', add:'SIG', place:"Ravensburg, Sigmaringen" },
                { id:"SLK", place:"Salzlandkreis" },
                { id:"SLN", alt:'ABG', place:"Altenburger Land" },
                { id:"SLS", place:"Saarlouis" },
                { id:"SLÜ", alt:'HU', place:"Main-Kinzig-Kreis" },
                { id:"SLZ", alt:'WAK', place:"Wartburgkreis" },
                { id:"SM", place:"Schmalkalden-Meiningen" },
                { id:"SMÜ", alt:'A', place:"Augsburg" },
                { id:"SN", place:"Schwerin, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Ludwiglust-Parchim" },
                { id:"SO", place:"Soest" },
                { id:"SOB", alt:'ND', place:"Neuburg-Schrobenhausen" },
                { id:"SOG", alt:'WM', place:"Weilheim-Schongau" },
                { id:"SOK", place:"Saale-Orla-Kreis" },
                { id:"SÖM", place:"Sömmerda" },
                { id:"SON", place:"Sonneberg" },
                { id:"SP", place:"Speyer, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Rhein-Pfalz-Kreis" },
                { id:"SPB", alt:'SPN', place:"Spree-Neiße" },
                { id:"SPN", place:"Spree-Neiße" },
                { id:"SR", add:'SR1', place:"Straubing, Straubing-Bogen" },
                { id:"SRB", alt:'MOL', place:"Märkisch-Oderland" },
                { id:"SRO", alt:'SHK', place:"Saale-Holzland-Kreis" },
                { id:"ST", place:"Steinfurt" },
                { id:"STA", place:"Starnberg" },
                { id:"STB", alt:'LUP', place:"Ludwigslust-Parchim" },
                { id:"STD", place:"Stade" },
                { id:"STE", alt:'LIF', place:"Lichtenfels" },
                { id:"STL", alt:'ERZ', place:"Erzgebirgskreis" },
                { id:"STO", alt:'SIG', add:'KN', place:"Konstanz, Sigmaringen" },
                { id:"SU", place:"Rhein-Sieg-Kreis" },
                { id:"SUL", alt:'AS', place:"Amberg-Sulzbach" },
                { id:"SÜW", place:"Südliche Weinstraße" },
                { id:"SW", add:'SW1', place:"Schweinfurt" },
                { id:"SWA", alt:'RÜD', place:"Rheingau-Taunus-Kreis" },
                { id:"SY", alt:'DH', place:"Diepholz" },
                { id:"SZ", place:"Salzgitter, Stadt" },
                { id:"SZB", alt:'ERZ', place:"Erzgebirgskreis" },
                { id:"TBB", place:"Main-Tauber-Kreis" },
                { id:"TDO", place:"Nordsachsen" },
                { id:"TE", alt:'ST', place:"Steinfurt" },
                { id:"TET", alt:'LRO', place:"Rostock, Landkreis" },
                { id:"TF", place:"Teltow-Fläming" },
                { id:"TG", alt:'TDO', place:"Nordsachsen" },
                { id:"TIR", place:"Tirschenreuth" },
                { id:"TO", alt:'TDO', place:"Nordsachsen" },
                { id:"TÖL", place:"Bad Tölz-Wolfratshausen" },
                { id:"TP", alt:'UM', place:"Uckermark" },
                { id:"TR", add:'TR1', place:"Trier, Stadt und Trier-Saarburg" },
                { id:"TS", place:"Traunstein" },
                { id:"TT", alt:'FN', place:"Bodenseekreis" },
                { id:"TÜ", place:"Tübingen" },
                { id:"TUT", place:"Tuttlingen" },
                { id:"UE", place:"Uelzen" },
                { id:"ÜB", alt:'FN', add:['RV','SIG'], place:"Ravensburg, Bodenseekreis, Sigmaringen" },
                { id:"UEM", alt:'VG', place:"Vorpommern-Greifswald" },
                { id:"UER", alt:'VG', place:"Vorpommern-Greifswald" },
                { id:"UFF", alt:'NEA', place:"Neustadt a. d. Aisch-Bad Windsheim" },
                { id:"UH", place:"Unstrut-Hainich-Kreis" },
                { id:"UL", add:'UL1', place:"Ulm, Stadt, Alb-Donaukreis" },
                { id:"UM", place:"Uckermark" },
                { id:"UN", place:"Unna" },
                { id:"USI", alt:'HG', place:"Hochtaunuskreis" },
                { id:"V", place:"Vogtlandkreis" },
                { id:"VAI", alt:'LB', place:"Ludwigsburg" },
                { id:"VB", place:"Vogelsbergkreis" },
                { id:"VEC", place:"Vechta" },
                { id:"VER", place:"Verden" },
                { id:"VG", place:"Vorpommern-Greifswald" },
                { id:"VIB", alt:'LA', add:['MÜ','PAN'], place:"Landshut, Rottal-Inn" },
                { id:"VIE", place:"Viersen" },
                { id:"VIT", alt:'REG', place:"Regen" },
                { id:"VK", alt:'SB', place:"Völklingen, Stadt" },
                { id:"VOH", alt:'NEW', place:"Neustadt a. d. Waldnaab" },
                { id:"VR", alt:'HST', place:"Vorpommern-Rügen" },
                { id:"VS", place:"Schwarzwald-Baar-Kreis" },
                { id:"W", place:"Wuppertal, Stadt" },
                { id:"WA", alt:'KB', place:"Waldeck-Frankenberg" },
                { id:"WAF", place:"Warendorf" },
                { id:"WAK", place:"Wartburgkreis" },
                { id:"WAN", alt:'HER', place:"Herne, Stadt" },
                { id:"WAR", alt:'HX', place:"Höxter" },
                { id:"WAT", alt:'BO', place:"Bochum, Stadt" },
                { id:"WB", place:"Wittenberg" },
                { id:"WBG", alt:'WOB', place:"Wolfsburg, Stadt" },
                { id:"WBS", alt:'EIC', place:"Eichsfeld" },
                { id:"WDA", alt:'Z', place:"Zwickau" },
                { id:"WE", place:"Weimar, Stadt", note:"auslaufend Kreis, Abwicklung: Zulassungsbehörde Weimarer Land" },
                { id:"WEL", alt:'LM', place:"Limburg-Weilburg" },
                { id:"WEN", place:"Weiden in der Oberpfalz, Stadt" },
                { id:"WER", alt:'A', add:'DLG', place:"Augsburg, Dillingen a. d. Donau" },
                { id:"WES", place:"Wesel" },
                { id:"WF", place:"Wolfenbüttel" },
                { id:"WG", alt:'RV', place:"Ravensburg" },
                { id:"WHV", place:"Wilhelmshaven, Stadt" },
                { id:"WI", place:"Wiesbaden, Stadt" },
                { id:"WIL", place:"Bernkastel-Wittlich" },
                { id:"WIS", alt:'NWM', place:"Nordwestmecklenburg" },
                { id:"WIT", alt:'EN', place:"Ennepe-Ruhr-Kreis" },
                { id:"WIZ", alt:'ESW', place:"Werra-Meißner-Kreis" },
                { id:"WK", alt:'OPR', place:"Ostprignitz-Ruppin" },
                { id:"WL", place:"Harburg" },
                { id:"WLG", alt:'VG', place:"Vorpommern-Greifswald" },
                { id:"WM", place:"Weilheim-Schongau" },
                { id:"WMS", alt:'BK', place:"Börde" },
                { id:"WN", place:"Rems-Murr-Kreis" },
                { id:"WND", place:"St. Wendel" },
                { id:"WO", place:"Worms, Stadt" },
                { id:"WOB", place:"Wolfsburg, Stadt" },
                { id:"WOH", alt:'KS', place:"Kassel" },
                { id:"WOL", alt:'FDS', add:'OG', place:"Freudenstadt, Ortenaukreis" },
                { id:"WOR", alt:'M', add:['TÖL','STA'], place:"Bad Tölz-Wolfratshausen, München, Starnberg" },
                { id:"WOS", alt:'FRG', place:"Freyung-Grafenau" },
                { id:"WR", alt:'HZ', place:"Harz" },
                { id:"WRN", alt:'NB', place:"Mecklenburgische Seenplatte" },
                { id:"WS", alt:'MÜ', add:'RO', place:"Mühldorf am Inn, Rosenheim" },
                { id:"WSF", alt:'BLK', place:"Burgenlandkreis" },
                { id:"WST", place:"Ammerland" },
                { id:"WSW", alt:'GR', place:"Görlitz" },
                { id:"WT", place:"Waldshut" },
                { id:"WTL", alt:'OS', place:"Osnabrück" },
                { id:"WTM", place:"Wittmund" },
                { id:"WÜ", add:'WÜ1', place:"Würzburg" },
                { id:"WUG", place:"Weißenburg-Gunzenhausen" },
                { id:"WÜM", alt:'CHA', place:"Cham" },
                { id:"WUN", place:"Wunsiedel im Fichtelgebirge" },
                { id:"WUR", alt:'L', place:"Leipzig" },
                { id:"WW", place:"Westerwald" },
                { id:"WZ", alt:'LDK', place:"Lahn-Dill-Kreis" },
                { id:"WZL", alt:'BK', place:"Börde" },
                { id:"Z", place:"Zwickau" },
                { id:"ZE", alt:'ABI', place:"Anhalt-Bitterfeld" },
                { id:"ZEL", alt:'COC', place:"Cochem-Zell" },
                { id:"ZI", alt:'GR', place:"Görlitz" },
                { id:"ZIG", alt:'HR', place:"Schwalm-Eder-Kreis" },
                { id:"ZP", alt:'ERZ', place:"Erzgebirgskreis" },
                { id:"ZR", alt:'GRZ', place:"Greiz" },
                { id:"ZW", add:'ZW', alt:'PS', place:"Südwestpfalz, Zweibrücken, Stadt" },
                { id:"ZZ", alt:'BLK', place:"Burgenlandkreis" },
            ],
            svg: null,
            current: [],
            mask: null,
            info: '',
            selected: null,
            pz: null,
            pan: { x:0, y:0, mx:0, my:0, cx:0, cy: 0}
        }
    },
    computed: {
        filtered() {
            if (this.mask)
                return this.codes.filter(c => c.id.substring(0,1) == this.mask);
            return this.codes;
        }
    },
    mounted() {
        const index = {}
        this.codes.forEach(c => {
            const a = c.id.substring(0,1);
            index[a] = 1;
        })

        this.letters = Object.keys(index).map(x => x);
        // this.svg = document.getElementById('svg1').contentDocument;
        
    },
    methods: {
        onWheel(e) { console.log(e); },
        up() { let {x,y} = this.pz.getPan(); y-=100; this.pz.pan( { x, y }); this.x = x; this.y = y; },
        down() { let {x,y} = this.pz.getPan(); y+=100; this.pz.pan( { x, y }); this.x = x; this.y = y; },
        left() { let {x,y} = this.pz.getPan(); x-=100; this.pz.pan( { x:x, y }); this.x = x; this.y = y; },
        right() { let {x,y} = this.pz.getPan(); x+=100; this.pz.pan( { x, y }); this.x = x; this.y = y; },
        setPos( cx, cy ) {
            this.svg = document.getElementById('svg1').contentDocument;
            const m = this.svg.getElementById('svg1');
            const {x,y,width,height} = m.getBoundingClientRect();   
            const tm = [3, 0, 0, 3, 0, 0];
            tm[4] = -cx; //-y/scale; //-cy * 0.5;
            tm[5] = -cy; //-x/scale; //-cx * 0.125;
            const mat = `matrix(${tm.join(' ')})`;
            console.log(x,y,width,height,mat);
            m.setAttributeNS(null, "transform", mat);
        },
        get(t) {
            return Vue.compile(t);
        },
        scan() {
            // const a = document.getElementById('svg').contentDocument;
            this.codes.forEach(c => {
                var t = a.getElementById(c.id);
                c.missed = 0;
                if (!t)
                    c.missed = 1;
            })
        },
        filter(c) {
            this.mask = c;
            var myDiv = document.getElementById('selector');
            myDiv.scrollTop = 0;
        },
        hide(ids) {
            this.selected = null;
            this.svg = document.getElementById('svg1').contentDocument;
            ids.forEach(id => {
                var t = this.svg.getElementById(id);
                t.setAttributeNS(null, 'fill', null);
            });
            this.current = [];
            this.info = '';
        },
        infoText(c) {
            return (c.src || c.place).replace(/\[(\w+)\]/g, '<strong>$1</strong>')
        },
        show(num) {
            this.current && this.hide(this.current);
            const id = num.alt || num.id;
            this.mark(id);
            this.selected = num.id;
            this.info = this.infoText(num);
            if (num.add) {
                if (Array.isArray(num.add)) {
                    num.add.forEach(a => this.mark(a));
                    return;
                }
                this.mark(num.add);
            }
            this.circle(num);
        },
        mark(id) {
            this.svg = document.getElementById('svg1').contentDocument;
            var t = this.svg.getElementById(id);
            t.setAttributeNS(null, 'fill', '#ff0000');
            this.current.push(id);
        },
        circle(c) {
            this.svg = document.getElementById('svg1').contentDocument;
            const ids = [];
            ids.push([c.alt || c.id ]);
            if (c.add) {
                if (Array.isArray(c.add)) {
                    c.add.forEach(a => ids.push(a));
                } else
                    ids.push(c.add);
            }
            let xmin = 999999;
            let ymin = 999999;
            let xmax = 0;
            let ymax = 0;
            ids.forEach(t => {
                const e = this.svg.getElementById(t);
                const { x, y, width, height } = e.getBBox();
                if (x < xmin) xmin = x;
                if (y < ymin) ymin = y;
                if (x+width > xmax) xmax = x+width;
                if (y+height > ymax) ymax = y+height;
                console.log(t,x,y,width, height, e);
            })
            const cx = (xmin + xmax) / 2;
            const cy = (ymin + ymax) / 2;
            const r = Math.max(xmax-xmin, ymax-ymin)/2;
            console.log(ids, cx, cy, r);
            const m = this.svg.getElementById('circle');
            m.setAttributeNS(null, 'cx', cx);
            m.setAttributeNS(null, 'cy', cy);
            m.setAttributeNS(null, 'r', r + 20);
            
            // this.zoom(1, cx, cy);
        },
        zoom(scale, cx, cy) {
            const t = document.getElementById('svg1').contentDocument;
            const svg = t.getElementById('rootsvg');
            const { x, y, width, height } = svg.getBBox();
            const mx = x + width/2;
            const my = y + height/2;
            this.pz = this.pz || new svgPanZoom(svg, {});
            console.log('vp1',this.pz.getPan());
            this.pan = { x, y, mx, my, cx, cy };
            this.pz.zoom(scale);
            this.x = -cx*0.5*scale;
            this.y = -cy*0.5*scale;
            this.pz.pan({ x: this.x, y: this.y });
            console.log('vp2',this.pz.getPan());
            return;

            // this.pz = this.pz || new spz('#svg', { zoom: { factor: 0.5 }, pan: { factor: 100 }});
            // const {x,y,width,height} = svg.getBoundingClientRect();   
            // const tm = [1, 0, 0, 1, 0, 0];
            // for (var i = 0; i < 4; i++) {
            //     tm[i] *= scale;
            // }
            // tm[4] = -cx*(scale-1); //-y/scale; //-cy * 0.5;
            // tm[5] = -cy*(scale-1); //-x/scale; //-cx * 0.125;
            // const mat = `matrix(${tm.join(' ')})`;
            // console.log(x,y,width,height, cx, cy, mat);
            // svg.setAttributeNS(null, "transform", mat);
        }
    }
}
</script>

<style lang="less" scoped>
@import (reference) '../shared.less';
    .kfz {
        margin: 0.25rem 1rem;
        width: 10rem;
    }
    .num {
        display:flex;
        align-items: center;
        color: #3333c9;
        font-family: kfz;
        font-size: 4rem;
        margin: 0;
        border-width: 0.2rem;
        border-style: solid;
        border-color: #000;
        border-radius: 0.5rem;
        padding: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(/img/euro.png);
        cursor: pointer;
    }

    @font-face {
        font-family: kfz;
        src: url(/EuroPlate.ttf);
    }
    .d {
        width: 3rem;
        height: 4rem;
    }
    .id {
        color: black;
        margin-right: 1rem;
    }
    .info {
        color:#123;
    }
    .letter {
        flex:0 0 auto;
        margin: 0.25rem 1rem;
        border: 1px solid gray;
        width:2rem;
        font-size: 2rem;
        border-radius: 0.25rem;
        color: black;
        cursor: pointer;
    }
    .missed {
        background-color:#f88;
    }
    .selected {
        background-color: #dfd;
    }
    .title {
        height:3rem;
        font-size:2rem;
        color:#000;
    }

</style>