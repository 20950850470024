<template lang="pug">
div(style="flex: 1 0; display: flex; flex-flow: column")
  div(style="flex: 0 0 auto; display: flex; padding: 0.5rem")
    PentominoSolver(
      ref="solver",
      @solved="saveSolution($event)",
      @ready="ready()"
    )
    button3d(@click="solve()") SOLVE
    div(style="font-size:2rem;width:4rem;margin:0 1rem;") {{ count }}

    button3d(@click="prev()",:disabled="count < 1 || currentSolution < 1")
      icon(name="chevronLeft")
    div(style="font-size:2rem;width:4rem;margin:0 1rem;") {{currentSolution}}
    button3d(@click="next()",:disabled="count < 1 || currentSolution >= count-1")
      icon(name="chevronRight")
    button3d(@click="play()",style="margin-left:0.5rem;",:disabled="count < 1 || moving")
      icon(name="play")
    button3d(@click="reset()",style="margin:0 0.5rem;",:disabled="moving") Reset
  #container(style="display: flex; flex: 1 0")
</template>

<script>
import { mapGetters } from "vuex";
import PentominoSolver from "@/components/PentominoSolver.vue";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import * as T from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
export default {
  components: { PentominoSolver, Icon, Button3d },
  data() {
    return {
      // count: 0,
      cnt: 0,
      width: 0,
      height: 0,
      renderer: null,
      scene: null,
      green: null,
      textureCube: null,
      pos: [],
      // solutions: [],
      moving: null,
      shape: 0,
      phase: 0,
      progress: 0,
      currentSolution: 0
    };
  },
  computed: {
    ...mapGetters({ count: "pentomino/count", solutions: "pentomino/solutions" })
  },
  async mounted() {
     const loader = new T.CubeTextureLoader();
      loader.setCrossOrigin("");
      loader.setPath("/img/cube/SaintLazarusChurch2/");
      this.textureCube = await loader.load([
        "posx.jpg",
        "negx.jpg",
        "posy.jpg",
        "negy.jpg",
        "posz.jpg",
        "negz.jpg",
      ]);
    this.cF = new T.MeshStandardMaterial({ envMap:this.textureCube, color: "#ff8800", side: T.DoubleSide, metalness: 1, roughness: 0.001 });
    this.cI = new T.MeshStandardMaterial({ envMap:this.textureCube, color: "#00ffff", side: T.DoubleSide, metalness: 1, roughness: 0.001 });
    this.cL = new T.MeshStandardMaterial({ envMap:this.textureCube, color: "#8800ff", side: T.DoubleSide, metalness: 1, roughness: 0.001 });
    this.cN = new T.MeshStandardMaterial({ envMap:this.textureCube, color: "#00aa88", side: T.DoubleSide, metalness: 1, roughness: 0.001 });
    this.cP = new T.MeshStandardMaterial({ envMap:this.textureCube, color: "#4444ff", side: T.DoubleSide, metalness: 1, roughness: 0.001 });
    this.cT = new T.MeshStandardMaterial({ envMap:this.textureCube, color: "#ff0000", side: T.DoubleSide, metalness: 1, roughness: 0.001 });
    this.cU = new T.MeshStandardMaterial({ envMap:this.textureCube, color: "#ffff00", side: T.DoubleSide, metalness: 1, roughness: 0.001 });
    this.cV = new T.MeshStandardMaterial({ envMap:this.textureCube, color: "#888888", side: T.DoubleSide, metalness: 1, roughness: 0.001 });
    this.cW = new T.MeshStandardMaterial({ envMap:this.textureCube, color: "#0000cc", side: T.DoubleSide, metalness: 1, roughness: 0.001 });
    this.cX = new T.MeshStandardMaterial({ envMap:this.textureCube, color: "#008800", side: T.DoubleSide, metalness: 1, roughness: 0.001 });
    this.cY = new T.MeshStandardMaterial({ envMap:this.textureCube, color: "#00ff00", side: T.DoubleSide, metalness: 1, roughness: 0.001 });
    this.cZ = new T.MeshStandardMaterial({ envMap:this.textureCube, color: "#ff00cc", side: T.DoubleSide, metalness: 1, roughness: 0.001 });
    this.green = new T.MeshStandardMaterial({
      color: "#00ff00",
    });
    this.white = new T.MeshStandardMaterial({
      envMap:this.textureCube,
      color: "#ffffff",
      metalness: 1, 
      roughness: 0.1
    });
    await this.setCanvas();
    this.animate();
  },
  methods: {
    async solve() {
      await this.$refs.solver.solve();
    },
    prev() {
      if (this.currentSolution > 0)
        this.currentSolution--;
    },
    next() {
      if (this.currentSolution < this.solutions.length-1)
        this.currentSolution++;
    },
    play() {
      this.reset();
      this.start(this.solutions[this.currentSolution], 50);
    },
    ready() {
      console.log('READY', this.solutions);
      // this.start(this.solutions[0]);
    },
    saveSolution(item) {

        console.log(this.$store.state.pentomino.count);
        setTimeout(() => {
          this.$forceUpdate();
        }, 100)
      //  this.$nextTick(() => {
      //   this.count = e.count;
      //   this.solutions.push(e.stack);
      //  });
    },
    start(stack, speed) {
      this.moving = {
        stack,
        shape: 0,
        phase: 0,
        progress: 0,
        speed
      };
    },
    reset() {
      // this.start(this.startPositions, 500);
      this.initPositions(this.shapes);
    },
    logobj(h, id, p, o) {
      if (p < 1 || p >= 100)
        console.log(
          `${h}: ${id} - ${p} - (${o.position.x} ${o.position.y} ${o.position.z}) ${o.rotation.x} ${o.rotation.y} ${o.rotation.z}`
        );
    },
    savePos(s) {
      return {
        x: s.position.x,
        y: s.position.y,
        z: s.position.z,
        r: s.rotation.y,
        s: s.rotation.z,
      };
    },
    moveShapes(m) {
      const speed = m.speed || 50;
      let cs = m.stack[m.shape];
      let id = cs.id;
      let s = this.shapes[id];
      switch (m.phase) {
        case 0: //up
          if (m.progress < 1) {
            m.init = this.savePos(s);
          }
          m.progress += 0.1 * speed;
          s.position.y = (m.progress * 2) / 100;
          if (m.progress >= 100) {
            s.position.y = 2;
            m.phase = 1;
            m.progress = 0;
          }
          break;
        case 2: //rotate
          const r0 = m.init.r; //Math.round((m.init.r * 2) / Math.PI);
          const r1 = cs.rotated * Math.PI / 2;
          if (r0 != cs.rotated) {
            // const a = cs.rotated == 3 ? -1 : cs.rotated;
            // let d = cs.rotated * 0.5 * Math.PI - m.init.r;

            s.rotation.y = r0 + ((r1-r0) * m.progress) / 100;
            m.progress += 0.1 * speed;
            if (m.progress >= 100) {
              s.rotation.y = r1;
              m.phase = 3;
              m.progress = 0;
            }
          } else {
            m.phase = 3;
            m.progress = 0;
          }
          break;
        case 1: //mirror
          const a0 = m.init.s;
          const a1 = (cs.mirrored ? 1 : 0) * Math.PI;
          if (cs.mirrored || m.init.s) {
            const a = a0 + ((a1-a0)*m.progress) / 100;
            s.rotation.z = a; //m.init.s + (Math.PI * m.progress) / 100;
            m.progress += 0.1 * speed;
            if (m.progress >= 100) {
              s.rotation.z = a1; //m.init.s + Math.PI;
              m.phase = 2;
              m.progress = 0;
            }
          } else {
            m.phase = 2;
            m.progress = 0;
          }
          break;
        case 3: //move
          const x0 = m.init.x;
          const z0 = m.init.z;
          const x1 = cs.x - 4.5;
          const z1 = cs.y - 2.5;
          m.progress += 0.1 * speed;
          const x = x0 + ((x1 - x0) * m.progress) / 100;
          const z = z0 + ((z1 - z0) * m.progress) / 100;
          s.position.x = x;
          s.position.z = z;

          if (m.progress >= 100) {
            s.position.x = x1;
            s.position.z = z1;
            m.phase = 4;
            m.progress = 0;
          }
          break;
        case 4: //down
          s.position.y = ((100 - m.progress) * 2) / 100;
          m.progress += 0.1 * speed;
          if (m.progress >= 100) {
            s.position.y = 0;
            m.phase = 5;
            m.progress = 0;
          }
          break;
        case 5: //next shape
          m.shape++;
          cs = m.stack[m.shape];
          if (cs) {
            id = cs.id;
            s = this.shapes[id];
            m.phase = 0;
            m.progress = 0;
          } else {
            this.moving = null;
          }
          break;
      }
    },
    async load(name, color) {
      const loader = new STLLoader();
      return new Promise((resolve, reject) => {
        loader.load(
          `/img/${name}.stl`,
          (object) => {
            const p = new T.Mesh(object, color);
            p.geometry.scale(1, 1, 1);
            this.scene.add(p);
            return resolve(p);
          },
          (xhr) => {},
          (error) => {
            console.log(error);
            reject(error);
          }
        );
      });
    },
    move(obj, x, y, a, s) {
      obj.rotation.z = s ? Math.PI : 0;
      obj.rotation.y = (a * 90 * Math.PI) / 180;
      obj.position.x = x;
      obj.position.z = y;
    },
    createBox() {
      const floor = new T.BoxGeometry(10.2, 0.01, 6.2).translate(0, -0.25, 0);
      this.scene.add(new T.Mesh(floor, this.white));
      const left = new T.BoxGeometry(0.1, 0.6, 6.2).translate(-5.05, 0.05, 0);
      this.scene.add(new T.Mesh(left, this.white));
      const right = new T.BoxGeometry(0.1, 0.6, 6.2).translate(5.05, 0.05, 0);
      this.scene.add(new T.Mesh(right, this.white));
      const back = new T.BoxGeometry(10, 0.6, 0.1).translate(0, 0.05, -3.05);
      this.scene.add(new T.Mesh(back, this.white));
      const front = new T.BoxGeometry(10, 0.6, 0.1).translate(0, 0.05, 3.05);
      this.scene.add(new T.Mesh(front, this.white));
    },
    initPositions(s) {
      this.move(s.F, 6, 3, 0);
      this.move(s.I, 6, -3, 0);
      this.move(s.L, 7, -1, 0);
      this.move(s.N, -6, -2, 0);
      this.move(s.P, 6, -5, 0, 0);
      this.move(s.T, -7, -4, 2, 0);
      this.move(s.U, -1, -6, 0, 0);
      this.move(s.V, 2, -6, 0, 0);
      this.move(s.W, 1, -7, 0, 0);
      this.move(s.X, 4, 5, 0, 0);
      this.move(s.Y, -6, 1, 0, 0);
      this.move(s.Z, -4, -5, 0, 0);
      this.startPositions = [
        { id: "F", x: 10.5, y: 5.5, rotated: 0 },
        { id: "I", x: 10.5, y: -0.5, rotated: 0 },
        { id: "L", x: 11.5, y: 1.5, rotated: 0 },
        { id: "N", x: -1.5, y: 0.5, rotated: 0 },
        { id: "P", x: 10.5, y: -2.5, rotated: 0 },
        { id: "T", x: -2.5, y: -1.5, rotated: 2 },
        { id: "U", x: 3.5, y: -3.5, rotated: 0 },
        { id: "V", x: 6.5, y: -3.5, rotated: 0 },
        { id: "W", x: 5.5, y: -4.5, rotated: 0 },
        { id: "X", x: 8.5, y: 7.5, rotated: 0 },
        { id: "Y", x: -1.5, y: 3.5, rotated: 0 },
        { id: "Z", x: 0.5, y: -2.5, rotated: 0 },
      ];
    },
    async setCanvas() {
      let map = document.getElementById("container");
      let mapDimensions = map.getBoundingClientRect();
      this.width = mapDimensions.width;
      this.height = mapDimensions.height;

      //   this.width = window.innerWidth;
      //   this.height = window.innerHeight;

      // Renderer
      this.renderer = new T.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(1);
      this.renderer.setSize(this.width, this.height);
      //   document
      //     .getElementById("container")
      map.appendChild(this.renderer.domElement);

      this.scene = new T.Scene();

      this.shapes = {
        F: await this.load("P_F", this.cF),
        I: await this.load("P_I", this.cI),
        L: await this.load("P_L", this.cL),
        N: await this.load("P_N", this.cN),
        P: await this.load("P_P", this.cP),
        T: await this.load("P_T", this.cT),
        U: await this.load("P_U", this.cU),
        V: await this.load("P_V", this.cV),
        W: await this.load("P_W", this.cW),
        X: await this.load("P_X", this.cX),
        Y: await this.load("P_Y", this.cY),
        Z: await this.load("P_Z", this.cZ),
      };
      this.initPositions(this.shapes);
      this.createBox();

      const light = new T.HemisphereLight(0xffffff, 0xdddddd, 0.1);
      light.position.set(0, 116, 0);
      this.scene.add(light);

      var light1 = new T.DirectionalLight(0xffffff);
      light1.position.set(-10, 6, 10); //.normalize();
      this.scene.add(light1);
      var light2 = new T.PointLight(0xffffff, 2);
      light2.position.set(0, 100, 0).normalize();
      //   this.scene.add(light2);

      this.camera = new T.PerspectiveCamera(
        30,
        this.width / this.height,
        0.1,
        1000
      );
      this.camera.up.set(0, 1, 0);
      this.camera.position.x = -2;
      this.camera.position.y = 16;
      this.camera.position.z = 15;
      this.scene.add(this.camera);

      this.renderer.render(this.scene, this.camera);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      this.controls.enableDamping = true;
    },
    animate() {
      if (this.moving) this.moveShapes(this.moving);
      this.controls.update();
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
  }
};
</script>