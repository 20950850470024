<template lang="pug">
.filterbox
  div(
    style="display: flex; justify-content: space-between; color: black; cursor: pointer",
    @click="dropped = !dropped"
  )
    div(style="font-size: 1.5rem") Filter
    icon(name="filter")
  .filter(v-if="dropped")
    check-box(v-model="filter.photos", @update="update(filter, 'photos')") Bilder
    check-box(v-model="filter.videos", @update="update(filter, 'videos')") Filme
    check-box(v-model="filter.gps", @update="update(filter, 'gps')") Mit GPS
    div(
    v-if="filter.gps",
    style="display: flex; flex-flow: column; align-self: stretch"
    )
        div(style="flex:0 0 auto; display:flex;flex-flow:column;align-items:flex-start;")
            div {{ filter.rect[0] }}
            div {{ filter.rect[1] }}
            div {{ filter.rect[2] }}
            div {{ filter.rect[3] }}
        div(style="flex: 1; align-self: stretch")
            GmapMap(
                :center="center",
                :zoom="zoom",
                :options="{streetViewControl: false, fullscreenControl: false, mapTypeControl: false}",
                @bounds_changed="boundsChanged($event)",
                style="margin: 0.1rem; height: 240px"
            )
        //- GmapMarker(:key="index",v-for="(m, index) in markers",:position="m.position")
    div(
      style="flex: 0 0 auto; align-self: stretch; display: flex; justify-content: flex-end"
    )
      icon.small(name="play", @click="search()")
</template>

<script>
import Icon from "@/components/Icon.vue";
import CheckBox from "@/components/CheckBox.vue";
import { gmapApi } from "vue2-google-maps";

export default {
  components: { Icon, CheckBox },
  props: [],
  data() {
    return {
      dropped: false,
      center: { lat: 54.153796, lng: 10.945332 },
      zoom: 13,
      filter: {
        photos: 1,
        videos: 0,
        gps: 0,
        rect: [ 0, 0, 0, 0 ]
      },
    };
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    search() {
      this.$emit("search", this.filter);
    },
    update(obj, p) {
      console.log("1:", p, this.filter);
      obj[p] = obj[p] ? 0 : 1;
      console.log("2:", p, this.filter);
    },
    boundsChanged(e) {
      e &&
        e.Ga &&
        e.Wa &&
        (this.filter.rect = [e.Ga.lo, e.Wa.lo, e.Ga.hi, e.Wa.hi]);
      console.log("bounds changed", e, e && e.Ga, e && e.Wa);
    },
  },
  mounted() {
    this.zoom = 2;
    this.center = { lat: 0, lng: 0 };
  },
};
</script>

<style lang="less" scoped>
@import (reference) "../shared.less";
.filterbox {
  flex: 0 0 auto;
  display: flex;
  justify-content: stretch;
  align-self: stretch;
  border: 1px solid gray;
  border-radius: 0.3rem;
  flex-flow: column;
  padding: 0.5rem;
  color: black;
}
.filter {
  flex: 0 0 auto;
  margin: 0.25rem;
  padding: 0.1rem;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
</style>