<template lang="pug">
    .face
        .row(v-for="(r,rix) in rows") 
            .cell(v-for="(c,cix) in r",:class="{ selected: isSelected(f,rix,cix)}" :style="{ backgroundColor: c.hex||'#666666'}",@click="selectColor(f,rix,cix)")
</template>

<script>
export default {
    props: ['f','p','cc','selected'],
    data() {
        return {
            faces: {
                // r: { id: 0, c: [] },
                u: { id: 2, c: [0,0,1,1,0,2,2,3,3] },
                f: { id: 4, c: [2,3,3,6,0,7,6,11,7] },
                l: { id: 1, c: [0,1,2,4,0,6,4,9,6] },
                r: { id: 0, c: [3,2,1,7,0,5,7,10,5] },
                d: { id: 3, c: [6,11,7,9,0,10,4,8,5] },
                b: { id: 5, c: [4,8,5,4,0,5,0,0,1] }
            },
            selecting: null
        }
    },
    computed: {
        rows() {
            if (!this.p) {
                const c = { id: 'x', hex: '0x666666' };
                return [[c,c,c],[c,c,c],[c,c,c]];
            }
            const color = this.cc[this.f.toUpperCase()];
            const {id,c} = this.faces[this.f]||{ id:0,c:[0,0,0,0,0,0,0,0,0]};
            const t0 = this.cc[this.p.corners[c[0]][id]];
            const t1 = this.cc[this.p.edges[c[1]][id]];
            const t2 = this.cc[this.p.corners[c[2]][id]];
            const t3 = this.cc[this.p.edges[c[3]][id]];
            const t4 = this.cc[this.p.edges[c[5]][id]];
            const t5 = this.cc[this.p.corners[c[6]][id]];
            const t6 = this.cc[this.p.edges[c[7]][id]];
            const t7 = this.cc[this.p.corners[c[8]][id]];
            const a = [
                [t0, t1, t2],
                [t3,color,t4],
                [t5, t6,t7],
            ];
            return a;
        },
        
    },
    mounted() {
    },
    methods: {
        selectColor(f,r,c) {
            if (r != 1 || c != 1)
                this.$emit('selectColor', {f,r,c});
        },
        isSelected(f,rix,cix) {
            return this.selected && cix==this.selected.c && rix==this.selected.r && f==this.selected.f;
        }
    }
}
</script>

<style lang="less" scoped>
.face {
    display:flex;
    flex-flow:column;
    margin:2px;
}
.row {
    display:flex;
    flex-flow:row;
}
.cell {
    height: 2rem;
    width: 2rem;
    border: 1px solid black;
    border-radius: 0.3rem;
    // margin: 1px;
}
.selected {
    border-radius: 1rem;
}
</style>