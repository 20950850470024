<template lang="pug">
div
  h1 Bike Stats
  div(style="display:flex;padding:1rem;")
    svg
//-   div(style="font-size: 1rem; color: black") {{ items }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as d3 from 'd3';

export default {
  computed: {
    ...mapGetters({
      items: "bike/items",
    }),
  },
  methods: {
    ...mapActions({
      list: "bike/list",
    }),
  },
  async mounted() {
    await this.list({ person: "em", days: 1 });
    const width = 1200;
    const height = 300;
    const svg = d3.select("svg").attr("width", width).attr("height", height);
    const g = svg.append("g");
    //2. Parse the dates
    console.log('D3', d3);
    // const parseTime = d3.timeFormat("%Y-%m-%dT%H:%M:%S");
    const parseTime = d3.utcParse("%Y-%m-%dT%H:%M:%S.%LZ");

    //3. Creating the Chart Axes
    const x = d3
      .scaleTime()
      .domain(
        d3.extent(this.items, function (d) {
            console.log('TIME:', d.created, parseTime(d.created))
          return parseTime(d.created);
        })
      )
      .rangeRound([0, width]);

    const y = d3
      .scaleLinear()
      .domain(
        d3.extent(this.items, function (d) {
          return d.data.speed;
        })
      )
      .rangeRound([height, 0]);

    //4. Creating a Line
    const line = d3
      .line()
      .x(function (d) {
        return x(parseTime(d.created));
      })
      .y(function (d) {
        return y(d.data.speed);
      });

    //5. Appending the Axes to the Chart
    g.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    g.append("g")
      .call(d3.axisLeft(y))
      .append("text")
      .attr("fill", "#000")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", "0.71em")
      .attr("text-anchor", "end")
      .text("Price ($)");    

    //6. Appending a path to the Chart
    g.append("path")
      .datum(this.items)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr("d", line);
  },
};
</script>

<style lang="less" scoped>
</style>