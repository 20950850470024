<template lang="pug">
div(style="flex: 1 0; display: flex; flex-flow: column")
  #container(style="display: flex; flex: 1 0; position: relative") 
</template>

<script>
import * as T from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { RoundedBoxGeometry } from "three/examples/jsm/geometries/RoundedBoxGeometry.js";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
export default {
  data() {
    return {
      scene: {},
      current: null,
      colors: {},
      shapes: {
        I: {
          c: 0x00ffff,
          b: [
            [0, 0],
            [0, 1],
            [0, 2],
            [0, 3],
          ],
        },
        J: {
          c: 0xffff00,
          b: [
            [0, 0],
            [1, 0],
            [1, 1],
            [1, 2],
          ],
        },
        L: {
          c: 0x0000ff,
          b: [
            [0, 0],
            [1, 0],
            [1, 1],
            [1, 2],
          ],
        },
        O: {
          c: 0xff8000,
          b: [
            [0, 0],
            [1, 0],
            [0, 1],
            [1, 1],
          ],
        },
        S: {
          c: 0x00ff00,
          b: [
            [0, 0],
            [1, 0],
            [1, 1],
            [2, 1],
          ],
        },
        Z: {
          c: 0xff00ff,
          b: [
            [1, 0],
            [2, 0],
            [0, 1],
            [1, 1],
          ],
        },
        T: {
          c: 0xff0000,
          b: [
            [1, 0],
            [0, 1],
            [1, 1],
            [2, 1],
          ],
        },
      },
    };
  },
  mounted() {
    const loader = new T.CubeTextureLoader();
    loader.setCrossOrigin("");
    loader.setPath("/img/cube/SaintLazarusChurch2/");
    this.textureCube = loader.load([
      "posx.jpg",
      "negx.jpg",
      "posy.jpg",
      "negy.jpg",
      "posz.jpg",
      "negz.jpg",
    ]);
    this.textureLoader = new T.TextureLoader();
    this.cF = new T.MeshStandardMaterial({
      envMap: this.textureCube,
      color: "#000000",
      side: T.DoubleSide,
      metalness: 0.3,
      roughness: 0.1,
    });
    Object.keys(this.shapes).forEach((key) => {
      const s = this.shapes[key];
      const c = new T.MeshStandardMaterial({
        envMap: this.textureCube,
        color: s.c,
        side: T.DoubleSide,
        metalness: 0.7,
        roughness: 0.001,
      });
      this.colors[key] = c;
    });

    window.addEventListener("keydown", (event) => {
      var key = event.which ? event.which : event.keyCode;
      if (this.current)
        switch (key) {
          case 38: // up (arrow)
            this.current.rotation.z += Math.PI/2;
            // Tetris.Block.move(0, 1, 0);
            break;
          case 40: // down (arrow)
            // Tetris.Block.move(0, -1, 0);
            break;
          case 37: // left(arrow)
            this.current.position.x -= 1;
            break;
          case 39: // right (arrow)
            this.current.position.x += 1;
            // Tetris.Block.move(1, 0, 0);
            break;
          case 32: // space
            // Tetris.Block.move(0, 0, -1);
            break;
        }
    });
    this.createCanvas();
    this.animate();
  },
  methods: {
    createPiece(t) {
      let p = [];
      let m = null;
      const s = this.shapes[t];
      for (let i = 0; i < 4; i++) {
        p.push(new T.BoxGeometry(1, 1, 1).translate(s.b[i][0], s.b[i][1], 0));
      }
      const g = BufferGeometryUtils.mergeGeometries(p);
      m = new T.Mesh(g, this.colors[t]);
      m.position.x = 5;
      m.position.y = 20.5;
      return m;
    },
    createFrame() {
      const p = [];
      p.push(new T.BoxGeometry(1, 20, 1).translate(0, 10, 0));
      p.push(new T.BoxGeometry(1, 20, 1).translate(10, 10, 0));
      p.push(new T.BoxGeometry(11, 1, 1).translate(5, 0, 0));
      p.push(new T.BoxGeometry(11, 20, 0.1).translate(5, 10, -0.49));
      const g = BufferGeometryUtils.mergeGeometries(p);
      let m = new T.Mesh(g, this.cF);
      this.scene.add(m);
    },
    createRandom() {
      const a = ["I", "O", "J", "L", "S", "T", "Z"];
      const r = Math.trunc(Math.random() * a.length);
      return this.createPiece(a[r]);
    },
    createCanvas() {
      let map = document.getElementById("container");
      let mapDimensions = map.getBoundingClientRect();
      this.width = mapDimensions.width;
      this.height = mapDimensions.height;

      // Renderer
      this.renderer = new T.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(1);
      this.renderer.setSize(this.width, this.height);
      //   document
      //     .getElementById("container")
      map.appendChild(this.renderer.domElement);

      this.scene = new T.Scene();
      //   this.scene.background = new T.Color("#222222");
      this.scene.background = this.textureCube;

      const light = new T.HemisphereLight(0xffffff, 0xdddddd, 0.3);
      light.position.set(0, 16, 0);
      this.scene.add(light);

      var light1 = new T.DirectionalLight(0xffffff);
      light1.position.set(-1, 15, -10);
      this.scene.add(light1);

      this.camera = new T.PerspectiveCamera(
        30,
        this.width / this.height,
        0.01,
        1000
      );
      this.camera.up.set(0, 1, 0);
      this.camera.position.x = 25;
      this.camera.position.y = 25;
      this.camera.position.z = 45;
      this.scene.add(this.camera);

      this.createFrame();

      this.renderer.render(this.scene, this.camera);
      this.renderer.shadowMap.enabled = false;
      // Controls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.target = new T.Vector3(5, 10, 1);
      this.controls.enableDamping = true;
    },
    animate() {
      if (!this.current) {
        this.current = this.createRandom();
        this.scene.add(this.current);
      } else
      if (this.current) {
        this.current.position.y -= 0.1;
        if (this.current.position.y < 1) {
          this.scene.remove(this.current);
          this.current = null;
        }
      }
      this.controls.update();
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
  },
};
</script>

<style lang="less" scoped>
</style>