<template lang="pug">
.content
  .parallax
    .bg
    p.layer10 Abgeschlossenes Studium zum EDV-Kaufmann
    p.layer10 Über 40 Jahre Berufserfahrung
    p.layer10 Entwicklung in Javascript/Typescript
    p.layer10 Webdesign mit HTML/PUG und CSS/LESS
    p.layer10 Datenbankdesign für mySQL
    p.layer10 Webentwicklung mit VueJS
    p.layer10 3D-Programmierung mit Three.js
    p.layer10 3D-Design mit Sketchup
    p.layer10 Grafik-Design mit Xara
    p.layer10 2 Jahre als Supporter an einem Großrechner in Danzig in einem Rechenzentrum für Bauindustrie
    p.layer10 14 Jahre als Entwickler in einem Rechenzentrum für Schuheinzelhandel in Dortmund
    p.layer10 25 Jahre Mitgesellschafter und Entwickler in einem Unternehmen, welches Software für Schuhhandel und Schuhindustrie entwickelt hat
    p.layer10 Seit November 2022 selbstständiger Entwickler in Grömitz an der Ostsee
    p.layer10 Seit Juli 2024 selbständiger Entwickler in Emmerich am Rhein
</template>

<script>
export default {
  metaInfo: {
    title: "Einige Informationen über mich",
  },
};
</script>
<style lang="less" scoped>
.content {
  margin: 0;
  padding: 0;
  position: relative;
  color: #000;
  font-size: 4rem;
  width: 100%;
  height: 160%;
  overflow: hidden;
}
.parallax {
  width: 100%;
  height: 180%;

  overflow-x: hidden;
  overflow-y: auto;

  perspective: 20px;
  perspective-origin: top;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.bg {
  position: absolute;
  transform: translateZ(0px);
  // left: 0%;
  // top: 0;
  // right: 0;
  // bottom: 0;
  background-image: url("/img/emmerich5.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center top;
  // animation: 2s back forwards;
  width: 100%;
  height: 220%;
}

.layer10 {
  transform: translateZ(15px);
}

p {
  font-size: 0.7vw;
  padding: 0.9vw 3vw;
  background-color: #ccc;
  margin: 5vw 40vw;
  opacity: 1;
  color: black;
  box-shadow: 2px 2px 4px #333;
  animation: 2s back forwards;
}
@keyframes text {
  from {
    transform: translateY(600px);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes back {
  from {
    filter: blur(10px);
    opacity: 0.1;
    transform: translateZ(0);
  }
  to {
    filter: blur(0);
    opacity: 1;
    transform: translateZ(15px);
  }
}

// ul {
//   text-align: left;
//   font-size: 1.5rem;
// }
// p {
//   margin-block: 0.3rem;
//   font-size: 1.5rem;
// }

@media screen and (max-device-width: 500px) and (orientation: portrait) {
p {
  font-size: 1.2vh;
  padding: 0.5vh 1vw;
  margin: 2vh 20vw;
}
@keyframes back {
  from {
    filter: blur(10px);
    opacity: 0.1;
    transform: translateZ(0);
  }
  to {
    filter: blur(0);
    opacity: 1;
    transform: translateZ(6px);
  }
}
.parallax {
  height: 100%;
}
.bg {
  height: 120%;
}
  // li {
  //   text-align: left;
  //   font-size: 1rem;
  // }
  // p {
  //   margin-block: 0.1rem;
  //   font-size: 0.9rem;
  // }
}
</style>