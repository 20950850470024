<template lang="pug">
    div(style="margin:0 4rem;")
        h1 Rezept
        p Aute ullamco nostrud dolor ullamco elit commodo laboris cupidatat tempor dolore. Fugiat do labore ipsum non cillum qui. Cillum nisi laborum magna anim anim incididunt nostrud eu nisi dolore. Eiusmod elit quis eu enim nostrud nostrud qui velit. Officia non sint dolor aute minim minim laborum veniam commodo duis minim eu labore. Dolore commodo non culpa aliquip eu id.
        p Aliqua pariatur officia culpa amet elit occaecat ut proident. Sint esse fugiat cupidatat est eu aliquip tempor ad minim ullamco. Proident enim sit officia consectetur irure. Occaecat laborum ipsum velit do consectetur minim dolor veniam nisi et sint aliquip. Ullamco deserunt nisi sit enim magna ea est mollit. Sint tempor mollit esse pariatur ullamco elit excepteur mollit aliquip nulla velit eu cillum.
        p Ullamco fugiat velit aliqua excepteur veniam dolore exercitation est nisi labore. Enim ipsum et ad tempor do amet. In labore ut culpa cupidatat tempor et aliquip laborum voluptate excepteur Lorem sint commodo. Eu anim cupidatat deserunt do mollit cupidatat eu aute. Non sint magna laborum cillum amet adipisicing commodo et ut.
        p Aute ullamco nostrud dolor ullamco elit commodo laboris cupidatat tempor dolore. Fugiat do labore ipsum non cillum qui. Cillum nisi laborum magna anim anim incididunt nostrud eu nisi dolore. Eiusmod elit quis eu enim nostrud nostrud qui velit. Officia non sint dolor aute minim minim laborum veniam commodo duis minim eu labore. Dolore commodo non culpa aliquip eu id.
        p Aliqua pariatur officia culpa amet elit occaecat ut proident. Sint esse fugiat cupidatat est eu aliquip tempor ad minim ullamco. Proident enim sit officia consectetur irure. Occaecat laborum ipsum velit do consectetur minim dolor veniam nisi et sint aliquip. Ullamco deserunt nisi sit enim magna ea est mollit. Sint tempor mollit esse pariatur ullamco elit excepteur mollit aliquip nulla velit eu cillum.
        p Ullamco fugiat velit aliqua excepteur veniam dolore exercitation est nisi labore. Enim ipsum et ad tempor do amet. In labore ut culpa cupidatat tempor et aliquip laborum voluptate excepteur Lorem sint commodo. Eu anim cupidatat deserunt do mollit cupidatat eu aute. Non sint magna laborum cillum amet adipisicing commodo et ut.
        p Aute ullamco nostrud dolor ullamco elit commodo laboris cupidatat tempor dolore. Fugiat do labore ipsum non cillum qui. Cillum nisi laborum magna anim anim incididunt nostrud eu nisi dolore. Eiusmod elit quis eu enim nostrud nostrud qui velit. Officia non sint dolor aute minim minim laborum veniam commodo duis minim eu labore. Dolore commodo non culpa aliquip eu id.
        p Aliqua pariatur officia culpa amet elit occaecat ut proident. Sint esse fugiat cupidatat est eu aliquip tempor ad minim ullamco. Proident enim sit officia consectetur irure. Occaecat laborum ipsum velit do consectetur minim dolor veniam nisi et sint aliquip. Ullamco deserunt nisi sit enim magna ea est mollit. Sint tempor mollit esse pariatur ullamco elit excepteur mollit aliquip nulla velit eu cillum.
        p Ullamco fugiat velit aliqua excepteur veniam dolore exercitation est nisi labore. Enim ipsum et ad tempor do amet. In labore ut culpa cupidatat tempor et aliquip laborum voluptate excepteur Lorem sint commodo. Eu anim cupidatat deserunt do mollit cupidatat eu aute. Non sint magna laborum cillum amet adipisicing commodo et ut.
        p Aute ullamco nostrud dolor ullamco elit commodo laboris cupidatat tempor dolore. Fugiat do labore ipsum non cillum qui. Cillum nisi laborum magna anim anim incididunt nostrud eu nisi dolore. Eiusmod elit quis eu enim nostrud nostrud qui velit. Officia non sint dolor aute minim minim laborum veniam commodo duis minim eu labore. Dolore commodo non culpa aliquip eu id.
        p Aliqua pariatur officia culpa amet elit occaecat ut proident. Sint esse fugiat cupidatat est eu aliquip tempor ad minim ullamco. Proident enim sit officia consectetur irure. Occaecat laborum ipsum velit do consectetur minim dolor veniam nisi et sint aliquip. Ullamco deserunt nisi sit enim magna ea est mollit. Sint tempor mollit esse pariatur ullamco elit excepteur mollit aliquip nulla velit eu cillum.
        p Ullamco fugiat velit aliqua excepteur veniam dolore exercitation est nisi labore. Enim ipsum et ad tempor do amet. In labore ut culpa cupidatat tempor et aliquip laborum voluptate excepteur Lorem sint commodo. Eu anim cupidatat deserunt do mollit cupidatat eu aute. Non sint magna laborum cillum amet adipisicing commodo et ut.
        p Aute ullamco nostrud dolor ullamco elit commodo laboris cupidatat tempor dolore. Fugiat do labore ipsum non cillum qui. Cillum nisi laborum magna anim anim incididunt nostrud eu nisi dolore. Eiusmod elit quis eu enim nostrud nostrud qui velit. Officia non sint dolor aute minim minim laborum veniam commodo duis minim eu labore. Dolore commodo non culpa aliquip eu id.
        p Aliqua pariatur officia culpa amet elit occaecat ut proident. Sint esse fugiat cupidatat est eu aliquip tempor ad minim ullamco. Proident enim sit officia consectetur irure. Occaecat laborum ipsum velit do consectetur minim dolor veniam nisi et sint aliquip. Ullamco deserunt nisi sit enim magna ea est mollit. Sint tempor mollit esse pariatur ullamco elit excepteur mollit aliquip nulla velit eu cillum.
        p Ullamco fugiat velit aliqua excepteur veniam dolore exercitation est nisi labore. Enim ipsum et ad tempor do amet. In labore ut culpa cupidatat tempor et aliquip laborum voluptate excepteur Lorem sint commodo. Eu anim cupidatat deserunt do mollit cupidatat eu aute. Non sint magna laborum cillum amet adipisicing commodo et ut.
        p Aute ullamco nostrud dolor ullamco elit commodo laboris cupidatat tempor dolore. Fugiat do labore ipsum non cillum qui. Cillum nisi laborum magna anim anim incididunt nostrud eu nisi dolore. Eiusmod elit quis eu enim nostrud nostrud qui velit. Officia non sint dolor aute minim minim laborum veniam commodo duis minim eu labore. Dolore commodo non culpa aliquip eu id.
        p Aliqua pariatur officia culpa amet elit occaecat ut proident. Sint esse fugiat cupidatat est eu aliquip tempor ad minim ullamco. Proident enim sit officia consectetur irure. Occaecat laborum ipsum velit do consectetur minim dolor veniam nisi et sint aliquip. Ullamco deserunt nisi sit enim magna ea est mollit. Sint tempor mollit esse pariatur ullamco elit excepteur mollit aliquip nulla velit eu cillum.
        p Ullamco fugiat velit aliqua excepteur veniam dolore exercitation est nisi labore. Enim ipsum et ad tempor do amet. In labore ut culpa cupidatat tempor et aliquip laborum voluptate excepteur Lorem sint commodo. Eu anim cupidatat deserunt do mollit cupidatat eu aute. Non sint magna laborum cillum amet adipisicing commodo et ut.
        p Aute ullamco nostrud dolor ullamco elit commodo laboris cupidatat tempor dolore. Fugiat do labore ipsum non cillum qui. Cillum nisi laborum magna anim anim incididunt nostrud eu nisi dolore. Eiusmod elit quis eu enim nostrud nostrud qui velit. Officia non sint dolor aute minim minim laborum veniam commodo duis minim eu labore. Dolore commodo non culpa aliquip eu id.
        p Aliqua pariatur officia culpa amet elit occaecat ut proident. Sint esse fugiat cupidatat est eu aliquip tempor ad minim ullamco. Proident enim sit officia consectetur irure. Occaecat laborum ipsum velit do consectetur minim dolor veniam nisi et sint aliquip. Ullamco deserunt nisi sit enim magna ea est mollit. Sint tempor mollit esse pariatur ullamco elit excepteur mollit aliquip nulla velit eu cillum.
        p Ullamco fugiat velit aliqua excepteur veniam dolore exercitation est nisi labore. Enim ipsum et ad tempor do amet. In labore ut culpa cupidatat tempor et aliquip laborum voluptate excepteur Lorem sint commodo. Eu anim cupidatat deserunt do mollit cupidatat eu aute. Non sint magna laborum cillum amet adipisicing commodo et ut.
        p Aute ullamco nostrud dolor ullamco elit commodo laboris cupidatat tempor dolore. Fugiat do labore ipsum non cillum qui. Cillum nisi laborum magna anim anim incididunt nostrud eu nisi dolore. Eiusmod elit quis eu enim nostrud nostrud qui velit. Officia non sint dolor aute minim minim laborum veniam commodo duis minim eu labore. Dolore commodo non culpa aliquip eu id.
        p Aliqua pariatur officia culpa amet elit occaecat ut proident. Sint esse fugiat cupidatat est eu aliquip tempor ad minim ullamco. Proident enim sit officia consectetur irure. Occaecat laborum ipsum velit do consectetur minim dolor veniam nisi et sint aliquip. Ullamco deserunt nisi sit enim magna ea est mollit. Sint tempor mollit esse pariatur ullamco elit excepteur mollit aliquip nulla velit eu cillum.
        p Ullamco fugiat velit aliqua excepteur veniam dolore exercitation est nisi labore. Enim ipsum et ad tempor do amet. In labore ut culpa cupidatat tempor et aliquip laborum voluptate excepteur Lorem sint commodo. Eu anim cupidatat deserunt do mollit cupidatat eu aute. Non sint magna laborum cillum amet adipisicing commodo et ut.
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      recipe: {},
      mode: "P",
      step: 0,
    };
  },
  computed: {
    ...mapGetters({ recipes: "recipes/items" }),
    steps() {
      return this.recipe.steps;
    },
  },
}
</script>

<style lang="less" scoped>

</style>