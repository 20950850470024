<template lang="pug">
    rubiks-cube
</template>

<script>
import RubiksCube from '@/components/RubiksCube.vue';

export default {
    components: { RubiksCube }
}
</script>