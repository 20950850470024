<template lang="pug">
div(style="flex: 1 0; display: flex; flex-flow: column")
  div(style="display: flex; flex: 0 0 auto; flex-flow: row wrap")
    button(@click="startPlaying()") Play
  #container(style="display: flex; flex: 1 0")
</template>

<script>
import {
  Scene,
  PlaneGeometry,
  SphereGeometry,
  BoxGeometry,
  MeshBasicMaterial,
  MeshPhongMaterial,
  MeshStandardMaterial,
  MeshLambertMaterial,
  Mesh,
  PointLight,
  PerspectiveCamera,
  WebGLRenderer,
  TextureLoader,
  HemisphereLight,
  AmbientLight,
  DirectionalLight,
  CubeTextureLoader,
  Color,
  Vector3,
  RepeatWrapping,
  SRGBColorSpace,
  Audio,
  AudioListener,
  AudioLoader,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { RoundedBoxGeometry } from "three/examples/jsm/geometries/RoundedBoxGeometry.js";
import { ColladaLoader } from "three/examples/jsm/loaders/ColladaLoader.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import { KMZLoader } from "three/examples/jsm/loaders/KMZLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
export default {
  data() {
    return {
      width: 0,
      height: 0,
      renderer: null,
      scene: null,
      light: null,
      camera: null,
      controls: null,
      black: null,
      white: null,
      red: null,
      keys: [],
      notes: {},
      noteList: [],
      noteIndex: 0,
      playing: false,
      step: 0,
      audioLoader: null,
      buffers: {}
      //   context: null,
      //   oscillator: null,
    };
  },
  async mounted() {
    this.audioLoader = new AudioLoader();

    this.notes = {};
    let i = 0;
    let m = 1;
    for (const o of [1, 2, 3, 4]) {
      for (const n of [
        { n: "C#", f: 34.65, s: "Db" },
        { n: "D#", f: 38.89, s: "Eb" },
        { n: "F#", f: 46.25, s: "Gb" },
        { n: "G#", f: 51.91, s: "Ab" },
        { n: "A#", f: 58.27, s: "Bb" },
        { n: "C", f: 32.7, s: "C" },
        { n: "D", f: 36.71, s: "D" },
        { n: "E", f: 41.2, s: "E" },
        { n: "F", f: 43.65, s: "F" },
        { n: "G", f: 49.0, s: "G" },
        { n: "A", f: 55.0, s: "A" },
        { n: "H", f: 61.74, s: "B" },
      ]) {
        this.notes[n.n + o] = {
          id: i,
          freq: n.f * m,
          file: "/sounds/" + n.s + (o + 1) + ".mp3",
        };
        i++;
      }
      m *= 2;
    }

    for (const n of Object.keys(this.notes)) {
      const t = this.notes[n];
      await this.loadSound(t);
    }

    this.black = new MeshPhongMaterial({
      color: "#000000",
      specular: "#aaaaaa",
      shininess: 10,
      reflectivity: 1,
    });
    this.white = new MeshPhongMaterial({
      color: "#ffffff",
      specular: "#ffffff",
      shininess: 10,
      reflectivity: 1,
    });
    this.red = new MeshPhongMaterial({
      color: "#ff0000",
      specular: "#ffffff",
      shininess: 10,
      reflectivity: 1,
    });
    this.setCanvas();
    this.animate();
  },
  methods: {
    async loadSound(note) {
      return new Promise((resolve, reject) => {
        console.log(note);
        this.audioLoader.load(note.file, buffer => {
          this.buffers[note.id] = buffer;
          return resolve({});
        });
      });
    },
    startPlaying() {
      this.noteIndex = 0;
      this.playing = true;
      this.step = 0;
      this.noteList = [
        ["X", 20],
        ["E3", 5],
        ["D#3", 5],
        ["E3", 5],
        ["D#3", 5],
        ["E3", 5],
        ["H2", 5],
        ["D3", 5],
        ["C3", 5],
        ["A2", 10],
        ["X", 5],
        ["C2", 5],
        ["E2", 5],
        ["A2", 5],
        ["H2", 10],
        ["X", 5],
        ["E2", 5],
        ["G#2", 5],
        ["H2", 5],
        ["C3", 10],
        ["X", 5],
        ["E2", 5],
        ["E3", 5],
        ["D#3", 5],
        ["E3", 5],
        ["D#3", 5],
        ["E3", 5],
        ["H2", 5],
        ["D3", 5],
        ["C3", 5],
        ["A2", 10],
        ["X", 5],
        ["C2", 5],
        ["E2", 5],
        ["A2", 5],
        ["H2", 10],
        ["X", 5],
        ["E2", 5],
        ["C3", 5],
        ["H2", 5],
        ["A2", 20],
      ];
    },
    play() {
      if (this.noteIndex < this.noteList.length) {
        const n = this.noteList[this.noteIndex];
        const t1 = 4;
        const t2 = 4;
        this.playNotes(n[0], this.step, n[1], t1, t2);
        this.step++;
        if (this.step > n[1] + t1 + t2) {
          this.step = 0;
          this.noteIndex++;
        }
      } else this.playing = false;
    },
    playNotes(n, step, d, t1, t2) {
      const note = this.notes[n];
      const key = note && this.keys[note.id];
      const f1 = 0.05 / t1;
      const f2 = 0.05 / t2;
      if (key && step <= t1) {
        key && key.rotation.set(step * f1, 0, 0);
        if (step == 1) {
          //   const audioLoader = new AudioLoader();
          const s = this.sound;
          s.setBuffer(this.buffers[this.notes[n].id]);
          s.setVolume(0.5);
          s.play();
          //   audioLoader.load(this.notes[n].file, function (buffer) {
          //     s.setBuffer(buffer);
          //     s.setLoop(true);
          //     s.setVolume(0.5);
          //     s.play();
          //   });
        }

        return;
      }
      if (step <= d + t1) {
        return;
      }
      if (key && step == d + t1 + t2) {
        this.sound.stop();
      }
      if (step <= d + t1 + t2) {
        key && key.rotation.set((d + t1 + t2 - step) * f2, 0, 0);

        return;
      }
    },
    addSky() {
      const path = "/img/pisa/";
      const format = ".png";
      const urls = [
        path + "px" + format,
        path + "nx" + format,
        path + "py" + format,
        path + "ny" + format,
        path + "pz" + format,
        path + "nz" + format,
      ];

      this.textureCube = new CubeTextureLoader().load(urls);
      this.scene.background = this.textureCube;

      // const geometry = new SphereGeometry( 0.1, 32, 16 );
      // const material = new MeshBasicMaterial( { color: 0xffffff, envMap: textureCube } );
      // this.scene.add(geometry, material);
    },
    addFloor() {
      const floorMat = new MeshStandardMaterial({
        roughness: 0.8,
        color: 0xffffff,
        metalness: 0.2,
        bumpScale: 0.0005,
      });
      const textureLoader = new TextureLoader();
      textureLoader.load("/img/floor/hardwood2_diffuse.jpg", function (map) {
        map.wrapS = RepeatWrapping;
        map.wrapT = RepeatWrapping;
        map.anisotropy = 4;
        map.repeat.set(10, 24);
        map.colorSpace = SRGBColorSpace;
        floorMat.map = map;
        floorMat.needsUpdate = true;
      });
      textureLoader.load("/img/floor/hardwood2_bump.jpg", function (map) {
        map.wrapS = RepeatWrapping;
        map.wrapT = RepeatWrapping;
        map.anisotropy = 4;
        map.repeat.set(10, 24);
        floorMat.bumpMap = map;
        floorMat.needsUpdate = true;
      });
      textureLoader.load("/img/floor/hardwood2_roughness.jpg", function (map) {
        map.wrapS = RepeatWrapping;
        map.wrapT = RepeatWrapping;
        map.anisotropy = 4;
        map.repeat.set(10, 24);
        floorMat.roughnessMap = map;
        floorMat.needsUpdate = true;
      });
      const floorGeometry = new PlaneGeometry(20, 20).translate(0, 0, -0.1);
      const floorMesh = new Mesh(floorGeometry, floorMat);
      floorMesh.receiveShadow = true;
      floorMesh.rotation.x = -Math.PI / 2.0;
      this.scene.add(floorMesh);
    },
    addBox() {
      const m = new MeshLambertMaterial({
        color: "#222222",
        reflectivity: 1,
        // envMap: this.textureCube,
      });
      const g1 = new BoxGeometry(7, 0.5, 0.1)
        .translate(0, 0.25, 0)
        .rotateX(-0.1);
      const m1 = new Mesh(g1, m);
      //   m1.castShadow = true;
      this.scene.add(m1);
      const g2 = new BoxGeometry(0.1, 0.1, 1.54).translate(-3.45, 0.05, 0.76);
      const m2 = new Mesh(g2, m);
      //   m2.castShadow = true;
      this.scene.add(m2);
      const g3 = new BoxGeometry(0.1, 0.1, 1.54).translate(3.45, 0.05, 0.76);
      const m3 = new Mesh(g3, m);
      //   m3.castShadow = true;
      this.scene.add(m3);
      const g4 = new BoxGeometry(7, 0.1, 0.1).translate(0, 0.05, 1.5);
      const m4 = new Mesh(g4, m);
      //   m4.castShadow = true;
      this.scene.add(m4);
      const g5 = new BoxGeometry(7, 0.1, 1.58).translate(0, -0.05, 0.74);
      const m5 = new Mesh(g5, m);
      //   m5.castShadow = true;
      this.scene.add(m5);
    },
    setCanvas() {
      let map = document.getElementById("container");
      let mapDimensions = map.getBoundingClientRect();
      this.width = mapDimensions.width;
      this.height = mapDimensions.height;

      //   this.width = window.innerWidth;
      //   this.height = window.innerHeight;

      // Renderer
      this.renderer = new WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(1);
      this.renderer.setSize(this.width, this.height);
      //   document
      //     .getElementById("container")
      map.appendChild(this.renderer.domElement);

      this.scene = new Scene();

        this.addSky();
        // this.addFloor();
      this.keys = [];
      const loader = new FBXLoader();
      const self = this;
      let x = -3.39;
      loader.load(
        "/img/piano.fbx",
        function (object) {
          object.children.forEach((g, ix) => {
            const k = g.children[0];
            k.material = ix <= 4 ? self.black : self.white;
            // k.geometry.scale(1, 2, 1);
            // ix == 1 && k.geometry.rotateX(0.15);
            self.keys.push(k);
            k.geometry.translate(x, 0, 0);
            // k.castShadow = true;
            self.scene.add(k);
          });
          x = 0;
          for (const d of [1, 2, 3]) {
            x += 1.695;
            console.log(d, x);
            for (let i = 0; i < 12; i++) {
              const k = self.keys[i];
              const k1 = k.clone();
              k1.material = i <= 4 ? self.black : self.white;
              k1.geometry = k.geometry.clone();
              k1.geometry.translate(x, 0, 0);
              //   k1.castShadow = true;
              self.keys.push(k1);
              self.scene.add(k1);
            }
          }
          //   self.startPlaying();
        },
        function (xhr) {
          console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        },
        function (error) {
          console.log("An error happened", error);
        }
      );
      this.addBox();
      // Lights
      // this.light = new PointLight(0xffffff, 100);
      this.light = new HemisphereLight(0xffffff, 0xdddddd, 1);
      this.light.position.set(-1, 6, 6);
      this.scene.add(this.light);
      //   const light = new AmbientLight(0x222222);
      //   this.scene.add(light);
      var light1 = new DirectionalLight(0xffffff);
      light1.position.set(4, 10, 5).normalize();
      this.scene.add(light1);

      //   const bulbLight = new PointLight(0xffffff, 10, 100, 1);
      //   bulbLight.position.set(0, 4, 1.7);
      //   this.scene.add(bulbLight);

      // Camera
      this.camera = new PerspectiveCamera(
        30,
        this.width / this.height,
        0.1,
        1000
      );
      this.camera.up.set(0, 1, 0);
      this.camera.position.x = -4;
      this.camera.position.y = 2;
      this.camera.position.z = 6;
      this.scene.add(this.camera);

      this.listener = new AudioListener();
      this.camera.add(this.listener);
      this.sound = new Audio(this.listener);

      this.renderer.render(this.scene, this.camera);
      this.renderer.shadowMap.enabled = false;
      // Controls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      this.controls.enableDamping = true;
    },
    animate() {
      if (this.playing) this.play();
      this.controls.update();
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
  },
};
</script>

<style lang="less" scoped>
button {
  font-size: 1.6rem;
  padding: 0.5rem;
  margin: 0.5rem;
}
</style>