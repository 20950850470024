<template lang="pug">
div(style="flex: 1 0; display: flex; flex-flow: column")
  div(style="display: flex; flex: 0 0 auto; flex-flow: row nowrap")
    div Speed {{ speed }}
    input.slider(
      style="width: 32rem",
      type="range",
      min="1",
      max="180",
      step="1",
      v-model="speed"
    )
  div(style="display: flex; flex: 0 0 auto; flex-flow: row wrap")
    button3d(v-for="m in allMoves", @click="start(m.id)") {{ m.name }}
  div(style="flex: 0 0 auto; display: flex; flex-flow: row wrap")
    button3d(@click="reset()") RESET
    button3d(@click="shuffle()") Shuffle
    button3d(@click="solve()") Solve
    //- button3d(v-for="(p, n) in patterns", @click="play(p)") {{ n }}
  div(
    style="flex: 0 0 auto; display: flex; flex-flow: row wrap; padding: 0.5rem"
  )
    input(v-model="toPlay", style="width: 48rem")
    button3d(@click="play(toPlay)")
      icon(name="next")
    button3d(@click="playBack()")
      icon(name="prev")
    div(style="flex: 1 0")
    button3d(@click="showPatterns") Patterns
    button3d(@click="screenshot()")
      icon(name="screenshot")
  #container(style="display: flex; flex: 1 0; position: relative")
    div(
      style="position: absolute; left: 0.5rem; top: 0.5rem; display: flex; flex-flow: column; align-items: center"
    )
      face(
        f="u",
        :p="pp",
        :cc="colors",
        @selectColor="selectColor",
        :selected="selectingColor"
      )
      div(style="display: flex")
        face(
          f="l",
          :p="pp",
          :cc="colors",
          @selectColor="selectColor",
          :selected="selectingColor"
        )
        face(
          f="f",
          :p="pp",
          :cc="colors",
          @selectColor="selectColor",
          :selected="selectingColor"
        )
        face(
          f="r",
          :p="pp",
          :cc="colors",
          @selectColor="selectColor",
          :selected="selectingColor"
        )
      face(
        f="d",
        :p="pp",
        :cc="colors",
        @selectColor="selectColor",
        :selected="selectingColor"
      )
      face(
        f="b",
        :p="pp",
        :cc="colors",
        @selectColor="selectColor",
        :selected="selectingColor"
      )
      div(
        v-if="selectingColor",
        style="position: absolute; z-index: 999; left: 21rem; top: 0; border: 1px solid gray; background-color: white; display: flex; flex-flow: column"
      )
        div(style="display: flex; background-color: #ddd")
          div(style="flex: 1 0")
          button3d(@click="selectingColor = null")
            icon.tiny(name="close")
        div(style="padding: 1rem")
          div(
            v-for="(c, ix) in colors",
            style="border: 1px solid gray; margin: 0.2rem; width: 2rem; height: 2rem",
            :style="{ backgroundColor: c.hex }",
            @click="setColor(c, ix)"
          )
  .blocker(v-if="viewingPatterns")
    patterns(@play="playPattern", @close="viewingPatterns = 0")
  .blocker(
    v-if="solved && solution.length > 0",
    style="align-items: flex-start"
  )
    div(
      style="flex: 0 0 auto; display: flex; flex-flow: column; background-color: white; color: black; border: 1px solid gray; border-radius: 0.25rem; box-shadow: #444 2px 2px 4px; margin: 1rem 2rem"
    )
      div(style="display: flex; background-color: #ddd; align-items: center")
        div(
          style="flex: 1 0; display: flex; justify-content: center; font-size: 1.6rem; padding: 0 2rem"
        ) Solution
        button3d(@click="solved = 0")
          icon.tiny(name="close")
      div(
        style="flex: 1 0; display: flex; flex-flow: column; overflow: auto; font-size: 1.4rem; padding: 1rem; align-items: flex-start"
      )
        div(v-for="s in solution", style="margin: 0.5rem 0") {{ s }}
</template>

<script>
import {
  Scene,
  SphereGeometry,
  BoxGeometry,
  CubeTextureLoader,
  MeshBasicMaterial,
  MeshPhongMaterial,
  MeshLambertMaterial,
  MeshStandardMaterial,
  Mesh,
  PointLight,
  PerspectiveCamera,
  WebGLRenderer,
  TextureLoader,
  HemisphereLight,
  AmbientLight,
  DirectionalLight,
  Color,
  Vector3,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";
import { RoundedBoxGeometry } from "three/examples/jsm/geometries/RoundedBoxGeometry.js";
import Button3d from "@/components/Button3d.vue";
import Icon from "@/components/Icon.vue";
import Solver from "./RubikSolver.vue";
import Patterns from "./RubikPatterns.vue";
import Face from "./RubikFace.vue";

export default {
  components: { Button3d, Solver, Icon, Face, Patterns },
  data() {
    return {
      camera: null,
      camera2: null,
      camera3: null,
      renderer: null,
      scene: null,
      background: null,
      mesh: null,
      controls: null,
      light: null,
      width: 0,
      height: 0,
      webGl: null,
      cubes: [],
      pp: null,
      toPlay: "",
      inMove: false,
      pattern: null,
      speed: 6,
      angle: 0,
      maxAngle: 0,
      black: null,
      red: null,
      orange: null,
      green: null,
      blue: null,
      yellow: null,
      white: null,
      viewingPatterns: 0,
      selectingColor: null,
      solution: [],
      solved: 0,
      colors: {
        U: { id: "y", hex: "#ffff00" },
        D: { id: "w", hex: "#ffffff" },
        R: { id: "r", hex: "#ff0000" },
        L: { id: "o", hex: "#ff8000" },
        F: { id: "b", hex: "#0000ff" },
        B: { id: "g", hex: "#00ff00" },
      },
      faces: {
        L: [0, 3, 6, 9, 12, 15, 18, 21, 24],
        R: [2, 5, 8, 11, 14, 17, 20, 23, 26],
        D: [0, 1, 2, 9, 10, 11, 18, 19, 20],
        U: [6, 7, 8, 15, 16, 17, 24, 25, 26],
        F: [18, 19, 20, 21, 22, 23, 24, 25, 26],
        B: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      },
      actions: {
        L: {
          rotate: (speed) => this.rotateX(1, speed, "L"),
          finish: () => this.finishX("L"),
        },
        L2: {
          rotate: (speed) => this.rotateX(1, speed, "L"),
          finish: () => {
            this.finishX("L");
            this.finishX("L");
          },
        },
        LP: {
          rotate: (speed) => this.rotateX(-1, speed, "L"),
          finish: () => this.finishX("LP"),
        },
        R: {
          rotate: (speed) => this.rotateX(-1, speed, "R"),
          finish: () => this.finishX("R"),
        },
        R2: {
          rotate: (speed) => this.rotateX(-1, speed, "R"),
          finish: () => {
            this.finishX("R");
            this.finishX("R");
          },
        },
        RP: {
          rotate: (speed) => this.rotateX(1, speed, "R"),
          finish: () => this.finishX("RP"),
        },
        D: {
          rotate: (speed) => this.rotateY(1, speed, "D"),
          finish: () => this.finishY("D"),
        },
        D2: {
          rotate: (speed) => this.rotateY(1, speed, "D"),
          finish: () => {
            this.finishY("D");
            this.finishY("D");
          },
        },
        DP: {
          rotate: (speed) => this.rotateY(-1, speed, "D"),
          finish: () => this.finishY("DP"),
        },
        U: {
          rotate: (speed) => this.rotateY(-1, speed, "U"),
          finish: () => this.finishY("U"),
        },
        U2: {
          rotate: (speed) => this.rotateY(-1, speed, "U"),
          finish: () => {
            this.finishY("U");
            this.finishY("U");
          },
        },
        UP: {
          rotate: (speed) => this.rotateY(1, speed, "U"),
          finish: () => this.finishY("UP"),
        },
        F: {
          rotate: (speed) => this.rotateZ(-1, speed, "F"),
          finish: () => this.finishZ("F"),
        },
        F2: {
          rotate: (speed) => this.rotateZ(-1, speed, "F"),
          finish: () => {
            this.finishZ("F");
            this.finishZ("F");
          },
        },
        FP: {
          rotate: (speed) => this.rotateZ(1, speed, "F"),
          finish: () => this.finishZ("FP"),
        },
        B: {
          rotate: (speed) => this.rotateZ(1, speed, "B"),
          finish: () => this.finishZ("B"),
        },
        B2: {
          rotate: (speed) => this.rotateZ(1, speed, "B"),
          finish: () => {
            this.finishZ("B");
            this.finishZ("B");
          },
        },
        BP: {
          rotate: (speed) => this.rotateZ(-1, speed, "B"),
          finish: () => this.finishZ("BP"),
        },
      },
      allMoves: [
        { id: "L", name: "L" },
        { id: "L2", name: "L2" },
        { id: "LP", name: "L'" },
        { id: "R", name: "R" },
        { id: "R2", name: "R2" },
        { id: "RP", name: "R'" },
        { id: "D", name: "D" },
        { id: "D2", name: "D2" },
        { id: "DP", name: "D'" },
        { id: "U", name: "U" },
        { id: "U2", name: "U2" },
        { id: "UP", name: "U'" },
        { id: "B", name: "B" },
        { id: "B2", name: "B2" },
        { id: "BP", name: "B'" },
        { id: "F", name: "F" },
        { id: "F2", name: "F2" },
        { id: "FP", name: "F'" },
      ],
    };
  },
  computed: {
    aspectRatio() {
      return this.width / this.height;
    },
  },
  mounted() {
    const urls = [
      "img/pisa/px.png",
      "img/pisa/nx.png",
      "img/pisa/py.png",
      "img/pisa/ny.png",
      "img/pisa/pz.png",
      "img/pisa/nz.png",
    ];

    const background = new CubeTextureLoader().load(urls);
    this.black = new MeshStandardMaterial({
      color: "#000000",
      reflectivity: 10,
    });
    this.red = new MeshStandardMaterial({
      name: "r",
      map: new TextureLoader().load("/img/red.png"),
      envMap: background,
      metalness: 0.5,
      roughness: 0.01,
    });
    this.orange = new MeshStandardMaterial({
      name: "o",
      map: new TextureLoader().load("/img/orange.png"),
      envMap: background,
      metalness: 0.5,
      roughness: 0.01,
    });
    this.green = new MeshStandardMaterial({
      name: "g",
      map: new TextureLoader().load("/img/green.png"),
      envMap: background,
      metalness: 0.5,
      roughness: 0.01,
    });
    this.blue = new MeshStandardMaterial({
      name: "b",
      map: new TextureLoader().load("/img/blue.png"),
      envMap: background,
      metalness: 0.5,
      roughness: 0.01,
    });
    this.yellow = new MeshStandardMaterial({
      name: "y",
      map: new TextureLoader().load("/img/yellow.png"),
      envMap: background,
      metalness: 0.5,
      roughness: 0.01
    });
    this.white = new MeshStandardMaterial({
      name: "w",
      map: new TextureLoader().load("/img/white.png"),
      envMap: background,
      metalness: 0.5,
      roughness: 0.01,
    });
    // this.colors.U = this.yellow;
    this.setCanvas();
    this.animate();
  },
  methods: {
    setColor(color, ix) {
      const { f, r, c } = this.selectingColor;
      if (r == 1 && c == 1) return;
      const map = {
        R: { d: 0, a: [8, 5, 2, 7, 4, 1, 6, 3, 0] },
        L: { d: 1, a: [2, 5, 8, 1, 4, 7, 0, 3, 6] },
        U: { d: 2, a: [0, 1, 2, 3, 4, 5, 6, 7, 8] },
        D: { d: 3, a: [6, 7, 8, 3, 4, 5, 0, 1, 2] },
        F: { d: 4, a: [6, 7, 8, 3, 4, 5, 0, 1, 2] },
        B: { d: 5, a: [0, 1, 2, 3, 4, 5, 6, 7, 8] },
      };
      const F = f.toUpperCase();
      console.log(color, ix, this.selectingColor);
      const p = map[F].a[r * 3 + c];
      this.cubes[this.faces[F][p]].material[map[F].d] = this.mapColor(ix);
      this.pp = this.positions();
    },
    selectColor(p) {
      const { f, r, c } = this.selectingColor || { f: 0, r: 0, c: 0 };
      if (p.f == f && p.r == r && p.c == c) return (this.selectingColor = null);
      this.selectingColor = p;
    },
    showPatterns() {
      this.viewingPatterns = 1;
    },
    playPattern(m) {
      this.viewingPatterns = 0;
      console.log(m);
      this.play(m);
    },
    mapColor(f) {
      const map = {
        y: this.yellow,
        w: this.white,
        r: this.red,
        o: this.orange,
        b: this.blue,
        g: this.green,
      };
      const c = this.colors[f].id;
      const t = map[c];
      t.name = f;
      return t;
    },
    reset() {
      this.faces["R"].forEach(
        (f) => (this.cubes[f].material[0] = this.mapColor("R"))
      );
      this.faces["L"].forEach(
        (f) => (this.cubes[f].material[1] = this.mapColor("L"))
      );
      this.faces["U"].forEach(
        (f) => (this.cubes[f].material[2] = this.mapColor("U"))
      );
      this.faces["D"].forEach(
        (f) => (this.cubes[f].material[3] = this.mapColor("D"))
      );
      this.faces["F"].forEach(
        (f) => (this.cubes[f].material[4] = this.mapColor("F"))
      );
      this.faces["B"].forEach(
        (f) => (this.cubes[f].material[5] = this.mapColor("B"))
      );
      this.pp = this.positions();
    },
    addRubik(scene) {
      for (const x of [-1, 0, 1])
        for (const y of [-1, 0, 1])
          for (const z of [-1, 0, 1])
            if (x || y || z) {
              const maps = [
                x > 0 ? this.mapColor("R") : this.black,
                x < 0 ? this.mapColor("L") : this.black,
                y > 0 ? this.mapColor("U") : this.black,
                y < 0 ? this.mapColor("D") : this.black,
                z > 0 ? this.mapColor("F") : this.black,
                z < 0 ? this.mapColor("B") : this.black,
              ];
              const p = x + 1 + (y + 1) * 3 + (z + 1) * 9;
              const s = 10.1;
              this.cubes[p] = this.addCube(scene, x * s, y * s, z * s, maps);
            }
      this.pp = this.positions();
    },
    rotateZ(d, speed, f) {
      this.faces[f].forEach((c) => {
        this.cubes[c].rotation.z += (d * (Math.PI * speed)) / 180;
      });
    },
    rotateX(d, speed, f) {
      this.faces[f].forEach((c) => {
        this.cubes[c].rotation.x += (d * (Math.PI * speed)) / 180;
      });
    },
    rotateY(d, speed, f) {
      this.faces[f].forEach((c) => {
        this.cubes[c].rotation.y += (d * (Math.PI * speed)) / 180;
      });
    },
    addCube(scene, x, y, z, maps) {
      const geometry = new RoundedBoxGeometry(10, 10, 10, 1, 1);
      geometry.translate(x, y, z);
      // const material = new MeshPhongMaterial({
      //   color: 0xccddff,
      //   wireframe: false,
      //   specular: new Color(0xffffff),
      //   shininess: 0,
      //   reflectivity: 0,
      // });
      const mesh = new Mesh(geometry, maps);
      mesh.name = x && y && z ? "C" : "E";
      scene.add(mesh);
      return mesh;
    },
    setCanvas() {
      let map = document.getElementById("container");
      let mapDimensions = map.getBoundingClientRect();
      this.width = mapDimensions.width;
      this.height = mapDimensions.height;

      //   this.width = window.innerWidth;
      //   this.height = window.innerHeight;

      // Renderer
      this.renderer = new WebGLRenderer({ antialias: true });
      this.renderer.setSize(this.width, this.height);
      this.renderer.setPixelRatio(1.0);

      //   document
      //     .getElementById("container")
      map.appendChild(this.renderer.domElement);

      // Create Scene
      this.scene = new Scene();

      const urls = [
        "/img/pisa/px.png",
        "/img/pisa/nx.png",
        "/img/pisa/py.png",
        "/img/pisa/ny.png",
        "/img/pisa/pz.png",
        "/img/pisa/nz.png",
      ];

      this.background = new CubeTextureLoader().load(urls);
      this.scene.background = this.background;
      // this.scene.background = new TextureLoader().load("/img/galaxy1.avif");

      this.addRubik(this.scene, -15, 0, 3);

      // Lights
      //   this.light = new PointLight(0xffffff, 100);
      this.light = new HemisphereLight(0xffffff, 0xdddddd, 1);
      this.light.position.set(1, 10, 1);
      this.scene.add(this.light);
      const light = new AmbientLight(0x888888);
      this.scene.add(light);
      var light1 = new DirectionalLight(0xffffff);
      light1.position.set(0, 20, 0).normalize();
      this.scene.add(light1);

      // Camera
      this.camera = new PerspectiveCamera(45, this.aspectRatio, 0.1, 1000);
      this.camera.position.x = 40;
      this.camera.position.y = 40;
      this.camera.position.z = 70;
      this.camera.lookAt(new Vector3(0, 0, 0));
      this.scene.add(this.camera);

      this.camera2 = new PerspectiveCamera(30, 1, 0.1, 1000);
      this.camera2.position.x = 50;
      this.camera2.position.y = 50;
      this.camera2.position.z = 70;
      this.camera2.lookAt(new Vector3(-1, -2, 0));
      this.scene.add(this.camera2);

      this.camera3 = new PerspectiveCamera(30, 1, 0.1, 1000);
      this.camera3.position.x = -50;
      this.camera3.position.y = -50;
      this.camera3.position.z = -70;
      this.camera3.lookAt(new Vector3(1, 2, 0));
      this.scene.add(this.camera3);

      this.renderer.render(this.scene, this.camera);
      // Controls
      // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls = new TrackballControls(
        this.camera,
        this.renderer.domElement
      );
      this.controls.panSpeed = 0.3;
      this.controls.rotateSpeed = 5;
      // this.controls.enableDamping = true;
    },

    updateCamera() {
      this.camera.aspect = this.aspectRatio;
      this.camera.updateProjectionMatrix();
    },

    updateRenderer() {
      this.renderer.setSize(this.width, this.height);
      this.renderer.render(this.scene, this.camera);
    },

    finishX(move) {
      const f = move.substring(0, 1);
      this.faces[f].forEach((c) => (this.cubes[c].rotation.x = 0));
      const save = {
        L: [
          [18, [3, 4, 1]],
          [21, [4, 1]],
        ],
        LP: [
          [18, [3, 4, 1]],
          [21, [4, 1]],
        ],
        R: [
          [20, [3, 4, 0]],
          [23, [4, 0]],
        ],
        RP: [
          [20, [3, 4, 0]],
          [23, [4, 0]],
        ],
      };
      const load = {
        L: [
          [0, [5, 3, 1]],
          [9, [3, 1]],
        ],
        LP: [
          [24, [4, 2, 1]],
          [15, [2, 1]],
        ],
        R: [
          [26, [4, 2, 0]],
          [17, [2, 0]],
        ],
        RP: [
          [2, [5, 3, 0]],
          [11, [3, 0]],
        ],
      };
      const maps = {
        L: [
          [18, [4, 3, 1], 24, [2, 4, 1]],
          [24, [4, 2, 1], 6, [2, 5, 1]],
          [6, [2, 5, 1], 0, [5, 3, 1]],
          [21, [4, 1], 15, [2, 1]],
          [15, [2, 1], 3, [5, 1]],
          [3, [5, 1], 9, [3, 1]],
        ],
        LP: [
          [18, [4, 3, 1], 0, [3, 5, 1]],
          [0, [3, 5, 1], 6, [5, 2, 1]],
          [6, [5, 2, 1], 24, [2, 4, 1]],
          [21, [4, 1], 9, [3, 1]],
          [9, [3, 1], 3, [5, 1]],
          [3, [5, 1], 15, [2, 1]],
        ],
        R: [
          [20, [4, 3, 0], 2, [3, 5, 0]],
          [2, [3, 5, 0], 8, [5, 2, 0]],
          [8, [5, 2, 0], 26, [2, 4, 0]],
          [23, [4, 0], 11, [3, 0]],
          [11, [3, 0], 5, [5, 0]],
          [5, [5, 0], 17, [2, 0]],
        ],
        RP: [
          [20, [4, 3, 0], 26, [2, 4, 0]],
          [26, [4, 2, 0], 8, [2, 5, 0]],
          [8, [2, 5, 0], 2, [5, 3, 0]],
          [23, [4, 0], 17, [2, 0]],
          [17, [2, 0], 5, [5, 0]],
          [5, [5, 0], 11, [3, 0]],
        ],
      };

      const m = [];
      save[move].forEach((t) => m.push(this.save(...t)));
      maps[move].forEach((a) => this.map(...a));
      load[move].forEach((t, ix) => this.load(...t, m[ix]));
    },
    map(c1, m1, c2, m2) {
      m1.forEach((x, i) => {
        this.cubes[c1].material[m1[i]] = this.cubes[c2].material[m2[i]];
      });
    },
    finishY(move) {
      const f = move.substring(0, 1);
      this.faces[f].forEach((c) => (this.cubes[c].rotation.y = 0));
      const save = {
        D: [
          [18, [4, 1, 3]],
          [19, [4, 3]],
        ],
        DP: [
          [18, [4, 1, 3]],
          [19, [4, 3]],
        ],
        U: [
          [24, [4, 1, 2]],
          [25, [4, 2]],
        ],
        UP: [
          [24, [4, 1, 2]],
          [25, [4, 2]],
        ],
      };
      const load = {
        D: [
          [20, [0, 4, 3]],
          [11, [0, 3]],
        ],
        DP: [
          [0, [1, 5, 3]],
          [9, [1, 3]],
        ],
        U: [
          [6, [1, 5, 2]],
          [15, [1, 2]],
        ],
        UP: [
          [26, [0, 4, 2]],
          [17, [0, 2]],
        ],
      };
      const maps = {
        D: [
          [18, [4, 1, 3], 0, [1, 5, 3]],
          [0, [1, 5, 3], 2, [5, 0, 3]],
          [2, [5, 0, 3], 20, [0, 4, 3]],

          [19, [4, 3], 9, [1, 3]],
          [9, [1, 3], 1, [5, 3]],
          [1, [5, 3], 11, [0, 3]],
        ],
        DP: [
          [18, [4, 1, 3], 20, [0, 4, 3]],
          [20, [4, 0, 3], 2, [0, 5, 3]],
          [2, [0, 5, 3], 0, [5, 1, 3]],

          [19, [4, 3], 11, [0, 3]],
          [11, [0, 3], 1, [5, 3]],
          [1, [5, 3], 9, [1, 3]],
        ],
        U: [
          [24, [4, 1, 2], 26, [0, 4, 2]],
          [26, [4, 0, 2], 8, [0, 5, 2]],
          [8, [0, 5, 2], 6, [5, 1, 2]],

          [25, [4, 2], 17, [0, 2]],
          [17, [0, 2], 7, [5, 2]],
          [7, [5, 2], 15, [1, 2]],
        ],
        UP: [
          [24, [4, 1, 2], 6, [1, 5, 2]],
          [6, [1, 5, 2], 8, [5, 0, 2]],
          [8, [5, 0, 2], 26, [0, 4, 2]],

          [25, [4, 2], 15, [1, 2]],
          [15, [1, 2], 7, [5, 2]],
          [7, [5, 2], 17, [0, 2]],
        ],
      };

      const m = [];
      save[move].forEach((t) => m.push(this.save(...t)));
      maps[move].forEach((a) => this.map(...a));
      load[move].forEach((t, ix) => this.load(...t, m[ix]));
    },
    save(c, f) {
      const t = [];
      f.forEach((k) => t.push(this.cubes[c].material[k]));
      return t;
    },
    load(c, f, s) {
      const cube = this.cubes[c];
      f.forEach((x, i) => {
        cube.material[x] = s[i];
      });
    },
    finishZ(move) {
      const f = move.substring(0, 1);
      this.faces[f].forEach((c) => (this.cubes[c].rotation.z = 0));
      const save = {
        FP: [
          [24, [1, 2, 4]],
          [25, [2, 4]],
        ],
        F: [
          [24, [1, 2, 4]],
          [25, [2, 4]],
        ],
        BP: [
          [6, [1, 2, 5]],
          [7, [2, 5]],
        ],
        B: [
          [6, [1, 2, 5]],
          [7, [2, 5]],
        ],
      };
      const load = {
        FP: [
          [18, [3, 1, 4]],
          [21, [1, 4]],
        ],
        F: [
          [26, [2, 0, 4]],
          [23, [0, 4]],
        ],
        BP: [
          [8, [2, 0, 5]],
          [5, [0, 5]],
        ],
        B: [
          [0, [3, 1, 5]],
          [3, [1, 5]],
        ],
      };
      const maps = {
        F: [
          [24, [1, 2, 4], 18, [3, 1, 4]],
          [18, [3, 1, 4], 20, [0, 3, 4]],
          [20, [0, 3, 4], 26, [2, 0, 4]],
          [25, [2, 4], 21, [1, 4]],
          [21, [1, 4], 19, [3, 4]],
          [19, [3, 4], 23, [0, 4]],
        ],
        FP: [
          [24, [1, 2, 4], 26, [2, 0, 4]],
          [26, [2, 0, 4], 20, [0, 3, 4]],
          [20, [0, 3, 4], 18, [3, 1, 4]],
          [25, [2, 4], 23, [0, 4]],
          [23, [0, 4], 19, [3, 4]],
          [19, [3, 4], 21, [1, 4]],
        ],
        BP: [
          [6, [1, 2, 5], 0, [3, 1, 5]],
          [0, [3, 1, 5], 2, [0, 3, 5]],
          [2, [0, 3, 5], 8, [2, 0, 5]],
          [7, [2, 5], 3, [1, 5]],
          [3, [1, 5], 1, [3, 5]],
          [1, [3, 5], 5, [0, 5]],
        ],
        B: [
          [6, [1, 2, 5], 8, [2, 0, 5]],
          [8, [2, 0, 5], 2, [0, 3, 5]],
          [2, [0, 3, 5], 0, [3, 1, 5]],
          [7, [2, 5], 5, [0, 5]],
          [5, [0, 5], 1, [3, 5]],
          [1, [3, 5], 3, [1, 5]],
        ],
      };

      const m = [];
      save[move].forEach((t) => m.push(this.save(...t)));
      maps[move].forEach((a) => this.map(...a));
      load[move].forEach((t, ix) => this.load(...t, m[ix]));
    },
    start(move) {
      this.angle = move ? 0 : 90;
      this.maxAngle = 90;
      if (move && move.match(/2$/)) this.maxAngle = 180;

      this.inMove = { move };
    },
    startPattern(p) {
      this.pattern = { moves: p, ix: 0 };
      this.start((p.length && p[0]) || null);
    },
    positions() {
      const pp = this.cubes.map((x, ix) => {
        return x.material.map((m) => m.name || "-").join("");
      });
      const cc = [6, 8, 24, 26, 0, 2, 18, 20];
      const ee = [7, 15, 17, 25, 3, 5, 21, 23, 1, 9, 11, 19];
      const cube = {
        edges: ee.map((e) => pp[e]),
        corners: cc.map((c) => pp[c]),
      };
      return cube;
    },
    shuffle() {
      const p = [];
      const t = Object.keys(this.actions);
      for (let i = 0; i < 20; i++) {
        const r = Math.trunc(Math.random() * t.length);
        p.push(t[r]);
      }
      this.play(p.join(","));
    },
    solveOne() {
      const id = this.toSolve.list[this.toSolve.index];
      const pp = this.positions();
      const p = Solver.solve(pp, id);
      console.log("solve one", id, pp, p);
      // if (mm && mm.length)
      p && this.solution.push(id + ": " + p);
      this.play(p);
    },
    solve() {
      this.solution = [];
      this.solved = 0;
      this.toSolve = {
        list: [
          "UF",
          "UL",
          "UB",
          "UR",
          "UFL",
          "ULB",
          "UBR",
          "URF",
          "FL",
          "FR",
          "RB",
          "BL",
          "3a",
          "3b",
          "3c",
        ],
        index: 0,
      };
      this.solveOne();
    },
    play(t) {
      const p = t
        .toUpperCase()
        .replace(/'/g, "P")
        .replace(/ /g, ",")
        .split(",");
      this.toPlay = t.toUpperCase();
      this.startPattern(p);
    },
    playBack() {
      const p = this.toPlay
        .toUpperCase()
        .replace(/'/g, "P")
        .replace(/ /g, ",")
        .split(",")
        .reverse()
        .map((x) => {
          if (x.length < 2) return x + "P";
          if (x[1] == "P") return x.slice(0, 1);
          return x;
        });
      this.startPattern(p);
    },
    screenshot() {
      // open in new window like this
      //
      var w = window.open("", "");
      w.document.title = "Screenshot";
      //w.document.body.style.backgroundColor = "red";
      var img = new Image();
      // Without 'preserveDrawingBuffer' set to true, we must render now
      this.renderer.setSize(512, 512);
      this.scene.background = new Color(0xeeeeee);
      this.renderer.render(this.scene, this.camera3);
      img.src = this.renderer.domElement.toDataURL();
      w.document.body.appendChild(img);

      img = new Image();
      this.renderer.render(this.scene, this.camera2);
      img.src = this.renderer.domElement.toDataURL();
      w.document.body.appendChild(img);
      this.scene.background = this.background;
      this.renderer.setSize(this.width, this.height);

      // this.renderer.render(this.scene, this.camera);
    },
    doMove(action) {
      // if (!this.actions[action.move])
      //   return;
      if (this.angle >= this.maxAngle) {
        this.inMove = false;
        action.move &&
          this.actions[action.move] &&
          this.actions[action.move].finish();
        this.pp = this.positions();
        if (this.pattern) {
          const p = this.pattern;
          p.ix++;
          if (p.ix < p.moves.length) {
            this.start(p.moves[p.ix]);
          } else {
            if (this.toSolve) {
              this.toSolve.index++;
              if (this.toSolve.index < this.toSolve.list.length) {
                console.log(
                  "solve one:",
                  this.toSolve.list[this.toSolve.index]
                );
                this.solveOne();
              } else {
                this.toSolve = null;
                this.solved = 1;
              }
            } else this.pattern = null;
          }
        }
        return;
      }
      this.angle += 1 * this.speed;
      // console.log(action.move);
      const a = this.actions[action.move];
      a && a.rotate(this.speed);
    },
    animate() {
      if (this.inMove) this.doMove(this.inMove);
      this.controls.update();
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
  },
  watch: {
    aspectRatio: function (val, oldval) {
      if (val) {
        this.updateCamera();
        this.updateRenderer();
      }
    },
  },
};
</script>
<style lang="less" scoped>
input {
  padding: 0 0.5rem;
  font-size: 1.8rem;
}
.button {
  font-size: 1.8rem;
  padding: 0.2rem 0.5rem;
  margin: 0.5rem;
}
#container {
  overflow: hidden;
  flex: 1 0;
}
.blocker {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff80;
}
</style>