<template lang="pug">
</template>

<script>
export default {
  cube: null,
  //   cube: {
  //     corners: [],
  //     edges: [],
  //   },
  //   map(pp) {
  //     const cc = [6, 8, 24, 26, 0, 2, 18, 20];
  //     const ee = [7, 15, 17, 25, 3, 5, 21, 23, 1, 9, 11, 19];
  //     this.cube.edges = ee.map(e => pp[e]);
  //     this.cube.corners = cc.map(c => pp[c]);
  //   },
  findEdge(cc) {
    console.log('find edge', cc);
    const u = this.cube.edges.findIndex(
      (x) => x.includes(cc[0]) && x.includes(cc[1])
    );
    console.log('found edge', u, this.cube.edges[u])
    return { p:u, c:this.cube.edges[u] };
  },
  findEdges(ee) {
    console.log('find edges', ee);
    const t = ee.map(x => {
      const v = this.findEdge(x.split(''));
      console.log('edge', v.c[3]);
      const rotated = v.c[3] != 'D';
      return (v.p - 8) + (rotated ? 'r' : 'n');
    });
    return t.join('');
  },
  findCorner(cc) {
    const u = this.cube.corners.findIndex(
      (x) => x.includes(cc[0]) && x.includes(cc[1]) && x.includes(cc[2])
    );
    return { p:u, c:this.cube.corners[u] };
  },
  findCorners(cc) {
    const f = cc.map((c) => this.findCorner(c));
    return f;
  },
  findRotation(cc) {
    const map = {
//     RLUDFB
      '-L-D-B': 0,
      '-D-B-L': 2,
      '-B-L-D': 1,
      'R--D-B': 0,
      'B--R-D': 2,
      'D--B-R': 1,
      '-L-DF-': 0,
      '-D-FL-': 1,
      '-F-LD-': 2,
      'R--DF-': 0,
      'F--RD-': 1,
      'D--FR-': 2,
    };
    const f = cc.map(c => {
      const t = this.findCorner(c);
      return map[t.c];
    }).join('');
    return f;
  },
  f3a: (self, pp) => {
    const moves = {
      '4567': '', // ready
      '4576': 'DP,LP,F,L,D,LP,DP,FP,D,L,BP,L,B,D,BP,DP,LP,D,B',
      '4765': 'BP,L,B,D,BP,DP,LP,D,B',
      '4756': 'D2,BP,L,B,D,BP,DP,LP,D,B,LP,F,L,D,LP,DP,FP,D,L',
      '4657': 'D2,LP,F,L,D,LP,DP,FP,D,L,BP,L,B,D,BP,DP,LP,D,B,LP,F,L,D,LP,DP,FP,D,L',
      '4675': 'DP,LP,F,L,D,LP,DP,FP,D,L',
      '5647': 'D,BP,L,B,D,BP,DP,LP,D,B',
      '5674': 'DP,BP,L,B,D,BP,DP,LP,D,B,LP,F,L,D,LP,DP,FP,D,L',
      '5476': 'DP,LP,F,L,D,LP,DP,FP,D,L,BP,L,B,D,BP,DP,LP,D,B,LP,F,L,D,LP,DP,FP,D,L',
      '5467': 'LP,F,L,D,LP,DP,FP,D,L',
      '5746': 'D', //ok
      '5764': 'LP,F,L,D,LP,DP,FP,D,L,BP,L,B,D,BP,DP,LP,D,B',
      '6475': 'DP', //ok
      '6457': 'D2,LP,F,L,D,LP,DP,FP,D,L,BP,L,B,D,BP,DP,LP,D,B',
      '6574': 'DP,BP,L,B,D,BP,DP,LP,D,B',
      '6547': 'D,BP,L,B,D,BP,DP,LP,D,B,LP,F,L,D,LP,DP,FP,D,L',
      '6745': 'D,LP,F,L,D,LP,DP,FP,D,L,BP,L,B,D,BP,DP,LP,D,B,LP,F,L,D,LP,DP,FP,D,L',
      '6754': 'D2,LP,F,L,D,LP,DP,FP,D,L',
      '7546': 'D,LP,F,L,D,LP,DP,FP,D,L',
      '7564': 'LP,F,L,D,LP,DP,FP,D,L,BP,L,B,D,BP,DP,LP,D,B,LP,F,L,D,LP,DP,FP,D,L',
      '7645': 'D,LP,F,L,D,LP,DP,FP,D,L,BP,L,B,D,BP,DP,LP,D,B',
      '7654': 'D2', //ok
      '7456': 'D2,BP,L,B,D,BP,DP,LP,D,B',
      '7465': 'BP,L,B,D,BP,DP,LP,D,B,LP,F,L,D,LP,DP,FP,D,L',
    }
    const t = self.findCorners(["LDB","RDB", "LDF", "RDF"]);
    const m = t.map(x => x.p).join('');
    console.log('F3A', t, m, moves[m]);
    return moves[m]||'';
  },
  f3b(self, pp) {
    const moves = {
      fpuf: 'FP,U,F,R,U,RP,D,R,UP,RP,FP,UP,F,DP',
      dpbd: 'DP,B,D,L,B,LP,F,L,BP,LP,DP,BP,D,FP',
      dpld: 'DP,L,D,F,L,FP,R,F,LP,FP,DP,LP,D,RP',
      bpub: 'BP,U,B,L,U,LP,D,L,UP,LP,BP,UP,B,DP',
      lpul: 'LP,U,L,F,U,FP,D,F,UP,FP,LP,UP,L,DP',
      rpur: 'RP,U,R,B,U,BP,D,B,UP,BP,RP,UP,R,DP',
      dpfd: 'DP,F,D,R,F,RP,B,R,FP,RP,DP,FP,D,BP',
      dprd: 'DP,R,D,B,R,BP,L,B,RP,BP,DP,RP,D,LP'
    }
    const map = {
      '0000': '', //ready
      '0012': moves.fpuf, //'FP,U,F,R,U,RP,D,R,UP,RP,FP,UP,F,DP',
      '0021': moves.dpbd, //'DP,B,D,L,B,LP,F,L,BP,LP,DP,BP,D,FP',
      '0102': moves.dpld, //'DP,L,D,F,L,FP,R,F,LP,FP,DP,LP,D,RP',
      '0111': moves.fpuf + ',' + moves.dpld,
      '0120': moves.bpub + ',' + moves.lpul, 
      '0201': moves.rpur, //'RP,U,R,B,U,BP,D,B,UP,BP,RP,UP,R,DP',
      '0210': moves.rpur + ',' + moves.fpuf,
      '0222': moves.rpur + ',' + moves.dpbd,
      '1002': moves.fpuf + ',' + moves.lpul,
      '1011': moves.lpul + ',' + moves.dpbd,
      '1020': moves.lpul,
      '1101': moves.rpur + ',' + moves.dpfd,
      '1110': moves.bpub + ',' + moves.dprd,
      '1122': moves.dpld + ',' + moves.lpul,
      '1200': moves.dpfd,
      '1212': moves.dpfd + ',' + moves.fpuf,
      '1221': moves.rpur + ',' + moves.lpul,
      '2001': moves.dprd + ',' + moves.dpbd,
      '2010': moves.dprd, //'DP,R,D,B,R,BP,L,B,RP,BP,DP,RP,D,LP',
      '2022': moves.fpuf + ',' + moves.dprd,
      '2100': moves.bpub,
      '2112': moves.fpuf + ',' + moves.bpub, 
      '2121': moves.bpub + ',' + moves.dpbd,
      '2202': moves.bpub + ',' + moves.dpld, 
      '2211': moves.rpur + ',' + moves.dprd,
      '2220': moves.dpfd + ',' + moves.lpul,
    }

    const t = self.findRotation(["LDB","RDB", "LDF", "RDF"]);
    console.log('F3B', t, map[t]);
    return map[t] || '';
  },
  f3c(self, pp) {
    const map = {
      '0n1n2n3n': '', //ready
      '0n1n2r3r': 'FP,D2,F2,D,FP,DP,FP,D2,B,R,F,RP,BP',
      '0n1r2n3r': 'LP,D2,L2,D,LP,DP,LP,D2,R,F,L,FP,RP',
      '0n1r2r3n': 'DP,R,FP,D,RP,F,BP,D,LP,F,DP,L,FP,B',

      '0n2n3n1n': 'F2,UP,BP,F,D2,B,FP,R2,U,F2',
      '0n2n3r1r': 'RP,FP,R,BP,F,D2,B,FP,R,F,R',
      '0n2r3n1r': 'R2,L,B,D,R2,F,BP,D2,FP,B,DP,BP,R2,LP',
      '0n2r3r1n': 'L2,RP,BP,D,BP,F,L2,B,FP,D,B,R,L2',

      '0n3n1n2n': 'F2,UP,R2,BP,F,D2,B,FP,U,F2',
      '0n3n1r2r': 'L2,RP,BP,DP,BP,F,L2,B,FP,DP,B,R,L2',
      '0n3r1n2r': 'RP,FP,RP,BP,F,D2,B,FP,RP,F,R',
      '0n3r1r2n': 'R2,L,B,D,F,BP,D2,FP,B,R2,DP,BP,R2,LP',

      '0r1n2n3r': 'B,R,FP,D2,F2,D,FP,DP,FP,D2,B,R,F,RP,BP,RP,BP',
      '0r1n2r3n': 'RP,D2,R2,D,RP,DP,RP,D2,L,B,R,BP,LP',
      '0r1r2n3n': 'BP,D2,B2,D,BP,DP,BP,D2,F,L,B,LP,FP',
      '0r1r2r3r': 'R2,F2,R2,D2,R,LP,F,RP,L,D2,R2,F2,R2,D',

      '0r2n3n1r': 'F2,UP,BP,F,D2,B,FP,R2,U,F2,DP,F,LP,D,FP,L,RP,D,BP,L,DP,B,LP,R',
      '0r2n3r1n': 'F2,UP,BP,F,D2,B,FP,R2,U,F2,RP,D2,R2,D,RP,DP,RP,D2,L,B,R,BP,LP',
      '0r2r3n1n': 'F2,UP,BP,F,D2,B,FP,R2,U,F2,BP,D2,B2,D,BP,DP,BP,D2,F,L,B,LP,FP',
      '0r2r3r1r': 'F2,UP,BP,F,D2,B,FP,R2,U,F2,R2,F2,R2,D2,R,LP,F,RP,L,D2,R2,F2,R2,D',

      '0r3n1n2r': 'F2,UP,R2,F,BP,D2,FP,B,U,F2,DP,B,RP,D,BP,R,LP,D,FP,R,DP,F,RP,L',
      '0r3n1r2n': 'F2,UP,R2,BP,F,D2,B,FP,U,F2,RP,D2,R2,D,RP,DP,RP,D2,L,B,R,BP,LP',
      '0r3r1n2n': 'F2,UP,R2,BP,F,D2,B,FP,U,F2,BP,D2,B2,D,BP,DP,BP,D2,F,L,B,LP,FP',
      '0r3r1r2r': 'F2,UP,R2,BP,F,D2,B,FP,U,F2,R2,F2,R2,D2,R,LP,F,RP,L,D2,R2,F2,R2,D',

      '1n0n3n2n': 'F2,B2,UP,F2,B2,R2,F2,B2,L2,U,F2,B2',
      '1n0n3r2r': 'R,F,D,FP,DP,R2,BP,DP,B,D,R',
      '1n0r3n2r': 'B2,F2,UP,B2,F2,L2,B2,F2,R2,U,B2,F2,LP,D2,L2,D,LP,DP,LP,D2,R,F,L,FP,RP',
      '1n0r3r2n': 'F2,B2,UP,F2,B2,R2,F2,B2,L2,U,F2,B2,DP,R,FP,D,RP,F,BP,D,LP,F,DP,L,FP,B',

      '1n2n0n3n': 'B2,UP,L2,FP,B,D2,F,BP,U,B2',
      '1n2n0r3r': 'B2,UP,L2,FP,B,D2,F,BP,U,B2,FP,D2,F2,D,FP,DP,FP,D2,B,R,F,RP,BP',
      '1n2r0n3r': 'B2,UP,L2,FP,B,D2,F,BP,U,B2,LP,D2,L2,D,LP,DP,LP,D2,R,F,L,FP,RP',
      '1n2r0r3n': 'L2,R,F,D,FP,B,D2,F,BP,L2,DP,FP,RP,L2',

      '1n3n2n0n': 'L2,UP,F2,RP,L,D2,R,LP,U,L2',
      '1n3n2r0r': 'L2,UP,F2,RP,L,D2,R,LP,U,L2,FP,D2,F2,D,FP,DP,FP,D2,B,R,F,RP,BP',
      '1n3r2n0r': 'B2,FP,RP,DP,RP,L,B2,R,LP,DP,R,F,B2',
      '1n3r2r0n': 'L2,UP,F2,RP,L,D2,R,LP,U,L2,DP,R,FP,D,RP,F,BP,D,LP,F,DP,L,FP,B',
      
      '1r0n3n2r': 'B2,F2,UP,B2,F2,R2,B2,F2,L2,U,B2,F2,DP,F,LP,D,FP,RP,L,D,BP,L,DP,B,R,LP',
      '1r0n3r2n': 'F2,B2,UP,F2,B2,R2,F2,B2,L2,U,F2,B2,RP,D2,R2,D,RP,DP,RP,D2,L,B,R,BP,LP',
      '1r0r3n2n': 'L,B,D,BP,DP,L2,FP,DP,F,D,L',
      '1r0r3r2r': 'LP,RP,FP,D2,R2,D2,R2,D2,R2,F,R,L',

      '1r2n0n3r': 'R2,LP,FP,DP,FP,B,R2,F,BP,DP,F,L,R2,LP,D2,L2,D,LP,DP,LP,D2,R,F,L,FP,RP',
      '1r2n0r3n': 'LP,BP,LP,FP,B,D2,F,BP,LP,B,L',
      '1r2r0n3n': 'R2,LP,FP,DP,FP,B,R2,F,BP,DP,F,L,R2',
      '1r2r0r3r': 'B2,UP,L2,FP,B,D2,F,BP,U,B2,R2,F2,R2,D2,R,LP,F,RP,L,D2,R2,F2,R2,D',
      
      '1r3n2n0r': 'F2,B,R,D,RP,L,D2,R,LP,F2,DP,RP,BP,F2',
      '1r3n2r0n': 'L2,UP,F2,RP,L,D2,R,LP,U,L2,RP,D2,R2,D,RP,DP,RP,D2,L,B,R,BP,LP',
      '1r3r2n0n': 'FP,LP,FP,RP,L,D2,R,LP,FP,L,F',
      '1r3r2r0r': 'L2,UP,F2,RP,L,D2,R,LP,U,L2,R2,F2,R2,D2,R,LP,F,RP,L,D2,R2,F2,R2,D',

      '2n0n1n3n': 'B2,UP,B,FP,D2,BP,F,L2,U,B2',
      '2n0n1r3r': 'B2,UP,FP,B,D2,F,BP,L2,U,B2,FP,D2,F2,D,FP,DP,FP,D2,B,R,F,RP,BP',
      '2n0r1n3r': 'B2,UP,FP,B,D2,F,BP,L2,U,B2,LP,D2,L2,D,LP,DP,LP,D2,R,F,L,FP,RP',
      '2n0r1r3n': 'R2,LP,FP,D,FP,B,R2,F,BP,D,F,L,R2',

      '2n1n3n0n': 'R2,UP,R,LP,D2,RP,L,B2,U,R2',
      '2n1n3r0r': 'B2,F,L,D,B2,LP,R,D2,RP,L,DP,LP,B2,FP',
      '2n1r3n0r': 'R2,UP,LP,R,D2,L,RP,B2,U,R2,LP,D2,L2,D,LP,DP,LP,D2,R,F,L,FP,RP',
      '2n1r3r0n': 'R2,UP,LP,R,D2,L,RP,B2,U,R2,DP,L,BP,D,LP,B,FP,D,RP,B,DP,R,BP,F',

      '2n3n0n1n': 'R2,L2,UP,R2,L2,B2,R2,L2,F2,U,R2,L2',
      '2n3n0r1r': 'L2,R2,UP,L2,R2,B2,R2,L2,F2,U,R2,L2,FP,D2,F2,D,FP,DP,FP,D2,B,R,F,RP,BP',
      '2n3r0n1r': 'F,L,D,LP,DP,F2,RP,DP,R,D,F',
      '2n3r0r1n': 'R2,L2,UP,R2,L2,B2,R2,L2,F2,U,R2,L2,DP,R,FP,D,RP,F,BP,D,LP,F,DP,L,FP,B',

      '2r0n1n3r': 'B2,UP,FP,B,D2,F,BP,L2,U,B2,DP,B,RP,D,BP,R,LP,D,FP,R,DP,F,RP,L',
      '2r0n1r3n': 'L2,R,F,D,L2,FP,B,D2,F,BP,DP,FP,RP,L2',
      '2r0r1n3n': 'LP,BP,L,FP,B,D2,F,BP,L,B,L',
      '2r0r1r3r': 'LP,BP,L,B,FP,D2,BP,F,L,B,L,FP,D2,F2,D,FP,DP,FP,D2,B,R,F,RP,BP',

      '2r1n3n0r': 'F2,BP,LP,D,LP,R,F2,L,RP,D,L,B,F2',
      '2r1r3n0n': 'R2,UP,LP,R,D2,L,RP,B2,U,R2,BP,D2,B2,D,BP,DP,BP,D2,F,L,B,LP,FP',
      '2r1n3r0n': 'BP,RP,B,LP,R,D2,L,RP,B,R,B',
      '2r1r3r0r': 'BP,RP,B,LP,R,D2,L,RP,B,R,B,LP,D2,L2,D,LP,DP,LP,D2,R,F,L,FP,RP',

      '2r3n0n1r': 'L2,R2,UP,L2,R2,B2,R2,L2,F2,U,R2,L2,DP,F,LP,D,FP,RP,L,D,BP,L,DP,B,R,LP',
      '2r3n0r1n': 'L,DP,B,LP,RP,FP,D2,R2,D2,R2,D2,R2,F,L,R,BP,D,LP',
      '2r3r0n1n': 'R2,L2,UP,R2,L2,B2,R2,L2,F2,U,R2,L2,BP,D2,B2,D,BP,DP,BP,D2,F,L,B,LP,FP',
      '2r3r0r1r': 'FP,BP,RP,D2,B2,D2,B2,D2,B2,R,B,F',

      '3n0n2n1n': 'L2,UP,RP,L,D2,R,LP,F2,U,L2',
      '3n0n2r1r': 'L2,UP,L,RP,D2,LP,R,F2,U,L2,FP,D2,F2,D,FP,DP,FP,D2,B,R,F,RP,BP',
      '3n0r2n1r': 'FP,LP,F,RP,L,D2,R,LP,F,L,F',
      '3n0r2r1n': 'L2,UP,RP,L,D2,R,LP,F2,U,L2,DP,L,BP,D,LP,B,FP,D,RP,B,DP,R,BP,F',

      '3n1n0n2n': 'R2,UP,B2,LP,R,D2,L,RP,U,R2',
      '3n1n0r2r': 'BP,RP,BP,LP,R,D2,L,RP,BP,R,B',
      '3n1r0n2r': 'R2,UP,B2,LP,R,D2,L,RP,U,R2,LP,D2,L2,D,LP,DP,LP,D2,R,F,L,FP,RP',
      '3n1r0r2n': 'R2,UP,B2,LP,R,D2,L,RP,U,R2,DP,R,FP,D,RP,F,BP,D,LP,F,DP,L,FP,B',

      '3n2n1n0n': 'R2,L2,UP,R2,L2,D2,R2,L2,UP,R2,L2',
      '3n2n1r0r': 'LP,DP,FP,D2,F,L,D,B,D2,BP',
      '3n2r1n0r': 'BP,DP,LP,D2,L,B,D,R,D2,RP',
      '3n2r1r0n': 'R,FP,B,U,R,F2,B2,R2,F2,B2,L2,RP,UP,F,BP,RP',

      '3r0n2n1r': 'B2,FP,RP,D,RP,L,B2,R,LP,D,R,F,B2',
      '3r0n2r1n': 'L2,UP,RP,L,D2,R,LP,F2,U,L2,RP,D2,R2,D,RP,DP,RP,D2,L,B,R,BP,LP',
      '3r0r2n1n': 'F2,B,R,D,F2,RP,L,D2,R,LP,DP,RP,BP,F2',
      '3r0r2r1r': 'FP,LP,F,RP,L,D2,R,LP,F,L,F,RP,D2,R2,D,RP,DP,RP,D2,L,B,R,BP,LP',

      '3r1n0n2r': 'B2,F,L,D,LP,R,D2,L,RP,B2,DP,LP,B2,FP',
      '3r1n0r2n': 'F2,BP,LP,DP,LP,R,F2,L,RP,DP,L,B,F2',
      '3r1r0n2n': 'R2,UP,B2,LP,R,D2,L,RP,U,R2,BP,D2,B2,D,BP,DP,BP,D2,F,L,B,LP,FP',
      '3r1r0r2r': 'R2,UP,B2,LP,R,D2,L,RP,U,R2,R2,F2,R2,D2,R,LP,F,RP,L,D2,R2,F2,R2,D',

      '3r2n1n0r': 'B,RP,L,U,B,R2,L2,B2,R2,L2,F2,BP,UP,R,LP,BP',
      '3r2n1r0n': 'FP,DP,RP,D2,R,F,D,L,D2,LP',
      '3r2r1n0n': 'RP,DP,BP,D2,B,R,D,F,D2,FP',
      '3r2r1r0r': 'L,BP,LP,B2,R2,L2,UP,R2,L2,B2,R2,L2,F2,U,R2,L2,B2,L,B,LP',
    }
    const t = self.findEdges(['DB','DL','DR','DF']);
    console.log('F3C', t, map[t]);
    return map[t] || '';
  },
  solve(pp, c) {
    const search = c.split("");
    this.cube = pp;
    // 0: R
    // 1: L
    // 2: U
    // 3: D
    // 4: F
    // 5: B
    let solution = [];
    const moves = {
      "--U--F": "U2",
      "--F--U": "B,LP,D,F2",
      "-UF---": "L,F",
      "-FU---": "UP",
      "U-F---": "RP,FP",
      "F-U---": "U",
      "--U-F-": "", // ready
      "--F-U-": "F,RP,DP,F2",
      "-U---F": "L2,F",
      "-F---U": "LP,D,F2",
      "U----F": "R2,FP",
      "F----U": "R,DP,F2",
      "-U--F-": "F",
      "-F--U-": "L,D,F2",
      "U---F-": "FP",
      "F---U-": "RP,DP,F2",
      "---U-F": "D2,F2",
      "---F-U": "D,LP,F",
      "U--F--": "R,FP",
      "F--U--": "DP,F2",
      "---UF-": "F2",
      "---FU-": "DP,LP,F",
      "-U-F--": "LP,F",
      "-F-U--": "D,F2",
      //            RLUDFB
      "--U--L": "B2,D,L2",
      "--L--U": "B,L",
      "-UL---": "L2,D,F,LP,FP",
      "-LU---": "", //ready
      "U-L---": "R,BP,D,L2",
      "L-U---": "R2,D2,L2",
      "-U---L": "B,D,L2",
      "-L---U": "L",
      "U----L": "BP,D,L2",
      "L----U": "R,D2,L2",
      "-U--L-": "L2,B,D,L2",
      "-L--U-": "LP",
      "U---L-": "F,DP,FP,L2",
      "L---U-": "RP,D2,L2",
      "---U-L": "D,L2",
      "---L-U": "BP,L",
      "U--L--": "DP,F,LP,FP",
      "L--U--": "D2,L2",
      "---UL-": "DP,L2",
      "---LU-": "F,LP,FP",
      "-U-L--": "D,F,LP,FP",
      "-L-U--": "L2",
      //            RLUDFB
      "--U--B": "", // ready
      "--B--U": "B2,DP,RP,B",
      "U-B---": "R,B",
      "B-U---": "R2,D,B2",
      "-U---B": "BP",
      "-B---U": "LP,DP,L,B2",
      "U----B": "B",
      "B----U": "R,D,B2",
      "-U--B-": "FP,D2,F,B2",
      "-B--U-": "L,DP,LP,B2",
      "U---B-": "R2,B",
      "B---U-": "RP,D,B2",
      "---U-B": "B2",
      "---B-U": "DP,RP,B",
      "U--B--": "RP,B",
      "B--U--": "D,B2",
      "---UB-": "D2,B2",
      "---BU-": "D,RP,B",
      "-U-B--": "L,BP,LP",
      "-B-U--": "DP,B2",
      //            RLUDFB
      "U-R---": "RP,F,D,FP,R2",
      "R-U---": "", // ready
      "-U---R": "B,DP,BP,R2",
      "-R---U": "LP,D2,L,R2",
      "U----R": "BP,DP,B,R2",
      "R----U": "RP",
      "-U--R-": "FP,D,R2,F",
      "-R--U-": "L,D2,LP,R2",
      "U---R-": "RP,DP,FP,R,F",
      "R---U-": "R",
      "---U-R": "DP,R2",
      "---R-U": "B,RP,BP",
      "U--R--": "DP,FP,R,F",
      "R--U--": "R2",
      "---UR-": "D,R2",
      "---RU-": "FP,R,F",
      "-U-R--": "D,FP,R,F",
      "-R-U--": "D2,R2",
      //            RLUDFB
      "-U-LF-": "L,D,LP",
      "-L-FU-": "FP,DP,F",
      "-F-UL-": "FP,D2,F,D,FP,DP,F",
      "L--UF-": "F,DP,F2,D,F",
      "F--LU-": "DP,L,D,LP",
      "U--FL-": "DP,FP,DP,F",
      "-F-L-U": "FP,D,F",
      "-U-F-L": "D,FP,DP,F",
      "-L-U-F": "D,FP,D2,F,D,FP,DP,F",
      "L--F-U": "L,D2,LP",
      "F--U-L": "R,DP,RP,L,D,LP",
      "U--L-F": "D2,L,D,LP",
      "-UL--F": "LP,D2,L,DP,FP,DP,F",
      "-FU--L": "B,D,BP,FP,DP,F",
      "-LF--U": "B,D,BP,L,D,LP",
      "L-U--F": "BP,D,B,L,D2,LP",
      "F-L--U": "BP,D2,B,FP,DP,F",
      "U-F--L": "R,D2,RP,L,D,LP",
      "-FL-U-": "FP,DP,F,D,FP,DP,F",
      "-UF-L-": "L,D,LP,DP,L,D,LP",
      "-LU-F-": "", //readF
      "L-F-U-": "F,D,FP,D2,L,D,LP",
      "F-U-L-": "RP,DP,R,L,D,LP",
      "U-L-F-": "RP,DP,R,FP,DP,F",
      //            RLUDFB
      "-B-LU-": "B,DP,BP",
      "-L-UB-": "DP,B,D2,BP,DP,B,D,BP",
      "-U-BL-": "DP,B,D,BP",
      "L--BU-": "D2,B,D,BP",
      "B--UL-": "RP,D2,R,DP,LP,DP,L",
      "U--LB-": "D2,LP,DP,L",
      "-B-U-L": "B,D2,BP,DP,B,D,BP",
      "-U-L-B": "LP,DP,L",
      "-L-B-U": "B,D,BP",
      "L--U-B": "R,D2,RP,B,D,BP",
      "B--L-U": "D,LP,DP,L",
      "U--B-L": "D,B,D,BP",
      "-BL--U": "B,D2,BP,D2,B,D,BP",
      "-LU--B": "", // ready
      "-UB--L": "LP,DP,L,D,LP,DP,L",
      "L-B--U": "R,DP,RP,D,LP,DP,L",
      "B-U--L": "R,D,RP,LP,DP,L",
      "U-L--B": "R,D,RP,B,D,BP",
      "L-U-B-": "RP,D2,R,B,D,BP",
      "B-L-U-": "F,D2,FP,B,D,BP",
      "U-B-L-": "RP,D2,R,LP,DP,L",
      //            RLUDFB
      "-B-UR-": "D2,R,DP,RP,D2,R,D,RP",
      "-R-BU-": "D2,BP,DP,B",
      "-U-RB-": "D2,R,D,RP",
      "R--UB-": "RP,D,R,DP,BP,DP,B",
      "B--RU-": "D,R,D,RP",
      "U--BR-": "D,BP,DP,B",
      "-B-R-U": "DP,R,D,RP",
      "-U-B-R": "DP,BP,DP,B",
      "-R-U-B": "DP,R,DP,RP,D2,R,D,RP",
      "R--B-U": "BP,DP,B",
      "B--U-R": "R,DP,RP,D2,R,D,RP",
      "U--R-B": "R,D,RP",
      "R-U--B": "", //Ready
      "B-R--U": "BP,DP,B,D,BP,DP,B",
      "U-B--R": "R,D,RP,DP,R,D,RP",
      "R-B-U-": "F,D,FP,R,D,RP",
      "B-U-R-": "RP,DP,R,D2,R,D,RP",
      "U-R-B-": "RP,DP,R,D2,BP,DP,B",
      //            RLUDFB
      "-F-RU-": "D,RP,DP,R",
      "-R-UF-": "D,RP,D,R,D2,RP,DP,R",
      "-U-FR-": "D,F,D,FP",
      "R--FU-": "F,D,FP",
      "F--UR-": "F,DP,FP,D2,F,D,FP",
      "U--RF-": "RP,DP,R",
      "-F-U-R": "D2,F,DP,FP,D2,F,D,FP",
      "-U-R-F": "D2,RP,DP,R",
      "-R-F-U": "D2,F,D,FP",
      "R--U-F": "DP,F,DP,FP,D2,F,D,FP",
      "F--R-U": "DP,RP,DP,R",
      "U--F-R": "DP,F,D,FP",
      "R-U-F-": "", // ready
      "F-R-U-": "F,D,FP,DP,F,D,FP",
      "U-F-R-": "RP,DP,R,D,RP,DP,R",
      //            RLUDFB
      "-L--F-": "", //ready
      "-F--L-": "FP,DP,F,D,L,D,LP,D,FP,DP,F,D,L,D,LP",
      "L---F-": "F2,D2,F2,D2,F2",
      "F---L-": "RP,DP,R,D,F,D,FP,DP,L,DP,LP,DP,FP,D,F",
      "L----F": "BP,DP,B,D,R,D,RP,D2,L,DP,LP,DP,FP,D,F",
      "F----L": "BP,DP,B,D,R,D,RP,DP,FP,DP,F,D,L,D,LP",
      "-L---F": "L2,D2,L2,D2,L2",
      "-F---L": "LP,DP,L,D,B,D,BP,D,L,DP,LP,DP,FP,D,F",
      "L--F--": "D,FP,DP,F,D,L,D,LP",
      "F--L--": "L,DP,LP,DP,FP,D,F",
      "-L-F--": "DP,FP,DP,F,D,L,D,LP",
      "-F-L--": "D2,L,DP,LP,DP,FP,D,F",
      "---FL-": "D2,FP,DP,F,D,L,D,LP",
      "---LF-": "D,L,DP,LP,DP,FP,D,F",
      "---F-L": "FP,DP,F,D,L,D,LP",
      "---L-F": "DP,L,DP,LP,DP,FP,D,F",
      //            RLUDFB
      "---F-R": "F,DP,FP,DP,RP,D,R",
      "---R-F": "D,RP,DP,R,D,F,D,FP",
      "---FR-": "D2,F,DP,FP,DP,RP,D,R",
      "---RF-": "DP,RP,DP,R,D,F,D,FP",
      "-R-F--": "DP,F,DP,FP,DP,RP,D,R",
      "-F-R--": "RP,DP,R,D,F,D,FP",
      "R--F--": "D,F,DP,FP,DP,RP,D,R",
      "F--R--": "D2,RP,DP,R,D,F,D,FP",
      "R---F-": "", //ready
      "F---R-": "DP,RP,DP,R,D,F,D,FP,D,RP,DP,R,D,F,D,FP",
      "R----F": "R,D,RP,DP,BP,DP,B,D2,F,DP,FP,DP,RP,D,R",
      "F----R": "R,D,RP,DP,BP,DP,B,DP,RP,DP,R,D,F,D,FP",
      "-R---F": "LP,DP,L,D,B,D,BP,D2,F,DP,FP,DP,RP,D,R",
      "-F---R": "LP,DP,L,D,B,D,BP,DP,RP,DP,R,D,F,D,FP",
      //            RLUDFB
      "---B-R": "D2,BP,DP,B,D,R,D,RP",
      "---R-B": "D,R,DP,RP,DP,BP,D,B",
      "---BR-": "BP,DP,B,D,R,D,RP",
      "---RB-": "DP,R,DP,RP,DP,BP,D,B",
      "-R-B--": "D,BP,DP,B,D,R,D,RP",
      "-B-R--": "R,DP,RP,DP,BP,D,B",
      "R--B--": "DP,BP,DP,B,D,R,D,RP",
      "B--R--": "D2,R,DP,RP,DP,BP,D,B",
      "R----B": "", //ready
      "B----R": "BP,DP,B,D,R,D,RP,D,BP,DP,B,D,R,D,RP",
      "-R---B": "B,D,BP,DP,LP,DP,L,D2,R,DP,RP,DP,BP,D,B",
      "-B---R": "LP,DP,L,D,B,D,BP,DP,R,DP,RP,DP,BP,D,B",
      //            RLUDFB
      "---B-L": "D2,B,DP,BP,DP,LP,D,L",
      "---L-B": "DP,LP,DP,L,D,B,D,BP",
      "---BL-": "B,DP,BP,DP,LP,D,L",
      "---LB-": "D,LP,DP,L,D,B,D,BP",
      "-L-B--": "D,B,DP,BP,DP,LP,D,L",
      "-B-L--": "D2,LP,DP,L,D,B,D,BP",
      "L--B--": "DP,B,DP,BP,DP,LP,D,L",
      "B--L--": "LP,DP,L,D,B,D,BP",
      "-L---B": "", // ready
      "-B---L": "B,D,BP,DP,LP,DP,L,DP,B,DP,BP,DP,LP,D,L",
      //            RLUDFB
      "-L-F-D": "D", // 4
      "-D-L-F": "D",
      "-F-D-L": "D",
      "L--D-F": "D2", // 5
      "D--F-L": "D2",
      "F--L-D": "D2",
      "L--FD-": "DP", // 7
      "D--LF-": "DP",
      "F--DL-": "DP",
      "-L-DF-": "", // ready 6
      "-D-FL-": "",
      "-F-LD-": "",
    };

    if (search[0] == "3") return this["f" + c](this, pp);
    const t =
      search.length > 2 ? this.findCorner(search).c : this.findEdge(search).c;
    const mm = moves[t];

    return mm || "";
  },
};
</script>
