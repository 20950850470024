<template lang="pug">
	.button(@click="click($event)",:class="{ disabled: disabled }")
		slot
</template>

<script>
export default {
	props: ['disabled'],
	methods: {
		click(event) {
			if (!this.disabled)
				this.$emit("click", event);
		}
	}
}
</script>

<style lang="less" scoped>
	@import (reference) "../shared.less";

	.button {
		cursor: pointer;
		display: flex;
		align-items:center;
		text-decoration: none;
		color: #000;
		font-weight: bold;
		background: linear-gradient(0deg, #ccc, #fff);
		padding: 0.3rem 0.75rem;
		font-size: 1.2rem;
		border: 1px solid #888;
		-webkit-border-radius: 0.5rem;
		-moz-border-radius: 0.5rem;
		border-radius: 0.5rem;
		box-shadow: #777 0.1rem 0.1rem 0.2rem;
	}

	.button:hover {
		background: linear-gradient(0deg, #888, #ccc);
	}

	.button:active {
		box-shadow: none;
		background: linear-gradient(180deg, #ccc, #fff);
		-webkit-transform: translate(0, 2px);
		-moz-transform: translate(0, 2px);
		transform: translate(0, 2px);
		-webkit-transition: all .1s ease-in-out;
		-moz-transition: all .1s ease-in-out;
		transition: all .1s ease-in-out;
	}
	.button.selected {
		color:#0c0;
		border-width: 3px;
		border-color: black;
	}
	.button.disabled {
		color:#ddd;
		cursor: default;
	}
</style>