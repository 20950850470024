<template lang="pug">
div(style="flex: 1 0; display: flex; flex-flow: column")
  div(style="display: flex; flex: 0 0 auto; flex-flow: row wrap; padding: 0")
    div(style="flex: 0 0 auto; display: flex; flex-flow: column")
      div Size {{ size }}
      input.slider(
        type="range",
        min="3",
        max="10",
        value="7",
        v-model="size",
        :disabled="playing",
        @change="updateScene()"
      )
    div(style="flex: 0 0 auto; display: flex; flex-flow: column")
      div Speed {{ speed }}
      input.slider(
        type="range",
        min="1",
        max="100",
        value="50",
        v-model="speed"
      )
    button3d(@click="solve()", style="margin: 1rem", :disabled="playing") Solve
  #container(style="display: flex; flex: 1 0")
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import * as T from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
export default {
  components: { Icon, Button3d },
  data() {
    return {
      background: null,
      playing: null,
      size: 7,
      speed: 50,
      newspeed: 50,
      moves: [],
      stacks: [
        { x: 0, y: 0, items: [] },
        { x: -3, y: 0, items: [] },
        { x: 3, y: 0, items: [] },
      ],
    };
  },
  async mounted() {
    const urls = [
      "img/pisa/px.png",
      "img/pisa/nx.png",
      "img/pisa/py.png",
      "img/pisa/ny.png",
      "img/pisa/pz.png",
      "img/pisa/nz.png",
    ];

    this.background = new T.CubeTextureLoader().load(urls);

    this.materials = [
      new T.MeshStandardMaterial({
        color: "#4444ff",
        specular: "#dddddd",
        shininess: 50,
        side: T.DoubleSide,
        envMap: this.background,
        metalness: 0.5,
        roughness: 0.1
      }),
      new T.MeshStandardMaterial({
        color: "#ff8888",
        specular: "#dddddd",
        shininess: 50,
        side: T.DoubleSide,
        envMap: this.background,
        metalness: 0.5,
        roughness: 0.1
      }),
      new T.MeshStandardMaterial({
        color: "#cccc00",
        specular: "#dddddd",
        shininess: 50,
        side: T.DoubleSide,
        envMap: this.background,
        metalness: 0.5,
        roughness: 0.1
      }),
      new T.MeshStandardMaterial({
        color: "#ff88ff",
        specular: "#dddddd",
        shininess: 50,
        side: T.DoubleSide,
        envMap: this.background,
        metalness: 0.5,
        roughness: 0.1
      }),
      new T.MeshStandardMaterial({
        color: "#ff8800",
        specular: "#dddddd",
        shininess: 50,
        side: T.DoubleSide,
        envMap: this.background,
        metalness: 0.5,
        roughness: 0.1
      }),
      new T.MeshStandardMaterial({
        color: "#dddddd",
        specular: "#dddddd",
        shininess: 50,
        side: T.DoubleSide,
        envMap: this.background,
        metalness: 0.5,
        roughness: 0.1
      }),
      new T.MeshStandardMaterial({
        color: "#8800ff",
        specular: "#dddddd",
        shininess: 50,
        side: T.DoubleSide,
        envMap: this.background,
        metalness: 0.5,
        roughness: 0.1
      }),
      new T.MeshStandardMaterial({
        color: "#ff0088",
        specular: "#dddddd",
        shininess: 50,
        side: T.DoubleSide,
        envMap: this.background,
        metalness: 0.5,
        roughness: 0.1
      }),
      new T.MeshStandardMaterial({
        color: "#88ff00",
        specular: "#dddddd",
        shininess: 50,
        side: T.DoubleSide,
        envMap: this.background,
        metalness: 0.5,
        roughness: 0.1
      }),
      new T.MeshStandardMaterial({
        color: "#88ff88",
        specular: "#dddddd",
        shininess: 50,
        side: T.DoubleSide,
        envMap: this.background,
        metalness: 0.5,
        roughness: 0.1
      }),
    ];
    this.setCanvas();
    this.m = 0;
    this.phase = 0;
    this.animate();
  },
  methods: {
    createItem(x, y, r, z, m) {
      const points = [
        new T.Vector2(0.06, -0.1),
        new T.Vector2(0.05, -0.1),
        new T.Vector2(0.05, 0.1),
        new T.Vector2(0.06, 0.1),
      ];
      for (let i = 0; i < 64; i++)
        points.push(
          new T.Vector2(
            r + 0.1 * Math.sin((i * Math.PI) / 64),
            0.1 * Math.cos((i * Math.PI) / 64)
          )
        );
      points.push(new T.Vector2(0.05, -0.1));
      const geometry = new T.LatheGeometry(points, 128);
      const lathe = new T.Mesh(geometry, m);
      lathe.position.x = x;
      lathe.position.y = z;
      lathe.position.z = y;
      this.scene.add(lathe);
      return lathe;
    },
    addStack(x, y, m) {
      const r = 0.1 * this.size + 0.25;
      const torus = new T.TorusGeometry(r, 0.025, 16, 64);
      const mesh0 = new T.Mesh(torus, m);
      mesh0.position.y = -0.11;
      mesh0.rotation.x = (Math.PI * 90) / 180;

      const cylinder = new T.CylinderGeometry(r, r, 0.05, 64);
      const mesh = new T.Mesh(cylinder, m);
      mesh.position.y = -0.11;

      const pin1 = new T.CylinderGeometry(
        0.05,
        0.05,
        this.size * 0.2 + 0.1,
        64
      );
      const mesh1 = new T.Mesh(pin1, m);
      mesh1.position.y = this.size * 0.1 - 0.05;

      const sphere = new T.SphereGeometry(0.05);
      const mesh2 = new T.Mesh(sphere, m);
      mesh2.position.y = this.size * 0.2;

      const group = new T.Group();
      group.add(mesh0);
      group.add(mesh);
      group.add(mesh1);
      group.add(mesh2);
      group.position.x = x;
      group.position.z = y;
      this.scene.add(group);
      return group;
    },
    createBody() {
      const m = new T.MeshStandardMaterial({
        color: "#ff0000",
        specular: "#dddddd",
        shininess: 50,
        metalness: 0.5,
        roughness: 0.001,
        envMap: this.background
      });
      this.stacks.forEach((s, ix) => {
        s.x = (ix - 1) * (0.5 + this.size * 0.25);
        s.y = 0;
        s.items = [];
        s.pin = this.addStack(s.x, s.y, m);
      });
    },
    createTowers() {
      this.createBody();
      let r = 0.1 + this.size * 0.1;
      let z = 0.0;
      const stack = this.stacks[0];
      const x = stack.x;
      const y = stack.y;
      for (let i = 0; i < this.size; i++) {
        this.stacks[0].items.push(
          this.createItem(stack.x, stack.y, r, z, this.materials[i])
        );
        r -= 0.1;
        z += 0.2;
      }
    },
    updateScene() {
      this.stacks.forEach((s) => {
        s.items.forEach((i) => this.scene.remove(i));
        s.items = [];
        this.scene.remove(s.pin);
      });
      this.scene;
      this.createTowers();
      this.camera.up.set(0, 1, 0);
      this.camera.position.x = -1 - this.size * 0.2;
      this.camera.position.y = this.size * 0.3;
      this.camera.position.z = 1 + this.size * 0.7;

      this.controls.target = new T.Vector3(0, this.size * 0.1, 0);
      this.controls.saveState();
    },
    setCanvas() {
      let map = document.getElementById("container");
      let mapDimensions = map.getBoundingClientRect();
      this.width = mapDimensions.width;
      this.height = mapDimensions.height;

      //   this.width = window.innerWidth;
      //   this.height = window.innerHeight;

      // Renderer
      this.renderer = new T.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(1);
      this.renderer.setSize(this.width, this.height);
      //   document
      //     .getElementById("container")
      map.appendChild(this.renderer.domElement);

      this.scene = new T.Scene();

      const light = new T.HemisphereLight(0xffffff, 0xdddddd, 1);
      light.position.set(-1, 6, 6);
      this.scene.add(light);

      var light1 = new T.DirectionalLight(0xffffff);
      light1.position.set(3, 6, 1);
      this.scene.add(light1);

      this.camera = new T.PerspectiveCamera(
        30,
        this.width / this.height,
        0.1,
        1000
      );

      this.scene.add(this.camera);

      this.renderer.render(this.scene, this.camera);
      this.renderer.shadowMap.enabled = false;
      // Controls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      this.controls.enableDamping = true;

      this.updateScene();
    },
    play() {
      const steps = Math.round(26 - 0.25 * this.speed);
      const p = this.playing;
      const m = this.moves[p.move];
      const stack = this.stacks[m.f];
      const item = stack.items[stack.items.length - 1];
      switch (p.phase) {
        case 0: // up
          if (p.step < 1) {
            p.saved = {
              position: item.position,
            };
          }
          if (p.step > steps) {
            p.phase++;
            p.step = 0;
          } else {
            const h = this.size * 0.2 + 0.2;
            item.position.y =
              p.saved.position.y + ((h - p.saved.position.y) * p.step) / steps;
            p.step++;
          }
          break;
        case 1: // move
          if (p.step < 1) {
            p.saved = { position: item.position };
          }
          if (p.step > steps) {
            p.phase++;
            p.step = 0;
          } else {
            const target = this.stacks[m.t];
            const x = stack.x + ((target.x - stack.x) * p.step) / steps;
            item.position.x = x;
            p.step++;
          }
          break;
        case 2: //down
          const target = this.stacks[m.t];
          const maxz = target.items.length * 0.2;
          if (p.step < 1) {
            p.saved = { position: item.position };
          }
          if (p.step > steps) {
            p.phase++;
            p.step = 0;
          } else {
            const z =
              p.saved.position.y +
              ((maxz - p.saved.position.y) * p.step) / steps;
            item.position.y = z;
            p.step++;
          }
          break;
        case 3:
          stack.items.pop();
          const t = this.stacks[m.t];
          t.items.push(item);
          p.move++;
          if (p.move >= this.moves.length) {
            this.playing = null;
          } else {
            p.phase = 0;
            p.step = 0;
          }
          break;
      }
    },
    animate() {
      this.playing && this.play();
      this.controls.update();
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
    moveItem(i, f, t) {
      this.moves.push({ i, f, t });
    },
    moveTower(first, f, t, x) {
      if (first < this.size) {
        this.moveTower(first + 1, f, x, t);
        this.moveItem(first, f, t);
        this.moveTower(first + 1, x, t, f);
      }
    },
    solve() {
      if (this.playing) return;
      this.moves = [];
      const from = this.stacks.findIndex((s) => s.items.length > 0);
      let to = from < 2 ? from + 1 : 0;
      let temp = to < 2 ? to + 1 : 0;
      this.moveTower(0, from, to, temp);
      this.start();
    },
    start() {
      this.playing = { move: 0, phase: 0, step: 0 };
    },
  },
};
</script>