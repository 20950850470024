<template lang="pug">
div(style="display: flex; flex-flow: column; flex: 1 0")
  h1 Media
  div(style="display: flex; flex-flow: row; overflow: auto")
    .mediabox
        media-filter(@search="search")
        pager(:page="page",:pages="pages",@change="selectPage")
        div(style="margin:0.5rem 0;flex:1 0;display:flex;flex-flow:column;overflow:auto;align-items:stretch;")
            .mediaitem(v-for="i in items", :class="{ 'selected': i.id == item.id }", @click="load(i.id)") {{ i.name }}
    div(style="align-items: stretch; flex: 1 0; display:flex;flex-flow:row;")
        
        div(v-if="itype=='unknown'",style="flex:2 0;display:flex;flex-flow:column;margin:0.5rem;")
        div(v-if="itype=='video'",style="flex:2 0;display:flex;flex-flow:column;margin:0.5rem;")
            video.videoPanel(autoplay,controls,:src="image",preload="metadata") Your browser does not support the video element
        div(v-if="itype=='image'",style="flex:2 0;display:flex;flex-flow:column;")
            .content(:style="{ 'background-image': 'url(\\'' + image + '\\')' }")
            div {{center}} {{item}}
        div(style="flex:1 0;")
            GmapMap(:center="center", :zoom="zoom", @bounds_changed="boundsChanged($event)" style="margin:1rem; height: 400px")
                GmapMarker(:key="index",v-for="(m, index) in markers",:position="m.position")
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
import { gmapApi } from 'vue2-google-maps' 
import Icon from "@/components/Icon.vue";
import MediaFilter from '@/components/MediaFilter.vue';
import Pager from '@/components/Pager.vue';

export default {
  name: "Media",
  components: { Icon, MediaFilter, Pager },
  data() {
    return {
      center: { lat: 54.153796, lng: 10.945332 },
      zoom: 13,
      markers: [],
      image: null,
      itype: "unknown"
    };
  },
  computed: {
    ...mapGetters({
        page: "media/page",
        pages: "media/pages",
        total: "media/total",
        items: "media/items",
        item: "media/item",
        content: "media/content",
    }),
    google: gmapApi
  },
  methods: {
    ...mapActions({
        clear: 'media/clear',
        list: "media/list",
        setPage: 'media/page',
        getItem: "media/item",
        getContent: "media/content",
    }),
    selectPage: function(page) {
        console.log('PAGE', page);
        this.setPage(page);
        this.loadPage();
    },
    setItemType: function (mime) {
      const types = [
        { mime: "image/jpeg", t: "image" },
        { mime: "image/png", t: "image" },
        { mime: "video/mp4", t: "video" },
        { mime: "video/mpeg", t: "video" },
      ];
      const t = types.find((x) => x.mime == mime);
      if (t) return t.t;
      return "unknown";
    },
    search: function(filter) {
        this.filter = filter;
        this.setPage(1);
        this.loadPage();
    },
    loadPage: function() {
        // this.clear();
        this.list(this.filter)
    },
    load: function (id) {
      this.getItem(id).then(() => {
        console.log("loaded", this.item);
        if (this.item.latitude && this.item.longitude) {
          this.center = { lat: this.item.latitude, lng: this.item.longitude };
          this.zoom = 17;
          this.markers = [{ position: this.center }];
          return;
        }
        this.zoom = 3;
        this.center = { lat: 0, lng: 0 };
      });
      this.getContent(id).then(() => {
        console.log("MIME", this.item.mime);
        this.itype = this.setItemType(this.item.mime);
        var blobObj = new Blob([this.content], { type: this.item.mime });
        const url = URL.createObjectURL(blobObj);
        this.image = url;
      });
      console.log('GOOGLE',this.google.maps);
    },
    boundsChanged(e) {
        // e && e.Ka && e.Va && (this.filter.rect = [e.Ka.lo, e.Va.lo, e.Ka.hi, e.Va.hi]);
        console.log('bounds changed', e, e && e.Ka, e && e.Va);
    }
  },
  mounted() {
    this.zoom = 2;
    this.center = { lat: 0, lng: 0 };
    moment.locale("de");
    
    this.list(this.filter);
    
  },
};
</script>

<style lang="less" scoped>
.content {
  flex: 1 0;
  margin: 0.5rem;
  background-color: #eee;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.selected {
  color: red;
}

.videoPanel {
  background-color: #eee;
  max-width: 100%;
  flex: 1 0;
  max-height: -webkit-fill-available;
}

.mediabox {
  display: flex;
  flex-flow: column;
  width: 20rem;
  padding: 0.5rem;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: stretch;
}

.mediaitem {
  flex: 0 0 auto;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0.2rem 0;
  cursor: pointer;
}
</style>