<template lang="pug">
  div
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { nextTick } from "vue";
import * as moment from "moment";

export default {
  name: "PentominoSolver",
  // render() {},
  data() {
    return {
      board: [
        ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
        ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"],
      ],
      pieces: [
        {
          id: "F",
          layout: [
            [0, 0],
            [1, -2],
            [1, -1],
            [1, 0],
            [2, -1],
          ],
          rotate: [0, 1, 2, 3],
          mirror: true,
        },
        {
          id: "I",
          layout: [
            [0, 0],
            [1, 0],
            [2, 0],
            [3, 0],
            [4, 0],
          ],
          rotate: [0, 1],
        },
        {
          id: "L",
          layout: [
            [0, 0],
            [1, 0],
            [2, 0],
            [3, 0],
            [3, -1],
          ],
          rotate: [0, 1, 2, 3],
          mirror: true,
        },
        {
          id: "N",
          layout: [
            [0, 0],
            [1, 0],
            [2, 0],
            [2, -1],
            [3, -1],
          ],
          rotate: [0, 1, 2, 3],
          mirror: true,
        },
        {
          id: "P",
          layout: [
            [0, 0],
            [0, -1],
            [0, -2],
            [1, 0],
            [1, -1],
          ],
          rotate: [0, 1, 2, 3],
          mirror: true,
        },
        {
          id: "T",
          layout: [
            [0, 0],
            [1, 0],
            [1, -1],
            [1, -2],
            [2, 0],
          ],
          rotate: [0, 1, 2, 3],
        },
        {
          id: "U",
          layout: [
            [0, 0],
            [0, -1],
            [1, -1],
            [2, -1],
            [2, 0],
          ],
          rotate: [0, 1, 2, 3],
        },
        {
          id: "V",
          layout: [
            [0, 0],
            [1, 0],
            [2, 0],
            [2, -1],
            [2, -2],
          ],
          rotate: [0, 1, 2, 3],
        },
        {
          id: "W",
          layout: [
            [0, 0],
            [1, 0],
            [1, -1],
            [2, -1],
            [2, -2],
          ],
          rotate: [0, 1, 2, 3],
        },
        {
          id: "X",
          layout: [
            [0, 0],
            [0, -1],
            [0, -2],
            [-1, -1],
            [1, -1],
          ],
          rotate: [0],
        },
        {
          id: "Y",
          layout: [
            [0, 0],
            [1, 0],
            [2, 0],
            [3, 0],
            [2, -1],
          ],
          rotate: [0, 1, 2, 3],
          mirror: true,
        },
        {
          id: "Z",
          layout: [
            [0, 0],
            [1, 0],
            [1, -1],
            [1, -2],
            [2, -2],
          ],
          rotate: [0, 1],
          mirror: true,
        },
      ],
      shapes: {},
    };
  },
  mounted() {
    this.init();
    // this.solve();
  },
  methods: {
    ...mapActions({
      add: "pentomino/add",
    }),
    rotated(p, a) {
      const np = { id: p.id, layout: [], rotated: a, mirrored: p.mirrored };
      switch (a) {
        case 0:
          np.layout = p.layout;
          break;
        case 1:
          for (const t of p.layout) {
            const y = -t[1];
            const x = t[0];
            np.layout.push([y, x]);
          }
          break;
        case 2:
          for (const t of p.layout) {
            const y = -t[0];
            const x = -t[1];
            np.layout.push([y, x]);
          }
          break;
        case 3:
          for (const t of p.layout) {
            const y = t[1];
            const x = -t[0];
            np.layout.push([y, x]);
          }
          break;
      }
      return np;
    },
    mirrored(p) {
      const lay = [];
      p.layout.forEach((t) => lay.push([t[0], -t[1]]));
      return { id: p.id, layout: lay, mirrored: true };
    },
    moved(p, x, y) {
      const layout = [];
      p.layout.forEach((t) => layout.push([t[0] + y, t[1] + x]));
      return { ...p, layout, x, y };
    },
    findEmpty(b) {
      let y = -1;
      let x = -1;
      const t = this.board.find((row, cy) => {
        const t1 = row.find((p, cx) => {
          if (p != "0") return false;
          y = cy;
          x = cx;
          return true;
        });
        return t1;
      });
      return { x, y };
    },
    mark(b, x, y) {
      b[y][x] = "*";
      return 1;
    },
    clean(b) {
      b.forEach((row) => {
        row.forEach((c, x) => c == "*" && (row[x] = "0"));
      });
    },
    fill(b, x, y) {
      let n = this.mark(b, x, y);
      if (b[y + 1] && b[y + 1][x] == "0") {
        n += this.fill(b, x, y + 1);
      }
      if (b[y - 1] && b[y - 1][x] == "0") {
        n += this.fill(b, x, y - 1);
      }
      if (b[y][x + 1] && b[y][x + 1] == "0") {
        n += this.fill(b, x + 1, y);
      }
      if (b[y][x - 1] && b[y][x - 1] == "0") {
        n += this.fill(b, x - 1, y);
      }
      return n;
    },
    smallest(b) {
      let min = 99;
      while (min >= 5) {
        const { x, y } = this.findEmpty(b);
        if (x < 0) {
          this.clean(b);
          return min;
        }
        const n = this.fill(b, x, y);
        if (n < min) min = n;
        if (n < 5) {
          this.clean(b);
          return min;
        }
      }
      return min;
    },
    fit(p, x, y, b) {
      const t = p.layout.find((t) => {
        const y1 = y + t[0];
        const x1 = x + t[1];
        if (y1 < 0 || x1 < 0) return "*";
        if (y1 >= b.length || x1 >= b[y1].length) return "*";
        return b[y1][x1] != "0";
      });
      return !t;
    },
    put(p, x, y, b) {
      for (const t of p.layout) {
        b[y + t[0]][x + t[1]] = p.id;
      }
    },
    remove(p, x, y, b) {
      for (const t of p.layout) {
        b[y + t[0]][x + t[1]] = "0";
      }
    },
    scan(pn) {
      this.shapes[pn.id] = this.shapes[pn.id] || { id: pn.id, layouts: [] };
      for (let y = 0; y < this.board.length; y++) {
        const row = this.board[y];
        for (let x = 0; x < row.length; x++) {
          if (this.fit(pn, x, y, this.board)) {
            this.put(pn, x, y, this.board);
            const n = this.smallest(this.board);
            if (n >= 5) {
              this.shapes[pn.id].layouts.push(this.moved(pn, x, y));
            }
            this.remove(pn, x, y, this.board);
          }
        }
      }
    },

    async init() {
      for (const p of this.pieces) {
        for (const a of p.rotate) {
          const pn = this.rotated(p, a);
          this.scan(pn);
        }
        if (p.mirror) {
          const pm = this.mirrored(p);
          for (const a of p.rotate) {
            const pn = this.rotated(pm, a);
            this.scan(pn);
          }
        }
      }
    },
    async solve() {
      const t0 = moment();
      const shapes = Object.keys(this.shapes).map((x) => this.shapes[x]);
      shapes.sort((a, b) => a.layouts.length - b.layouts.length);
      let stack = [];
      let si = 0;
      let li = 0;
      let count = 0;
      do {
        if (si > 11) {
          console.log("ready", moment().diff(t0));
          this.$emit("ready");
          return;
        }
        let shp = shapes[si];
        let p = shp.layouts[li];
        if (!p) {
          console.log("ready.");
          return;
        }
        let f = this.fit(p, 0, 0, this.board);
        // this.$nextTick();
        if (f) {
          this.put(p, 0, 0, this.board);
          if (si == 11) {
            stack.unshift({ si, li });
            count++;
            const s = stack.map((s) => {
              return shapes[s.si].layouts[s.li];
            });
            // this.add({ count, stack:s });
            this.$emit("solved", {
              count,
              stack: s,
            });
            this.$store.dispatch("pentomino/add", { count, stack: s });

            if (count >= 100) {
              console.log("ready", moment().diff(t0));
              this.$emit("ready");
              return;
            }

            f = false;
            li = 999;
            this.remove(p, 0, 0, this.board);
          } else {
            const s = this.smallest(this.board);
            if (s % 5) {
              this.remove(p, 0, 0, this.board);
              f = false;
            } else {
              stack.unshift({ si, li });
              si++;
              li = 0;
            }
          }
        }

        if (!f) {
          // this.$nextTick();
          let t = null;
          li++;
          while (li >= shp.layouts.length && stack.length > 0) {
            t = stack.shift();
            if (!t) {
              console.log("ready");
              return;
            }
            si = t.si;
            li = t.li;
            shp = shapes[si];
            p = shp.layouts[li];
            this.remove(p, 0, 0, this.board);
            li++;
          }
        }
      } while (1);
    },
  },
};
</script>
