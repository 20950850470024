"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Smoke = void 0;
const api_js_1 = require("@/api.js");
const state = {
    items: {}
};
const mutations = {
    list(state, items) {
        const t = {};
        items.forEach(i => {
            t[i.person] = t[i.person] || { count: 0, times: [] };
            t[i.person].times.push({ id: i.id, stamp: i.stamp });
            t[i.person].count++;
        });
        state.items = t;
    },
};
const getters = {
    items: (state) => state.items
};
const actions = {
    async list({ commit }) {
        const res = await api_js_1.default.tools.smoked();
        commit('list', res.data);
    },
    async add({ commit }, person) {
        await api_js_1.default.tools.smoke(person);
    }
};
exports.Smoke = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
