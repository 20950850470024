<template lang="pug">
.container
  button3d(
      v-for="d in demos"
      @click="goto(d.target)",
      style="width: 20rem; margin: 0.5rem; flex: 0 0 auto"
  )
      div(style="display: flex; flex: 1 0; align-items: center")
          img(:src="d.src")
          div(style="flex: 1 0; display: flex; justify-content: center") {{d.name}}
//-   #container(style="display: flex; flex: 1 0; position: relative")
</template>

<script>
import Button3d from "@/components/Button3d.vue";
import Stats from "stats.js";
import * as T from "three";
import { SVGLoader } from "three/examples/jsm/loaders/SVGLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { RoundedBoxGeometry } from "three/examples/jsm/geometries/RoundedBoxGeometry.js";
export default {
  components: { Button3d },
  data() {
    return {
      demos: [
        { target: "rubik", src: "/img/rubik.jpg", name: "Rubik's Cube" },
        { target: "pentomino", src: "/img/pentomino.jpg", name: "Pentomino" },
        { target: "towers", src: "/img/towers.jpg", name: "Towers of Hanoi" },
        { target: "sokoban", src: "/img/sokoban.jpg", name: "Sokoban" },
        { target: "piano", src: "/img/piano.jpg", name: "Piano" },
        { target: "chess", src: "/img/chess.jpg", name: "chess" },
        { target: "tetris", src: "/img/chess.jpg", name: "tetris" },
      ],
      scene: null,
      controls: null,
      renderer: null,
      textureLoader: null,
      current: null,
      info: null,
      stats: null,
    };
  },
  async mounted() {
    // this.stats = new Stats();
    // this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    // document.body.appendChild(this.stats.dom);
    // this.raycaster = new T.Raycaster();

    // this.textureLoader = new T.TextureLoader();
    // this.t1 = this.textureLoader.load("/menu/rubik1.png");
    // this.t2 = this.textureLoader.load("/menu/rubik2.png");
    // this.t3 = this.textureLoader.load("/menu/rubik3.png");
    // const loader = new T.CubeTextureLoader();
    // loader.setCrossOrigin("");
    // loader.setPath("/img/cube/SaintLazarusChurch2/");
    // this.textureCube = await loader.load([
    //   "posx.jpg",
    //   "negx.jpg",
    //   "posy.jpg",
    //   "negy.jpg",
    //   "posz.jpg",
    //   "negz.jpg",
    // ]);
    // this.setCanvas();
    // this.animate();
  },
  methods: {
    onDocumentMouseMove(event) {
      var mouse = new T.Vector2();

      event.preventDefault();

      mouse.x = (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
      mouse.y =
        -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
      this.raycaster.setFromCamera(mouse, this.camera);
      var intersects = this.raycaster.intersectObjects(this.scene.children);
      this.info = intersects.length;
      if (intersects.length > 0) {
        if (this.current != intersects[0].object) {
          if (this.current) {
            this.current.material[2].metalness = 1;
            this.current.material[2].roughness = 0.001;
            this.current.material[2].color = new T.Color(0xffffff);
          }

          this.current = intersects[0].object;
          this.current.c = this.current.material[2].color;
          this.current.m = this.current.material[2].metalness;
          this.current.r = this.current.material[2].roughness;
          this.current.material[2].metalness = 0;
          this.current.material[2].roughness = 1;
          this.current.material[2].color = new T.Color(0x80ff80);
        }
      } else {
        if (this.current) {
          this.current.material[2].metalness = 1;
          this.current.material[2].roughness = 0.001;
          this.current.material[2].color = new T.Color(0xffffff);
        }
        this.current = null;
      }
    },
    onDocumentMouseDown(event) {
      var mouse = new T.Vector2();

      event.preventDefault();

      mouse.x = (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
      mouse.y =
        -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;

      this.raycaster.setFromCamera(mouse, this.camera);
      var intersects = this.raycaster.intersectObjects(this.scene.children);
      if (intersects.length > 0) {
        this.goto(intersects[0].object.name);
      }
    },
    goto(t) {
      this.$router.push({ name: t });
    },
    addCube(scene, x, y, z, name, target) {
      const geometry = new RoundedBoxGeometry(160, 2, 25.6, 8, 0.5);
      geometry.translate(x, y, z);

      const m = new T.MeshStandardMaterial({
        color: 0x000000,
        envMap: this.textureCube,
        metalness: 1,
        roughness: 0.0001,
      });
      // const t1 = this.textureLoader.load(`/menu/${name}1.png`);
      const t2 = this.textureLoader.load(`/menu/${name}.png`);
      // const t3 = this.textureLoader.load(`/menu/${name}3.png`);
      const material = new T.MeshStandardMaterial({
        color: 0xffffff,
        map: t2,
        envMap: this.textureCube,
        metalness: 1,
        // metalnessMap: t1,
        roughness: 0.0001,
      });
      const mesh = new T.Mesh(geometry, [m, m, material, m, m, m]);
      mesh.name = name;
      mesh.target = target;
      scene.add(mesh);
      return mesh;
    },
    async setCanvas() {
      let map = document.getElementById("container");
      let mapDimensions = map.getBoundingClientRect();
      this.width = mapDimensions.width;
      this.height = mapDimensions.height;

      map.addEventListener("click", this.onDocumentMouseDown, false);
      map.addEventListener("mousemove", this.onDocumentMouseMove, false);

      this.scene = new T.Scene();
      this.scene.background = this.textureCube;

      const light = new T.HemisphereLight(0xffffff, 0xffffff, 1);
      light.position.set(0, 100, 0);
      this.scene.add(light);

      var light1 = new T.DirectionalLight(0xffffff);
      light1.position.set(10, 60, -10); //.normalize();
      this.scene.add(light1);
      var light2 = new T.PointLight(0xffffff, 2);
      light2.position.set(0, 30, -50).normalize();
      this.scene.add(light2);

      this.camera = new T.PerspectiveCamera(
        30,
        this.width / this.height,
        0.1,
        50000
      );
      this.camera.up.set(0, 1, 0);
      this.camera.position.x = -10;
      this.camera.position.y = 200;
      this.camera.position.z = 300;
      this.scene.add(this.camera);

      this.addCube(this.scene, 0, 0, 0, "rubik", "rubik");
      this.addCube(this.scene, 0, 0, 30, "pentomino", "pentomino");
      this.addCube(this.scene, 0, 0, 60, "towers", "towers");
      this.addCube(this.scene, 0, 0, 90, "sokoban", "sokoban");
      this.addCube(this.scene, 0, 0, 120, "piano", "piano");
      this.addCube(this.scene, 0, 0, 150, "chess", "chess");

      this.renderer = new T.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(1);
      this.renderer.setSize(this.width, this.height);
      map.appendChild(this.renderer.domElement);
      this.renderer.render(this.scene, this.camera);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true;
      this.controls.target = new T.Vector3(0, 0, 90);
      this.controls.saveState();
    },
    animate() {
      this.stats.begin();
      this.controls.update();
      this.stats.end();
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  flex: 1 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}
.grid {
  margin: 1rem;
  flex: 1 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(4fr);
  align-items: stretch;
  gap: 0.5rem;
  overflow: auto;
}
button3d {
  font-size: 1.8rem;
}
</style>