<template lang="pug">
.icon(v-html="svg", @click="click($event)")
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Icon",
  props: ["name"],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({ icons: "icons/items" }),
    svg() {
      //   if (!state.icons.all || state.icons.all.length < 1) {
      //     return "<svg viewBox='0 0 48 48'><path d='M23,23 L25,23 L25,25 L23,35 L23,23 Z'></path></svg>"
      //   }
      //   const p = state.icons.all[this.name]
      const p = this.icons && this.icons[this.name];
      if (p) {
        if (p.startsWith("<path")) {
          return `<svg viewBox="0 0 48 48">
					<g  transform="scale(1,-1) translate(0,-48)">
					${p}
					</g>
					</svg>`;
        }
        return `<svg viewBox="0 0 48 48">
	<path transform="scale(1,-1) translate(0,-48)" d="${p}" />
	</svg>`;
      }
    },
  },
  methods: {
    click(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style lang="less">
.icon {
  text-align: left;
  width: 32px;
  height: 32px;
  display: inline-grid;
  cursor: pointer;
}

.icon svg {
  fill: currentColor;
}
.icon.giant {
  width: 80px;
  height: 80px;
}
.icon.big {
  width: 48px;
  height: 48px;
}

.icon.small {
  width: 20px;
  height: 20px;
}

.icon.tiny {
  width: 16px;
  height: 16px;
}

.icon.micro {
  width: 10px;
  height: 10px;
}

.icon.framed {
  padding: 2px;
  border: 1px solid gray;
  border-radius: 3px;
}
.icon.rounded {
  padding:4px;
  border: 1px solid gray;
  border-radius: 24px;
}
.icon.button {
    width: 32px;
    height: 32px;
    padding: 8px;
    border: 1px solid gray;
    border-radius: 5px;
    background: linear-gradient(0deg, #ccc, #eee);
    box-shadow: #777 2px 2px 4px;
}
.icon.button:active {
    box-shadow: inset #444 2px 2px 4px;
}
</style>
