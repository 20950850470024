"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Media = void 0;
const api_js_1 = require("@/api.js");
const state = {
    page: 1,
    pageSize: 100,
    pages: 0,
    total: 0,
    items: [],
    item: {},
    content: null
};
const mutations = {
    clear(state) {
        state.items = [];
        state.item = {};
        state.page = 1;
        state.pages = 0;
        state.total = 0;
    },
    page(state, page) {
        state.page = page;
    },
    saveItems(state, data) {
        state.items = data.items;
        state.page = data.page;
        state.pageSize = data.pageSize;
        state.pages = data.pages;
        state.total = data.total;
    },
    item(state, item) {
        console.log('set item', item);
        state.item = item;
    },
    content(state, c) {
        state.content = c;
    }
};
const getters = {
    page: (state) => state.page,
    total: (state) => state.total,
    pages: (state) => state.pages,
    items(state) {
        return state.items;
    },
    item(state) {
        return state.item;
    },
    content(state) {
        return state.content;
    }
    // center(state) {
    //     if (state.item.latitude && state.item.longitude) {
    //         return { lat:state.item.latitude, lng:state.item.longitude};
    //     }
    //     return { lat:0, lng: 0 }
    // }
};
const actions = {
    clear({ commit }) {
        commit('clear');
    },
    page({ commit }, page) {
        commit("page", page);
    },
    list({ commit }, filter) {
        console.log('FILTER', filter, state.page);
        api_js_1.default.media.files(filter, state.page)
            .then(res => {
            commit("saveItems", res.data);
        });
    },
    item({ commit }, id) {
        return api_js_1.default.media.item(id)
            .then(res => {
            commit("item", res.data);
        });
    },
    content({ commit }, id) {
        console.log('GET CONTENT');
        return api_js_1.default.media.content(id)
            .then(res => {
            console.log('GOT CONTENT');
            commit("content", res.data);
        });
    }
};
exports.Media = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
