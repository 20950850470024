<template lang="pug">
.grid
  .field
    .cell(v-for="i in 50" :class="{ drawed: isDrawed('N', i) }") {{ i }}
      .hitted(v-if="isDrawed('N', i) && isCrossed('N', i)")
        svg(
          xmlns:xlink="http://www.w3.org/1999/xlink",
          xmlns="http://www.w3.org/2000/svg",
          version="1.1",
          width="100%",
          height="100%",
          viewBox="0 0 48 48"
        )
          g
            path(
              opacity=1,
              stroke-width=10,
              stroke="red",
              fill="none",
              d="M 0 0 L 0 47 L 47 47 L 47 0 L 0 0"
            )
      .crossed(v-if="isCrossed('N', i)")
        svg(
          xmlns:xlink="http://www.w3.org/1999/xlink",
          xmlns="http://www.w3.org/2000/svg",
          version="1.1",
          width="100%",
          height="100%",
          viewBox="0 0 48 48"
        )
          g
            path(
              opacity=0.5,
              stroke-width=10,
              stroke="blue",
              d="M 0 0 L 47 47 M 0 47 L 47 0"
            )
  div(style="height: 2vw")
  .sz
    .cell(v-for="i in 12", :class="{ drawed: isDrawed('S', i) }") {{ i }}
      .hitted(v-if="isDrawed('S', i) && isCrossed('S', i)")
        svg(
            xmlns:xlink="http://www.w3.org/1999/xlink",
            xmlns="http://www.w3.org/2000/svg",
            version="1.1",
            width="100%",
            height="100%",
            viewBox="0 0 48 48"
        )
          g
            path(
                    opacity=1,
                    stroke-width=10,
                    stroke="red",
                    fill="none",
                    d="M 0 0 L 0 47 L 47 47 L 47 0 L 0 0"
            )
      .crossed(v-if="isCrossed('S', i)")
        svg(
          xmlns:xlink="http://www.w3.org/1999/xlink",
          xmlns="http://www.w3.org/2000/svg",
          version="1.1",
          width="100%",
          height="100%",
          viewBox="0 0 48 48"
        )
          g
            path(
              opacity=0.5,
              stroke-width=10,
              stroke="blue",
              d="M 0 0 L 47 47 M 0 47 L 47 0"
            )
</template>

<script>
export default {
  props: ["crossed", "drawed"],
  methods: {
    isCrossed(t, n) {
      if (!this.crossed) return null;
      if (t == "N") return this.crossed.nums.includes(n);
      if (t == "S") return this.crossed.sz.includes(n);
      return false;
    },
    isDrawed(t, n) {
      if (!this.drawed || !this.drawed.drawNumbersCollection) return null;
      console.log(this.drawed.drawNumbersCollection);
      if (t == "N") return this.drawed.drawNumbersCollection.find(x => x.drawNumberType == 0 && x.drawNumber == n);
      if (t == "S") return this.drawed.drawNumbersCollection.find(x => x.drawNumberType == 1 && x.drawNumber == n)
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.grid {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin: 0;
}
.field {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border: 1px solid gray;
  margin: 0;
  background-color: #eee;
}

.sz {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border: 1px solid gray;
  margin: 0;
  background-color: #fee;
}

.cell {
  border: 1px solid gray;
  width: 8vw;
  height: 8vw;
  margin: 0.3vw;
  line-height: 8vw;
  font-size: 5vw;
  color: black;
  position: relative;
}
.crossed {
  position: absolute;
  left: 10%;
  top: 10%;
  right: 10%;
  bottom: 10%;
}
.drawed {
  background-color: #cfc;
}
.hit {
    background-color: #484;
}
.hitted {
    position: absolute;
    left: 5%;
    top: 5%;
    right: 5%;
    bottom: 5%;
}
</style>