<template lang="pug">
.container
  demo-list(v-if="this.$route.name === 'demos'")
  router-view
</template>

<script>
import Button3d from "@/components/Button3d.vue";
import DemoList from "@/components/DemoList.vue";
export default {
  components: { Button3d,DemoList },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.container {
  flex: 1 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}
</style>