<template lang="pug">
.sheet
  .header
    div(style="flex:1 0;")
    icon.small.framed(
      name="close",
      @click="$emit('close')",
      style="color: red"
    )
  .content
    .column(style="padding:0 1rem;")
        div(v-for="(p, ix) in patterns",style="display:flex;align-items:center;border:1px solid silver;padding:0.2rem;margin:0.2rem 0;")
            div
                button3d.b2(@click="delPattern(ix)")
                    icon.small(name="close")
                button3d.b2(@click="save(ix)")
                    icon.small(name="save")
            div(style="display:flex;flex-flow:column;",@click="load(p)")
                div(v-for="y in p.length/18",style="display:flex;")
                    div(v-for="x in 18",style="height:3px;width:3px;",:style="{ backgroundColor: p[(y-1)*18+x-1]}")
    .column(style="overflow: auto") 
      div(style="display:flex;")
        div(style="width:4rem;")
        .top(v-for="x in 18")
            icon.small(
                name="chevronDown",
                :style="{ color: currentColor }",
                @click="setCol(x-1)"
            )
      div(v-for="y in steps", style="display: flex")
        div(style="display:flex;width:4rem;")
            icon.small(name="close", @click="delRow(y-1)")
            div(style="width: 2rem") {{ y - 1 }}
            icon.small(
                name="chevronRight",
                :style="{ color: currentColor }",
                @click="setRow(y-1)"
            )
        .LED(
            v-for="x in 18",
            :style="{ backgroundColor: color(x, y) }",
            @click="setPixel(x, y)"
        )
      div(style="display: flex")
        icon.small(name="add", @click="addRow()")
    .column
      button3d.b1(@click="black()")
        icon(name="new")
      button3d.b1(@click="copy()")
        icon(name="trigger")
      button3d.b1(@click="save(-1)")
        icon(name="save")
      color-picker(:currentColor="currentColor", @color="updateColor($event)")
</template>

<script>
import api from "@/api";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import ColorPicker from "@/components/ColorPicker.vue";

export default {
  components: { Icon, Button3d, ColorPicker },
  data() {
    return {
      currentColor: "#ff0000",
      matrix: [],
      patterns: [],
    };
  },
  mounted() {
    this.matrix = [...Array(14 * 18)].map((x) => "#000");
    this.patterns = JSON.parse(localStorage.getItem("patterns") || "[]");
  },
  computed: {
    steps() {
      return this.matrix.length / 18;
    },
  },
  methods: {
    updateColor(c) {
      this.currentColor = c;
    },
    delPattern(ix) {
        this.patterns.splice(ix, 1);
        this.patterns = this.patterns.map(x => x);
        localStorage.setItem("patterns", JSON.stringify(this.patterns));
    },
    setCol(x) {
        const n = this.matrix.length / 18;
        for (let y=0; y<n; y++) {
            this.matrix[y*18+x] = this.currentColor;
        }
        this.matrix = this.matrix.map(x => x);
    },
    setRow(y) {
        for (let x=0; x<18; x++)
            this.matrix[y*18+x] = this.currentColor;
        this.matrix = this.matrix.map(x => x);
    },
    addRow() {
      const a = [...Array(18)];
      a.fill("#000000");
      this.matrix = this.matrix.concat(a);
    },
    delRow(y) {
        this.matrix.splice((y)*18, 18);
        this.matrix = this.matrix.map(x => x); 
    },
    color(x, y) {
      return this.matrix[(y - 1) * 18 + x - 1];
    },
    load(p) {
      this.matrix = p.map((x) => x);
    },
    black() {
      this.matrix = this.matrix.map((x) => "#000000");
    },
    setPixel(x, y) {
      const c = this.currentColor;
      this.$set(this.matrix, (y - 1) * 18 + x - 1, c);
    },
    save(ix) {
        const m = this.matrix.map((x) => x);
        if(ix >= 0)
            this.patterns[ix] = m;
        else
            this.patterns.push(m);
      this.patterns = this.patterns.map(x => x);
      
      localStorage.setItem("patterns", JSON.stringify(this.patterns));
    },
    async copy() {
      api.stairs.copy(this.matrix);
    },
  },
};
</script>

<style lang="less" scoped>
.sheet {
  display: flex;
  flex-flow: column;
  margin: 2rem 1rem;
  background-color: #ffffff;
  border: 1px solid gray;
  border-radius: 0.25rem;
  box-shadow: 0.25rem 0.25rem 0.25rem #777;
  overflow: hidden;
}
.header {
  display: flex;
  background-color: #ccddff;
  padding: 0.25rem;
}
.content {
  flex: 0 0 auto;
  overflow: auto;
  display: flex;
  flex-flow: row;
  margin: 2rem 1rem;
  background-color: #ffffff;
  padding: 1rem;
}
.b1 {
  margin: 0.5rem 1rem;
  color: black;
  align-self: flex-start;
}

.b2 {
  margin: 0.25rem 1rem;
  color: black;
  align-self: flex-start;
}

.column {
  flex: 0 0 auto;
  display: flex;
  flex-flow: column;
}

.top {
  width: 1.4rem;
  height: 1.4rem;
  border: 1px solid gray;
  margin: 0.1rem;
}
.LED {
  width: 1.4rem;
  height: 1.4rem;
  border: 1px solid gray;
  margin: 0.1rem;
  border-radius: 0.7rem;
  box-shadow: 0.1rem 0.1rem 0.25rem #777;
}
</style>