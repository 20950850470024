<template lang="pug">
div(style="flex: 1 0; display: flex; flex-flow: column")
  div(
    style="display: flex; flex: 0 0 auto; flex-flow: row wrap; padding: 0; align-items: center"
  )
    button3d(
      @click="prev()",
      style="margin: 1rem",
      :disabled="move || roomNo < 1"
    )
      icon(name="chevronLeft")
    div(style="font-size: 3rem; color: black") {{ roomNo + 1 }}
    button3d(
      @click="next()",
      style="margin: 1rem",
      :disabled="move || roomNo >= rooms.length - 1"
    )
      icon(name="chevronRight")
    div(style="width: 2rem")
    button3d(@click="turnLeft()", style="margin: 1rem", :disabled="move")
      icon(name="turnLeft")
    button3d(@click="forward()", style="margin: 1rem", :disabled="move")
      icon(name="forward")
    button3d(@click="turnRight()", style="margin: 1rem", :disabled="move")
      icon(name="turnRight")
  #container(style="display: flex; flex: 1 0")
</template>

<script>
import roomList from "@/rooms.json";
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import * as T from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { ColladaLoader } from "three/examples/jsm/loaders/ColladaLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

export default {
  components: { Icon, Button3d },
  data() {
    return {
      avatar: null,
      move: null,
      roomNo: 0,
      debugging: false,
      room: {},
      moves: {
        0: { axis: "y", step: -1 },
        90: { axis: "x", step: -1 },
        180: { axis: "y", step: 1 },
        270: { axis: "x", step: 1 },
      },
      rooms: [],
      objects: [],
    };
  },

  async mounted() {
    // roomList.forEach(r => this.rooms.push(Object.assign({}, r)));
    this.rooms = roomList;
    this.setCanvas();
    this.m = 0;
    this.phase = 0;
    this.animate();
  },
  methods: {
    debug() {
      const loader = new FontLoader();

      loader.load("/fonts/gentilis_bold.typeface.json", (font) => {
        for (let y = 0; y < this.room.map.length; y++) {
          const r = this.room.map[y];
          const tex = new T.MeshLambertMaterial({
            color: "#00ff00",
            wireframe: true,
          });
          for (let x = 0; x < r.length; x++)
            if (r[x] > 0) {
              const g = new TextGeometry(x + "," + y, {
                font: font,
                size: 0.5,
                height: 0.1,
                curveSegments: 4,
                bevelEnabled: false,
                bevelThickness: 1,
                bevelSize: 0,
                bevelOffset: 0,
                bevelSegments: 3,
              });
              const m = new T.Mesh(g, tex);
              m.position.x = x * 5;
              m.position.y = 5.2;
              m.position.z = y * 5;
              m.rotation.x = -Math.PI / 2;
              this.scene.add(m);
              this.objects.push(m);
              const cube = new T.BoxGeometry(4.95, 6.1, 4.95);
              const box = new T.Mesh(cube, tex);
              box.position.x = x * 5;
              box.position.y = 3.1;
              box.position.z = y * 5;
              this.scene.add(box);
              this.objects.push(box);
            }
        }
      });
    },
    next() {
      this.clearRoom();
      this.roomNo++;
      this.initRoom();
    },
    prev() {
      this.clearRoom();
      this.roomNo--;
      this.initRoom();
    },
    clearRoom() {
      this.scene.remove(this.avatar);
      this.objects.forEach((o) => this.scene.remove(o));
    },
    initRoom() {
      this.room = JSON.parse(JSON.stringify(this.rooms[this.roomNo]));
      //   this.room = Object.assign({}, this.rooms[this.roomNo]);
      this.controls.target = new T.Vector3(
        this.room.lookAt.x,
        0,
        this.room.lookAt.y
      );
      this.controls.saveState();

      const loader = new T.TextureLoader();

      this.createAvatar(this.room.avatar);

      this.createWalls(-2.5, 22.5);
      this.room.boxes.forEach((b, ix) => {
        this.createBox(b, loader);
      });
      this.room.targets.forEach((t) => {
        this.createTarget(t.x * 5, t.y * 5);
      });

      this.debugging &&this.debug();
    },
    setCanvas() {
      let map = document.getElementById("container");
      let mapDimensions = map.getBoundingClientRect();
      this.width = mapDimensions.width;
      this.height = mapDimensions.height;

      //   this.width = window.innerWidth;
      //   this.height = window.innerHeight;

      // Renderer
      this.renderer = new T.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(1);
      this.renderer.setSize(this.width, this.height);
      //   document
      //     .getElementById("container")
      map.appendChild(this.renderer.domElement);

      this.clock = new T.Clock();
      this.scene = new T.Scene();

      const light = new T.HemisphereLight(0xffffff, 0xdddddd, 0.3);
      light.position.set(0, 16, 0);
      this.scene.add(light);

      var light1 = new T.DirectionalLight(0xffffff);
      light1.position.set(-3, 6, -1);
      this.scene.add(light1);

      this.camera = new T.PerspectiveCamera(
        30,
        this.width / this.height,
        0.1,
        1000
      );
      this.camera.up.set(0, 1, 0);
      this.camera.position.x = 15;
      this.camera.position.y = 50;
      this.camera.position.z = 40;
      this.scene.add(this.camera);

      this.renderer.render(this.scene, this.camera);
      this.renderer.shadowMap.enabled = false;
      // Controls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      this.controls.enableDamping = true;
      this.initRoom();
    },
    createAvatar(p) {
      const cloader = new ColladaLoader();
      cloader.load("/img/stormtrooper/stormtrooper.dae", (collada) => {
        this.avatar = collada.scene;
        this.avatar.position.x = p.x * 5;
        this.avatar.position.y = 0;
        this.avatar.position.z = p.y * 5;
        this.avatar.rotation.z = (Math.PI * p.a) / 180;

        const animations = this.avatar.animations;

        this.mixer = new T.AnimationMixer(this.avatar);
        this.mixer.clipAction(animations[0]).play();

        this.scene.add(this.avatar);
      });
    },
    createTarget(x, z) {
      const m = new T.MeshPhongMaterial({
        color: "#ff0000",
      });
      const cyl = new T.CylinderGeometry(0.5, 0.5, 0.01);
      const c = new T.Mesh(cyl, m);
      c.position.x = x;
      c.position.y = 0;
      c.position.z = z;
      this.scene.add(c);
      this.objects.push(c);
    },
    createBox(b, loader) {
      const tex = new T.MeshLambertMaterial({
        map: loader.load("/img/kiste1.jpg"),
        bumpScale: 1.5,
        bumpMap: loader.load("/img/kiste1b.jpg"),
      });
      const textureCube = [tex, tex, tex, tex, tex, tex];
      const cube = new T.BoxGeometry(4.9, 4.9, 4.9);
      const box = new T.Mesh(cube, textureCube);
      box.position.x = b.x * 5;
      box.position.y = 2.45;
      box.position.z = b.y * 5;
      this.scene.add(box);
      b.box = box;
      this.objects.push(box);
      this.checkTargets(b.x, b.y, box);
    },
    createWalls(x, z) {
      const grey = new T.MeshBasicMaterial({
        color: "#aaaaaa",
      });
      const loader = new T.TextureLoader();
      const t = loader.load("/img/stormtrooper/bricks.jpg", (tx) => {
        tx.wrapS = T.RepeatWrapping;
        tx.wrapT = T.RepeatWrapping;
        tx.repeat.set(0.3, 0.3);
      });
      const tb = loader.load("/img/stormtrooper/bricksB.jpg", (tx) => {
        tx.wrapS = T.RepeatWrapping;
        tx.wrapT = T.RepeatWrapping;
        tx.repeat.set(0.3, 0.3);
      });
      const t1 = loader.load("/img/stormtrooper/granite.jpg", (tx) => {
        tx.wrapS = T.RepeatWrapping;
        tx.wrapT = T.RepeatWrapping;
        tx.repeat.set(0.2, 0.2);
      });
      const t1b = loader.load("/img/stormtrooper/granite1.jpg", (tx) => {
        tx.wrapS = T.RepeatWrapping;
        tx.wrapT = T.RepeatWrapping;
        tx.repeat.set(0.2, 0.2);
      });
      const texture = [
        grey,
        new T.MeshLambertMaterial({ map: t, bumpMap: tb, bumpScale: 0.1 }),
      ];
      const extrudeSettings = {
        steps: 2,
        depth: 6,
        bevelEnabled: false,
        bevelThickness: 0.3,
        bevelSize: 0.3,
        bevelOffset: 0,
        bevelSegments: 16,
      };

      const room = new T.Group();
      const shape = new T.Shape();
      this.room.walls.shape.forEach((s, ix) => {
        ix ? shape.lineTo(s.x, s.y) : shape.moveTo(s.x, s.y);
      });

      const hole = new T.Shape();
      this.room.walls.inner.forEach((s, ix) => {
        ix ? hole.lineTo(s.x, s.y) : hole.moveTo(s.x, s.y);
      });
      shape.holes.push(hole);
      const wall = new T.ExtrudeGeometry(shape, extrudeSettings);
      const walls = new T.Mesh(wall, texture);
      room.add(walls);

      this.room.walls.blocks.forEach((b) => {
        const block = new T.Shape();
        b.forEach((s, ix) => {
          ix ? block.lineTo(s.x, s.y) : block.moveTo(s.x, s.y);
        });
        const c = new T.ExtrudeGeometry(block, extrudeSettings);
        const w = new T.Mesh(c, texture);
        room.add(w);
      });

      const floor = new T.Mesh(
        new T.ExtrudeGeometry(hole, { depth: 0.01, bevelEnabled: false }),
        new T.MeshLambertMaterial({ map: t1, bumpMap: t1b, bumpScale: 0.1 })
      );
      floor.position.z = 6;
      room.add(floor);
      this.objects.push(floor);

      room.rotation.z = 0; //-Math.PI;
      room.rotation.x = (Math.PI * 90) / 180;
      //   room.position.x = x;
      //   room.position.z = z;
      room.position.y = 6 - 0.001;
      this.scene.add(room);
      this.objects.push(room);
      this.setupKeyControls();
    },
    checkMove(t) {
      const p = { x: this.room.avatar.x, y: this.room.avatar.y };
      p[t.axis] += t.step;
      if (p.y >= 0 && p.y < this.room.map.length) {
        const r = this.room.map[p.y];
        if (p.x >= 0 && p.x < r.length && r[p.x] > 0) {
          return r[p.x];
        }
      }
      return 0;
    },
    checkPush(t) {
      const p = { x: this.room.avatar.x, y: this.room.avatar.y };
      p[t.axis] += t.step;

      const b = this.room.boxes.find((b) => b.x == p.x && b.y == p.y);
      if (!b) return 1;

      p[t.axis] += t.step;
      if (p.y >= 0 && p.y < this.room.map.length) {
        const r = this.room.map[p.y];
        if (p.x >= 0 && p.x < r.length && r[p.x] > 0) {
          return r[p.x];
        }
      }
      return 0;
    },
    turnLeft() {
      this.move = {
        mode: "r",
        from: { a: this.room.avatar.a },
        to: { a: this.room.avatar.a + 90 },
        step: 0,
      };
    },
    turnRight() {
      this.move = {
        mode: "r",
        from: { a: this.room.avatar.a },
        to: { a: this.room.avatar.a - 90 },
        step: 0,
      };
    },
    forward() {
      const t = this.moves[this.room.avatar.a];
      const x = this.checkMove(t);
      if (!x) return;
      if (x > 0) {
        if (!this.checkPush(t)) return;
      }
      const from = { x: this.room.avatar.x, y: this.room.avatar.y };
      const to = { ...from };
      to[t.axis] += t.step;
      this.move = { mode: "m", from, to, box: null, step: 0 };
      const ix = this.room.boxes.findIndex((b) => b.x == to.x && b.y == to.y);
      if (ix >= 0) {
        const box = this.room.boxes[ix];
        const bfrom = { x: box.x, y: box.y };
        const bto = { ...bfrom };
        bto[t.axis] += t.step;
        this.move.box = { ix, b: box.box, from: bfrom, to: bto };
      }
    },
    setupKeyControls() {
      document.onkeydown = (e) => {
        if (this.move) return;
        switch (e.key) {
          case "ArrowLeft":
            this.turnLeft();
            break;
          case "ArrowUp":
            this.forward();
            break;
          case "ArrowRight":
            this.turnRight();
            break;
        }
      };
    },
    checkTargets(x, y, box) {
        const tx = this.room.targets.find(
        (t) => t.x == x && t.y == y
        );
        box.material[0].color = tx
        ? new T.Color(0.3, 0.7, 0.3)
        : new T.Color(1, 1, 1);
    },
    animate() {
      if (this.move) {
        const m = this.move;
        if (m.mode == "r") {
          const n = 32;
          const a = m.from.a + ((m.to.a - m.from.a) * m.step) / n;
          this.room.avatar.a = a;
          this.avatar.rotation.z = (Math.PI * a) / 180;
          m.step++;
          if (m.step > n) {
            this.room.avatar.a = (m.to.a + 360) % 360;
            this.avatar.rotation.z = (Math.PI * this.room.avatar.a) / 180;
            this.move = null;
          }
        } else {
          const n = m.box ? 10 : 32;
          // this.room.avatar[t.axis] += t.step;
          this.avatar.position.x =
            (m.from.x + ((m.to.x - m.from.x) * m.step) / n) * 5;
          this.avatar.position.z =
            (m.from.y + ((m.to.y - m.from.y) * m.step) / n) * 5;
          if (m.box) {
            m.box.b.position.x =
              (m.box.from.x + ((m.box.to.x - m.box.from.x) * m.step) / n) * 5;
            m.box.b.position.z =
              (m.box.from.y + ((m.box.to.y - m.box.from.y) * m.step) / n) * 5;
          }
          m.step++;
          if (m.step > n) {
            this.room.avatar.x = m.to.x;
            this.room.avatar.y = m.to.y;
            if (m.box) {
              const box = m.box;
              this.room.boxes[box.ix].x = box.to.x;
              this.room.boxes[box.ix].y = box.to.y;
              m.box.b.position.x = box.to.x * 5;
              m.box.b.position.z = box.to.y * 5;
              this.checkTargets(box.to.x, box.to.y, box.b);
            }
            this.move = null;
          }
        }
      }
      this.controls.update();
      requestAnimationFrame(this.animate);
      const delta = this.clock.getDelta();

      if (this.mixer !== undefined) {
        this.mixer.update(delta);
      }
      this.renderer.render(this.scene, this.camera);
    },
  },
};
</script>