<template lang="pug">
    .pager
        div(v-if="pages > 0") Seite {{page}} von {{pages}}
        .navi
            icon.first(name="begin",:class="{ disabled: page<2 }", @click="go(1)")
            icon.mid(name="fp",:class="{ disabled: page<2 }", @click="go(page-10)")
            icon.mid(name="prev",:class="{ disabled: page<2 }", @click="go(page-1)")
            icon.mid(name="next",:class="{ disabled: page>=pages }", @click="go(page+1)")
            icon.mid(name="ff",:class="{ disabled: page>=pages }", @click="go(page+10)")
            icon.last(name="end",:class="{ disabled: page>=pages }", @click="go(pages)")
</template>

<script>
import Icon from '@/components/Icon.vue';

export default {
    components: { Icon },
    props: ['page', 'pages'],
    methods: {
        go(page) {
            if (page < 1)
                page = 1;
            if (page > this.pages)
                page = this.pages;
            this.$emit('change', page);
        }
    }
}
</script>

<style lang="less" scoped>
.pager {
    padding: 0.5rem;
    flex: 0 0 auto;
    display: flex;
    flex-flow: column;
}

.navi {
    padding: 0.5rem;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
}

.pager .icon {
    border-style: solid;
    border-color: gray;
    padding: 0.5rem;
    color: black;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.pager .icon.disabled {
    cursor: normal;
    color: #ddd;
}

.pager .icon.first {
    border-width: 1px;
    border-radius: 5px 0 0 5px;
}

.pager .icon.last {
    border-width: 1px 1px 1px 0;
    border-radius: 0 5px 5px 0;
}

.pager .icon.mid {
    border-width: 1px 1px 1px 0;
    border-radius: 0;
}
</style>