<template lang="pug">
.container
    button3d(v-if="$route.name === 'tools' && (!t.local || local)",v-for="t in tools",style="width:20rem;margin:0.5rem;",@click="goto(t.target)",:title="t.info") 
        div(style="display:flex;flex:1 0;align-items:center;")
            img(:src="t.img")
            div(style="flex:1 0;display:flex;justify-content:center;") {{t.name}}
    router-view(style="flex:1 0;display:flex;flex-flow:column;")
</template>

<script>
import Vue from 'vue';
import Icon from '@/components/Icon.vue';
import Button3d from '@/components/Button3d.vue';

export default {
    components: { Icon, Button3d },
    data() {
        return {
            local: false,
            tools: [
                { target: 'kfz', name: 'KFZ-Kennzeichen', img: '/img/kfz.jpg', info: 'Abfrage der Autokennzeichen'},
                { target: 'kfz2', name: '3D-KFZ-Kennzeichen', img: '/img/kfz2.jpg', info: 'Abfrage der Autokennzeichen'},
                { target: 'stairs', name: 'Treppenbeleuchtung', img: '', info:'', local:true },
                { target: 'recipes', name: 'Kochrezepte', img: '/img/cookbook1.png', info:''}
            ]
        }
    },
    methods: {
        goto(t) {
            this.$router.push({ name:t });
        }
    },
    mounted() {
        this.local = window.location.protocol == 'http:';
        console.log(this.$route, window.location);
    }
}
</script>

<style lang="less" scoped>
.container {
    flex:1 0;
    display:flex;
    flex-flow: column;
    justify-content: flex-start;
    overflow: auto;
}
.grid {
  margin: 1rem;
  flex: 1 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(4fr);
  align-items: stretch;
  gap: 0.5rem;
  overflow: auto;
}
button3d {
  font-size: 1.8rem;
}

@media print {
    .container {
        display:block;
        height: auto !important;
    }
}
</style>