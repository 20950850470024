<template lang="pug">
    .content
        div(style="display:flex;background-color:#dddddd;align-items:center;")
            div(style="flex:1 0;color:black;") Patterns
            icon.small.framed(name="close",@click="close",style="color:red;padding:0.5rem;margin:0.2rem;")
        .body
            div(v-for="p in list",style="display:flex;align-items:center;color:black;") 
                .pict(:style="{ 'background-image': 'url(img/rubik/' + p.id + '_b.png' }")
                .pict(:style="{ 'background-image': 'url(img/rubik/' + p.id + '_f.png' }")
                div(style="flex:1 0;margin:0 1rem;") {{p.name}}
                button3d(@click="play(p.moves)")
                    icon.small(name="play")
</template>

<script>
import Icon from '@/components/Icon.vue';
import Button3d from '@/components/Button3d.vue';

export default {
    components: { Icon, Button3d },
    data() {
        return {
            list: [
                { id: "chess", name: "chess", moves: "U2,R2,F2,R2,L2,F2,L2,D2" },
                { id: "cross", name: "4 crosses", moves: "U,F,BP,L2,U2,L2,FP,B,U2,L2,U" },
                { id: "stripes", name: "stripes", moves: "F,U,F,R,L2,B,DP,R,D2,L,DP,B,R2,L,F,U,F" },
                { id: "cubes", name: "cubes", moves: "F,L,F,UP,R,U,F2,L2,UP,LP,B,DP,BP,L2,U" },
                { id: "snake", name: "snake", moves: "F2,RP,BP,U,RP,L,FP,L,FP,B,DP,R,B,L2" },
                { id: "corners", name: "corners", moves: "F,BP,U,F,U,F,U,L,B,L2,BP,U,FP,L,U,LP,B" },
                { id: "CUaround", name: "C U around", moves: "UP,B2,U,L2,D,L2,R2,DP,BP,R,DP,L,RP,B2,U2,FP,LP,UP" },
                { id: "checkerboard", name: "checkerboard", moves: "U2 D2 F2 B2 L2 R2" },
                { id: "flippedtips", name: "flipped tips", moves: "U B D' F2 D B' U' R2 D F2 D' R2 D F2 D' R2" },
                { id: "plusminus", name: "plus minus", moves: "U2 R2 L2 U2 R2 L2" },
                { id: "spiral", name: "spiral", moves: "L' B' D U R U' R' D2 R2 D L D' L' R' F U" },
                { id: "vstripes", name: "vertical stripes", moves: "F U F R L2 B D' R D2 L D' B R2 L F U F" },
                { id: "crosses", name: "crosses", moves: "R2 L' D F2 R' D' R' L U' D R D B2 R' U D2" },
                { id: "cube3", name: "cube in a cube in a cube", moves: "U' L' U' F' R2 B' R F U B2 U B' L U' F U R F'" },
                { id: "anaconda", name: "anaconda", moves: "L U B' U' R L' B R' F B' D R D' F'" },
                { id: "python", name: "python", moves: "F2 R' B' U R' L F' L F' B D' R B L2" },
                { id: "mamba", name: "black mamba", moves: "R D L F' R L' D R' U D' B U' R' D'" },
                { id: "fourspots", name: "four spots", moves: "F2 B2 U D' R2 L2 U D'" },
                { id: "sixspots", name: "six spots", moves: "U D' R L' F B' U D'" },
                { id: "twister", name: "twister", moves: "F R' U L F' L' F U' R U L' U' L F'" },
                { id: "kilt", name: "kilt", moves: "U' R2 L2 F2 B2 U' R L F B' U F2 D2 R2 L2 F2 U2 F2 U' F2" },
                { id: "tetris", name: "tetris", moves: "L R F B U' D' L' R'" },
                { id: "wire", name: "wire", moves: "R L F B R L F B R L F B R2 B2 L2 R2 B2 L2" },
            ]
        }
    },
    methods: {
        play(m) {
            this.$emit('play', m);
        },
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style lang="less" scoped>
.content {
    font-size:1.8rem;
    flex:1 0;
    margin: 4rem 0;
    overflow:auto;
    display:flex;
    flex-flow:column nowrap;
    border:1px solid gray;
    border-radius:0.25rem;
    background-color:white;
    box-shadow:#444 2px 2px 4px;
}
.body {
    display:flex;
    flex-flow: column; 
    overflow:auto;
    padding:1rem 2rem;
}
.pict {
    width:80px;
    height:80px;
    background-size: cover;
    margin:0.25rem;
}
</style>