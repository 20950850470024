<template lang="pug">
div(style="flex: 1 0; display: flex; flex-flow: column")
  div(
    style="flex: 0 0 auto; display: flex; justify-content: flex-end; height: 3rem"
  )
    input.slider(
      style="width: 32rem; margin: 0 1rem",
      type="range",
      min="5",
      max="50",
      step="1",
      v-model="speed"
    )

    button3d(
      v-if="!move",
      style="margin: 0.5rem 1rem",
      @click="selectingGame = 1"
    ) Games
    button3d(v-if="!move", style="margin: 0.5rem 1rem", @click="reset") Reset
  #container(style="display: flex; flex: 1 0; position: relative")
    div(
      style="position: absolute; left: 0.5rem; top: 0.5rem; width: 14rem; display: flex; flex-flow: column; background-color: white; background-color: white; color: black; align-items: flex-start"
    )
      div(v-for="m in moveList", style="display: flex; padding: 0.1rem 0.5rem") 
        |
        div(style="width: 2rem; text-align: right; margin-right: 1rem") {{ m[0] }}
        div(style="width: 5rem; text-align: left") {{ m[1] }}
        div(style="width: 5rem; text-align: left") {{ m[2] }}
  div(
    v-if="selectingGame",
    style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; align-items: center; display: flex; flex-flow: column; background-color: #ffffffcc"
  )
    chess-games(@selected="startGame", @close="selectingGame = 0")
</template>

<script>
import Icon from "@/components/Icon.vue";
import Button3d from "@/components/Button3d.vue";
import ChessPieces from "@/components/ChessPieces.vue";
import ChessGames from "@/components/ChessGames.vue";
import * as T from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
export default {
  mixins: [ChessPieces],
  components: { Icon, Button3d, ChessGames },
  data() {
    return {
      width: 0,
      hegiht: 0,
      renderer: null,
      scene: null,
      camera: null,
      textureLoader: null,
      black: null,
      white: null,
      move: null,
      play: null,
      playIndex: 0,
      selectingGame: 0,
      extra: [],
    };
  },
  async mounted() {
    await this.setCanvas();
    this.animate();
  },
  methods: {
    debug() {
      const loader = new FontLoader();

      loader.load("/fonts/gentilis_bold.typeface.json", (font) => {
        for (let y = 0; y < 7; y++) {
          const r = this.room.map[y];
          const tex = new T.MeshLambertMaterial({
            color: "#00ff00",
            wireframe: true,
          });
          for (let x = 0; x < r.length; x++)
            if (r[x] > 0) {
              const g = new TextGeometry(x + "," + y, {
                font: font,
                size: 0.5,
                height: 0.1,
                curveSegments: 4,
                bevelEnabled: false,
                bevelThickness: 1,
                bevelSize: 0,
                bevelOffset: 0,
                bevelSegments: 3,
              });
              const m = new T.Mesh(g, tex);
              m.position.x = x * 5;
              m.position.y = 5.2;
              m.position.z = y * 5;
              m.rotation.x = -Math.PI / 2;
              this.scene.add(m);
              this.objects.push(m);
              const cube = new T.BoxGeometry(4.95, 6.1, 4.95);
              const box = new T.Mesh(cube, tex);
              box.position.x = x * 5;
              box.position.y = 3.1;
              box.position.z = y * 5;
              this.scene.add(box);
              this.objects.push(box);
            }
        }
      });
    },
    addBox() {
      const m = new T.MeshStandardMaterial({
        color: "#cccccc",
        reflectivity: 1,
        // envMap: this.textureCube,
      });
      const g1 = new T.BoxGeometry(1, 1, 1).translate(0, 0, 0).rotateX(0);
      const m1 = new T.Mesh(g1, m);
      //   m1.castShadow = true;
      this.scene.add(m1);
    },
    setBoard() {
      const map = this.textureLoader.load("/img/chess/oak.jpg");
      const black = new T.MeshStandardMaterial({
        map: map,
        envMap: this.textureCube,
        // transparent: true,
        // opacity: 0.1,
        metalness: 0.0,
        roughness: 0.1,
      });
      const white = new T.MeshStandardMaterial({
        map: this.textureLoader.load("/img/chess/pine.jpg"),
        envMap: this.textureCube,
        // transparent: true,
        // opacity: 0.3,
        metalness: 0.0,
        roughness: 0.1,
      });
      const frame = new T.MeshStandardMaterial({
        color: 0x111111,
        side: T.DoubleSide,
        envMap: this.textureCube,
        metalness: 1,
        roughness: 0.001,
      });
      const blackTexture = [black, black, black, black, black, black];
      const whiteTexture = [white, white, white, white, white, white];
      const frameTexture = [frame, frame, frame, frame, frame, frame];

      for (let y = 0; y < 8; y++) {
        for (let x = 0; x < 8; x++) {
          const cube = new T.BoxGeometry(1, 0.25, 1);
          const texture = (x & 1) == (y & 1) ? whiteTexture : blackTexture;
          const box = new T.Mesh(cube, texture);
          box.position.x = x;
          box.position.z = y;
          box.position.y = -0.125;
          this.scene.add(box);
        }
      }
      const cube = new T.BoxGeometry(9, 0.25, 9);
      const textureCube = frameTexture;
      const box = new T.Mesh(cube, textureCube);
      box.position.x = 3.5;
      box.position.y = -0.15;
      box.position.z = 3.5;
      const cube1 = new T.BoxGeometry(8.4, 1.4, 8.4);
      this.scene.add(box);
      const box1 = new T.Mesh(cube1, textureCube);
      box1.position.x = 3.5;
      box1.position.y = -0.8;
      box1.position.z = 3.5;
      this.scene.add(box1);

      const loader = new FontLoader();
      const tex = new T.MeshStandardMaterial({
        color: "#ffffff",
      });
      loader.load("/fonts/gentilis_bold.typeface.json", (font) => {
        ["a", "b", "c", "d", "e", "f", "g", "h"].forEach((t, ix) => {
          const g = new TextGeometry(t, {
            font: font,
            size: 0.2,
            height: 0.05,
            curveSegments: 4,
            bevelEnabled: false,
            bevelThickness: 1,
            bevelSize: 0,
            bevelOffset: 0,
            bevelSegments: 3,
          });
          const m = new T.Mesh(g, tex);
          m.position.x = 7 - ix;
          m.position.y = -0.06;
          m.position.z = -0.8;
          m.rotation.x = -Math.PI / 2;
          m.rotation.z = Math.PI;
          this.scene.add(m);
        });
        ["1", "2", "3", "4", "5", "6", "7", "8"].forEach((t, ix) => {
          const g = new TextGeometry(t, {
            font: font,
            size: 0.2,
            height: 0.05,
            curveSegments: 4,
            bevelEnabled: false,
            bevelThickness: 1,
            bevelSize: 0,
            bevelOffset: 0,
            bevelSegments: 3,
          });
          const m = new T.Mesh(g, tex);
          m.position.x = 7.8;
          m.position.y = -0.06;
          m.position.z = ix;
          m.rotation.x = -Math.PI / 2;
          m.rotation.z = Math.PI;
          this.scene.add(m);
        });
      });
    },
    loadMaterials() {
      this.black = new T.MeshStandardMaterial({
        color: 0x444444,
        side: T.DoubleSide,
        envMap: this.textureCube,
        transparent: true,
        metalness: 0.7,
        roughness: 0.1,
      });
      this.white = new T.MeshStandardMaterial({
        color: 0xffffff,
        side: T.DoubleSide,
        envMap: this.textureCube,
        transparent: true,
        metalness: 0.7,
        roughness: 0.1,
      });
    },
    async createRoom() {
      this.scene.background = this.textureCube;
    },
    async setCanvas() {
      const loader = new T.CubeTextureLoader();
      loader.setCrossOrigin("");
      loader.setPath("/img/cube/SaintLazarusChurch2/");
      this.textureCube = loader.load([
        "posx.jpg",
        "negx.jpg",
        "posy.jpg",
        "negy.jpg",
        "posz.jpg",
        "negz.jpg",
      ]);
      this.textureLoader = new T.TextureLoader();
      let map = document.getElementById("container");
      let mapDimensions = map.getBoundingClientRect();
      this.width = mapDimensions.width;
      this.height = mapDimensions.height;

      //   this.width = window.innerWidth;
      //   this.height = window.innerHeight;

      // Renderer
      this.renderer = new T.WebGLRenderer({ antialias: true });
      this.renderer.setPixelRatio(1);
      this.renderer.setSize(this.width, this.height);
      //   document
      //     .getElementById("container")
      map.appendChild(this.renderer.domElement);

      this.scene = new T.Scene();

      this.loadMaterials();

      const light = new T.HemisphereLight(0xffffff, 0xdddddd, 0.3);
      light.position.set(0, -16, 0);
      this.scene.add(light);

      var light1 = new T.DirectionalLight(0xffffff);
      light1.position.set(-3, 6, -10);
      this.scene.add(light1);

      this.camera = new T.PerspectiveCamera(
        30,
        this.width / this.height,
        0.01,
        1000
      );
      this.camera.up.set(0, 1, 0);
      this.camera.position.x = 7;
      this.camera.position.y = 8;
      this.camera.position.z = -10;
      this.scene.add(this.camera);

      this.createRoom();
      this.setBoard();

      await this.init(this.scene, this.white, this.black);

      this.renderer.render(this.scene, this.camera);
      this.renderer.shadowMap.enabled = false;
      // Controls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.target = new T.Vector3(3, -1, 3);
      this.controls.enableDamping = true;
    },
    startGame(p) {
      this.reset();
      this.moveList = [];
      this.selectingGame = 0;
      this.play = this.parseGame(p.moves);
      this.playIndex = 0;
      setTimeout(() => {
        this.move = this.startMove(this.play[this.playIndex]);
      }, 1000);
    },
    promote(color) {
      const p = {
        'T': 'rook',
        'S': 'knigt',
        'L': 'bishop',
        'D': 'queen'
      }
      const m = this.play[this.playIndex];
      console.log('promote', m);
      const pos = this.position(m[1]);
      const pawn = this.board[pos.y][pos.x];
      pawn.position.y = -1.3;
      const queen = this.createPiece(p[m.promote], color, 0);
      this.put(queen, pos.x, pos.y);
      this.scene.add(queen);
      this.extra.push(queen);
    },
    animate() {
      if (this.play && this.move) {
        this.move.index++;
        const i = this.move.index;
        const d = this.move.points.length;
        const p = this.move.piece;
        if (i < d) {
          const t = this.move.points[i];
          p.position.x = t.x;
          p.position.y = t.y;
          p.position.z = t.z;
          if (this.move.delete && i >= d - 10) {
            this.move.delete.position.y = -(i - d + 10) * 0.13;
          }
        } else {
          if (p.name == "pawn") {
            console.log("moving", this.move);
            const m = this.play[this.playIndex];
            if (m[1].match(/8$/)) {
              this.promote(this.white);
            } else
            if (m[1].match(/1$/)) {
              this.promote(this.black);
            }
          }
          this.playIndex++;
          if (this.playIndex < this.play.length) {
            this.move = this.startMove(this.play[this.playIndex]);
          } else {
            this.move = null;
            this.extra.forEach((p) => this.scene.remove(p));
          }
        }
      }
      this.controls.update();
      requestAnimationFrame(this.animate);

      this.renderer.render(this.scene, this.camera);
    },
  },
};
</script>

<style lang="less" scoped>
</style>