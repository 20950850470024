<template lang="pug">
    .skill
        icon.big(:name="icon")
        div(style="margin-left:3vw;") {{text}}
</template>

<script>
import Icon from "@/components/Icon.vue";
export default {
    components: { Icon },
    props: [ 'icon', 'text' ]
}
</script>

<style lang="less" scoped>
.skill {
    color: black;
    align-self: center;
    font-size:10vw;
    border: 1px solid gray;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    background-color: silver;
    opacity: 0.7;
    display:flex;
    align-items:center;
}
</style>