<template lang="pug">
</template>

<script>
import * as T from "three";
import { ColladaLoader } from "three/examples/jsm/loaders/ColladaLoader.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import ChessGames from "@/components/ChessGames.vue";
export default {
  mixins: [ChessGames],
  data() {
    return {
      board: null,
      dataReady: false,
      texture: null,
      black: null,
      white: null,
      cloader: null,
      speed: 20,
      pieces: {
        pawn: null,
        rook: null,
        knight: null,
        bishop: null,
        king: null,
        queen: null,
      },
      positions: [],
      moveList: [],
    };
  },
  methods: {
    async init(scene, white, black) {
      if (!this.dataReady) await this.loadAllPieces();
      this.dataReady = true;
      for (let y = 0; y < 8; y++) {
        for (let x = 0; x < 8; x++) {
          let f = null;
          const p = this.board[y][x];
          switch (p) {
            case "R":
              f = this.createPiece("rook", white, 0);
              break;
            case "H":
              f = this.createPiece("knight", white, 1);
              break;
            case "B":
              f = this.createPiece("bishop", white, 1);
              break;
            case "K":
              f = this.createPiece("king", white, 0);
              break;
            case "Q":
              f = this.createPiece("queen", white, 1);
              break;
            case "P":
              f = this.createPiece("pawn", white, 0);
              break;
            case "r":
              f = this.createPiece("rook", black, 0);
              break;
            case "h":
              f = this.createPiece("knight", black, -1);
              break;
            case "b":
              f = this.createPiece("bishop", black, -1);
              break;
            case "k":
              f = this.createPiece("king", black, 0);
              break;
            case "q":
              f = this.createPiece("queen", black, -1);
              break;
            case "p":
              f = this.createPiece("pawn", black, 0);
              break;
          }
          if (f) {
            this.positions.push([f, x, y]);
            this.put(f, x, y);
            scene.add(f);
          } else this.board[y][x] = null;
        }
      }
    },
    reset() {
      for (let y of [0, 1, 2, 3, 4, 5, 6, 7])
        for (let x of [0, 1, 2, 3, 4, 5, 6, 7]) this.board[y][x] = null;
      this.positions.forEach((f) => {
        this.put(f[0], f[1], f[2]);
      });
    },
    position(p) {
      const x = "abcdefgh".indexOf(p[0]);
      const y = "12345678".indexOf(p[1]);
      //   console.log('POS:', p, p[0],p[1], x, y)
      return { x, y };
    },
    put(t, x, y) {
      this.board[y][x] = t;
      t.position.x = 7 - x;
      t.position.z = y;
      t.position.y = 0;
    },
    startMove(mv) {
      console.log(mv);
      mv.length > 3 && this.moveList.push(mv[3]);

      const p0 = this.position(mv[0]);
      const p1 = this.position(mv[1]);
      const piece = this.board[p0.y][p0.x];

      const tp = this.board[p1.y][p1.x];
      if (tp) {
        // tp.position.y = -2;
      }
      this.board[p1.y][p1.x] = piece;
      this.board[p0.y][p0.x] = null;

      const dx = Math.sqrt(
        (p1.x - p0.x) * (p1.x - p0.x) + (p1.y - p0.y) * (p1.y - p0.y)
      );
      const pp = [
        { x: 0, y: 0 },
        { x: 0.02, y: 0.8 },
        { x: 0.45, y: 1.3 },
        { x: dx * 0.5, y: 1.44 },
        { x: dx - 0.45, y: 1.3 },
        { x: dx - 0.02, y: 0.8 },
        { x: dx, y: 0 },
      ];
      const curve = new T.SplineCurve(pp.map((p) => new T.Vector2(p.x, p.y)));

      const points = curve
        .getPoints(Math.trunc(this.speed * 2 + this.speed * dx))
        .map((p) => {
          let t = new T.Vector3(
            7 - p0.x,
            p.y,
            p0.y + (p.x * (p1.y - p0.y)) / dx
          );
          if (p1.x != p0.x) {
            const xt = p.x;
            const x = (xt * (p1.x - p0.x)) / dx;
            t = new T.Vector3(
              7 - p0.x - x,
              p.y,
              p0.y + ((p1.y - p0.y) * x) / (p1.x - p0.x)
            );
          }
          return t;
        });

      return { piece, index: 0, delete: tp, promo: mv[2], points };
    },
    async loadStl(name) {
      return new Promise((resolve, reject) => {
        const fn = `/data/chess/${name}.stl`;
        this.cloader.load(
          fn,
          (obj) => {
            console.log('BufferGeometryUtils', BufferGeometryUtils);
            // obj = BufferGeometryUtils.mergeVertices(obj, 0.1);
            // obj.normalizeNormals ();
            // obj.computeVertexNormals();
            return resolve(obj);
          },
          (p) => {},
          (e) => {
            console.log(fn, e);
          }
        );
      });
    },
    async loadAllPieces() {
      const list = Object.keys(this.pieces);
      for (let k of list) {
        const p = await this.loadStl(k);
        console.log(p);
        // p.children.forEach(function (child) {
        //     child.geometry.mergeVertices(); /* ADDED MERGE WHICH GIVES ERROR */
        //     child.geometry.computeFaceNormals();
        //     child.geometry.computeVertexNormals();
        // });
        p.scale(0.02, 0.02, 0.02);
        // p.rotateX(-Math.PI / 2);
        this.pieces[k] = p;
      }
    },
    createPiece(name, color, rotation) {
      const obj = this.pieces[name];
      const p = new T.Mesh(obj, color);
      p.geometry.computeVertexNormals();
      if (rotation) {
        const t = (rotation * Math.PI) / 2;
        p.rotation.y = t;
      }
      p.name = name;
      p.material.shading = T.SmoothShading;
      return p;
    },
  },
  async mounted() {
    const t = [
      "R,H,B,Q,K,B,H,R",
      "P,P,P,P,P,P,P,P",
      "x,x,x,x,x,x,x,x",
      "x,x,x,x,x,x,x,x",
      "x,x,x,x,x,x,x,x",
      "x,x,x,x,x,x,x,x",
      "p,p,p,p,p,p,p,p",
      "r,h,b,q,k,b,h,r",
    ];
    this.board = t.map((r) => r.split(","));
    this.cloader = new STLLoader();
  },
};
</script>