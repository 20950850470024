<template lang="pug">
div(style="flex: 1 0; display: flex; flex-flow: row")
  div(
    style="flex: 0 0 auto; display: flex; flex-flow: column; background-color: #ddd"
  )
    div(style="display: flex; align-items: center;margin:0.5rem 0;")
        button3d(v-for="set in sets",@click="selectSet(set)",:class="{ green: group && (group.name == set.id) }") {{set.id}}
    div(style="display: flex; align-items: center;margin:0.5rem 0;")
      div(style="margin: 0 0.5rem;color:red;") Axis X
      input.slider(
            style="width: 16rem;margin:0 1rem;",
            type="range",
            min="-1",
            max="1",
            step="0.01",
            v-model="planeX",
            @input="setX"
          )
      icon.small(name="turnRight",style="color:black;margin:0.25rem;",@click="switchAxis(0)")
    div(style="display: flex; align-items: center;margin:0.5rem 0;")
      div(style="margin: 0 0.5rem;color:green;") Axis Y
      input.slider(
            style="width: 16rem;margin:0 1rem;",
            type="range",
            min="-1",
            max="1",
            step="0.01",
            v-model="planeY",
            @input="setY"
          )
      icon.small(name="turnRight",style="color:black;margin:0.25rem;",@click="switchAxis(1)")
    div(style="display: flex; align-items: center;margin:0.5rem 0;")
      div(style="margin: 0 0.5rem;color:blue;") Axis Z
      input.slider(
            style="width: 16rem;margin:0 1rem;",
            type="range",
            min="-1",
            max="1",
            step="0.01",
            v-model="planeZ",
            @input="setZ"
          )
      icon.small(name="turnRight",style="color:black;margin:0.25rem;",@click="switchAxis(2)")
  #container(style="display: flex; flex: 1 0")
</template>

<script>
import a from "axios";
import dicom from 'dicom-parser';
import * as T from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { PCDLoader } from "three/examples/jsm/loaders/PCDLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";
import Button3d from "@/components/Button3d.vue";
import Icon from "@/components/Icon.vue";
export default {
  components: { Button3d, Icon },
  data() {
    return {
      scene: null,
      camera: null,
      white: null,
      red: null,
      group: null,
      clipPlanes: [],
      planeX: 0,
      planeY: 0,
      planeZ: 0,
      sets: [
        { id:1, count: 74, start: 1 },
        { id:2, count:133, start: 2 },
        { id:3, count:62, start: 2 },
        { id:4, count:74 },
        { id:5, mask: '/dicom_em_20161208_1/series1/IM${num}.png', count:151 },
        { id:6, mask: '/dicom_em_20161208_1/series2/IM${num}.png', count:151 },
        { id:7, mask: '/dicom_em_20161208_1/series3/IM${num}.png', count:151 },
        { id:8, mask: '/dicom_em_20161208_1/series4/IM${num}.png', count:151 },
        { id:9, mask: '/dicom_em_20161208_1/series5/IM${num}.png', count:45 },
        { id:10, mask: '/dicom_em_20161208_1/series8/IM${num}.png', count:76 },
      ]
    };
  },
  async mounted() {
    this.white = new T.MeshPhongMaterial({ color: "#ffffff" });
    this.red = new T.MeshPhongMaterial({ color: "#ff0000" });
    await this.setCanvas();

    // await this.loadMrt({ id: 1, count: 74, start: 2 });
    // await this.loadMrt({ id:2, count:133, start: 2});
    // await this.loadMrt({ id:3, count:62});
    this.group = await this.loadMrt(this.sets[0]);
    // await this.loadPcd({ name: 'ctGG_221021_3.pcd' })
    this.animate();
  },
  methods: {
    switchAxis(a) {
        this.clipPlanes[a].normal.x *= -1;
        this.clipPlanes[a].normal.y *= -1;
        this.clipPlanes[a].normal.z *= -1;
    },
    async selectSet(s) {
        this.group && this.scene.remove(this.group);
        this.group = await this.loadMrt(s);
    },
    setX() {
        this.clipPlanes[0].constant = this.planeX;
    },
    setY() {
        this.clipPlanes[1].constant = this.planeY;
    },
    setZ() {
        this.clipPlanes[2].constant = this.planeZ;
    },
    async loadPcd(params) {
      // const color = new T.PointsMaterial({ color: "#ffffff" });
      const loader = new PCDLoader();
      loader.load(`/mrt/${params.name}`, (points) => {
        points.scale.x = 0.01;
        points.scale.y = 0.01;
        points.scale.z = 0.3;
        this.scene.add(points);
        console.log("pcd ready");
      });
    },
    async loadMrt(params) {

      const mask = params.mask || '/mrt/IMG_${id}_${num}.png';
      const loader = new T.TextureLoader();
      const group = new T.Group();
      let width = 0;
      let height = 0;
      for (let i = params.start || 1; i <= params.count; i++) {
        const name = mask.replace('${id}', params.id).replace('${num}', i);
        const texture = loader.load(name, texture => {

            console.log(texture.source);
            width = texture.source.data.width;
            height = texture.source.data.height;
            console.log(width, height);
            const material = new T.MeshBasicMaterial({
              map: texture,
              color: 0xffffff,
              side: T.DoubleSide,
              alphaMap: texture,
              transparent: true,
              clippingPlanes: this.clipPlanes,
              clipIntersection: true,
            });
    
            const geometry = new T.PlaneGeometry(width/height, 1);
            const plane = new T.Mesh(geometry, material);
            plane.rotation.x = -Math.PI * 0.5;
            plane.position.y = i * 0.01;
            group.add(plane);
        });
      }
      group.rotation.x = Math.PI * 0.5;
      group.position.z = -0.5;
      group.name = params.id;
      this.scene.add(group);
      return group;
    },
    async setCanvas() {
      let map = document.getElementById("container");
      let mapDimensions = map.getBoundingClientRect();
      this.width = mapDimensions.width;
      this.height = mapDimensions.height;

      // Renderer
      this.renderer = new T.WebGLRenderer({
        antialias: true,
      });
      this.renderer.localClippingEnabled = true;
      this.renderer.setPixelRatio(1);
      this.renderer.setSize(this.width, this.height);

      map.appendChild(this.renderer.domElement);

      this.scene = new T.Scene();
      this.clipPlanes = [
        new T.Plane(new T.Vector3(1, 0, 0), this.planeX),
        new T.Plane(new T.Vector3(0, -1, 0), this.planeY),
        new T.Plane(new T.Vector3(0, 0, -1), this.planeZ),
      ];

      const helpers = new T.Group();
      helpers.add(new T.PlaneHelper(this.clipPlanes[0], 2, 0xff0000));
      helpers.add(new T.PlaneHelper(this.clipPlanes[1], 2, 0x00ff00));
      helpers.add(new T.PlaneHelper(this.clipPlanes[2], 2, 0x0000ff));
      helpers.visible = true;
      this.scene.add(helpers);
      //   const front = new T.BoxGeometry(1, 1, 1);
      //   this.scene.add(new T.Mesh(front, this.red));

      const light = new T.HemisphereLight(0xffffff, 0xdddddd, 0.1);
      light.position.set(0, 16, 10);
      this.scene.add(light);

      var light1 = new T.DirectionalLight(0xffffff);
      light1.position.set(-10, 6, 10); //.normalize();
      this.scene.add(light1);
      var light2 = new T.PointLight(0xffffff, 2);
      light2.position.set(0, 10, 0).normalize();
      //   this.scene.add(light2);

      this.camera = new T.PerspectiveCamera(
        50,
        this.width / this.height,
        0.1,
        1000
      );
      this.camera.up.set(0, 1, 0);
      this.camera.position.x = -2;
      this.camera.position.y = 1;
      this.camera.position.z = 2;
      this.scene.add(this.camera);

      this.renderer.render(this.scene, this.camera);

      this.controls = new TrackballControls(
        this.camera,
        this.renderer.domElement
      );
      this.controls.panSpeed = 0.3;
      this.controls.rotateSpeed = 5;
      this.controls.enableDamping = true;
    },
    animate() {
      this.controls.update();
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  margin: 0 0.25rem;
  padding: 0.3rem;
}
    input[type='range'] {
      overflow: hidden;
      background-color: #9a905d;
    }
.green {
    color: #4c4;
    border: 1px solid #4c4;
}
    
</style>